import m from "mithril";
import i18n from "../../../shared/i18n/i18n";
import Auth from "../../../shared/auth";
import FormUtilities from "../../../shared/utilities/form_utilities";
import FormElementsMenu from "./FormElementsMenu";

let fieldCopy = null;
let optionInput = "";
let optionsForDeps = [];

function revertToolbox() {
    m.mount(document.querySelector(".form-toolbox"), FormElementsMenu);
}

function saveUndoHistory() {
    if (undoHistory.length >= 1)
        undoHistory.splice(0, 1);

    undoHistory.push(JSON.parse(JSON.stringify(unit)));
}

function saveField() {
    if (FormUtilities.validateFields(".field-edit input")) {
        for (let i = 0; i < unit.sections.length; i++) {
            for (let x = 0; x < unit.sections[i].fields.length; x++)
                if (unit.sections[i].fields[x].id === fieldCopy.id)
                    unit.sections[i].fields[x] = fieldCopy;
        }
        revertToolbox();
    }
}

function removeField() {
    for (let i = 0; i < unit.sections.length; i++) {
        for (let x = 0; x < unit.sections[i].fields.length; x++)
            if (unit.sections[i].fields[x].id === fieldCopy.id) {
                saveUndoHistory();
                unit.sections[i].fields.splice(x, 1);
            }
    }
    revertToolbox();

}

function checkFieldOps(opId) {
    for (let i = 0; i < fieldCopy.prerequisiteOptionIds.length; i++)
        if (fieldCopy.prerequisiteOptionIds[i] === opId)
            return true;

    return false;
}

function updateFieldOptionDeps(options) {
    fieldCopy.prerequisiteOptionIds = [];
    for (let i = 0; i < options.length; i++)
        if (options[i].selected)
            fieldCopy.prerequisiteOptionIds.push(options[i].value);
}

function getFieldOptions(fieldId) {
    let options = [];
    for (let i = 0; i < unit.sections.length; i++)
        for (let v = 0; v < unit.sections[i].fields.length; v++) {
            if (unit.sections[i].fields[v].id === fieldId)
                return options;

            if (unit.sections[i].fields[v].type === "checkbox" || unit.sections[i].fields[v].type === "radio")
                for (let x = 0; x < unit.sections[i].fields[v].options.length; x++)
                    options.push(unit.sections[i].fields[v].options[x]);
        }

    return options;
}

function Option(id, value) {
    this.id = id || Math.random().toString(36).slice(2);
    this.value = value || "";
}

function addFieldOption(field, value) {
    let match = false;
    for (let i = 0; i < field.options.length; i++)
        if (field.options[i].value === value)
            match = true;

    if (!match)
        field.options.push(new Option(null, value));
}

function removeFieldOption(field, id) {
    for (let i = 0; i < field.options.length; i++)
        if (field.options[i].id === id)
            field.options.splice(i, 1);
}

let unit;
let undoHistory;
const EditFieldToolbox = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
        undoHistory = vnode.attrs.undoHistory;
        fieldCopy = JSON.parse(JSON.stringify(vnode.attrs.fld));
        optionsForDeps = getFieldOptions(fieldCopy.id);
    },
    view: function () {
        return [
            m(".form-toolbox", [
                m("h4.mb-2", [i18n.t("edit_field"), " - ", i18n.t(fieldCopy.type)]),
                fieldCopy ? [
                    m(".form full-width field-edit", [
                        m(".form-section required", [
                            m(".form-label", i18n.t("name")),
                            m("input[type=text]", {
                                oninput: function () {
                                    fieldCopy.name = this.value;
                                }, value: fieldCopy.name
                            })
                        ]),
                        fieldCopy.type != "checkbox" && fieldCopy.type != "radio" && fieldCopy.type != "email" ? [
                            m(".form-section", [
                                m(".form-label", i18n.t("placeholder")),
                                m("textarea", {
                                    oninput: function () {
                                        fieldCopy.information = this.value;
                                    }, value: fieldCopy.information
                                })
                            ]),
                        ] : "",
                        m(".form-section checkbox-section", [
                            m("div", {
                                onclick: function (e) {
                                    fieldCopy.isRequired = !fieldCopy.isRequired;
                                }
                            }, [
                                fieldCopy.isRequired ? m("i.icon-checkbox-checked") : m("i.icon-checkbox-empty text-gray"),
                                m("span.ml-1", i18n.t("required_field"))
                            ])
                        ]),
                        fieldCopy.type === "checkbox" || fieldCopy.type === "radio" ? [
                            m(".form-label", i18n.t("options")),
                            m(".form-section with-button", [
                                m("input[type=text]", {
                                    onkeyup: function (e) {
                                        if (e.keyCode === 13) {
                                            addFieldOption(fieldCopy, optionInput);
                                            optionInput = "";
                                        }
                                    }, oninput: function () {
                                        optionInput = this.value;
                                    }, value: optionInput
                                }),
                                m("button.active", {
                                    disabled: !optionInput, onclick: function () {
                                        addFieldOption(fieldCopy, optionInput);
                                        optionInput = "";
                                    }
                                }, "+")
                            ]),
                            fieldCopy.options.map(function (option) {
                                return m("div", {key: option.id, id: option.id}, [
                                    m("button.btn-text", {
                                        onclick: function () {
                                            removeFieldOption(fieldCopy, option.id);
                                        }
                                    }, "x"),
                                    option.value
                                ])
                            }),
                            fieldCopy.options.length > 0 ? m("hr") : ""
                        ] : "",
                        optionsForDeps.length > 0 ? [
                            m(".form-section", [
                                m(".form-label", i18n.t("dependencies")),
                                m("select[multiple].section-option-deps-select", {
                                    onchange: function () {
                                        updateFieldOptionDeps(this.options);
                                    }
                                }, optionsForDeps.map(function (op) {
                                    return [
                                        m("option", {
                                            value: op.id, oncreate: function (e) {
                                                if (checkFieldOps(op.id)) {
                                                    e.dom.setAttribute("selected", "selected");
                                                }
                                            }
                                        }, op.value)
                                    ];
                                }))
                            ])
                        ] : "",
                        m(".form-buttons", [
                            Auth.validateRights(["unit_form_edit"], [
                                m("button.btn-error flex-float-left", {onclick: removeField}, i18n.t("delete_field")),
                                m("button", {onclick: saveField}, i18n.t("save_field"))
                            ]),
                            m("button.ml-1", {onclick: revertToolbox}, i18n.t("cancel"))
                        ])
                    ])
                ] : m("button.ml-1", {onclick: revertToolbox}, i18n.t("cancel"))
            ])
        ];
    }
};
export default EditFieldToolbox;
