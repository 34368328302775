import m from "mithril";
const pdfJsLib = require("pdfjs-dist/webpack");
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import Loading from "../../shared/loading";

let loading, originalUnit, enrolmentId, documentUnit, exitCallback;

let pdfDoc = null,
    totalPageNum = 1,
    currentPageNumber = 1,
    pageRendering = false,
    pageNumPending = null,
    scale = 1.5,
    canvas,
    ctx,
    allPageVisited = false,
    isAcknowledged = false,
    docLoaded,
    visitedPage = [];

function recordVisitedPage(pageNum) {
    if (!visitedPage.includes(pageNum)) {
        visitedPage.push(pageNum);
    }
    if(totalPageNum == visitedPage.length) {
        allPageVisited = true;
    } else {
        allPageVisited = false;
    }
}

function closeDocument() {
    loading = true;
    Requester.post(Gateway.public + "units/document/" + originalUnit.enrolment.id + "?enrolmentId=" + enrolmentId)
        .then(function () {
            exitCallback();
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            loading = false;
        });
}

function close() {
    exitCallback();
}

function toggleAcknowledgement() {
    isAcknowledged = !isAcknowledged;
}

function renderPage(num) {
    recordVisitedPage(num)
    pageRendering = true;
    pdfDoc.getPage(num).then((page) => {
        let viewport = page.getViewport({scale:scale});
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        console.log(viewport)
        let renderContext = {
            canvasContext: ctx,
            viewport: viewport
        };
        let renderTask = page.render(renderContext);
        renderTask.promise.then(() => {
            pageRendering=false;
            if(pageNumPending !==null) {
                renderPage(pageNumPending)
                pageNumPending = null
            }
        });
    });
}

function queueRenderPage(num) {
    if(pageRendering) {
        pageNumPending = num;
    } else {
        renderPage(num);
    }
}

function onPrevPage() {
    if(currentPageNumber <=1) {
        return;
    }
    currentPageNumber --;
    queueRenderPage(currentPageNumber);
}

function onNextPage() {
    if(currentPageNumber >= pdfDoc.numPages) {
        return;
    }
    currentPageNumber ++;
    queueRenderPage(currentPageNumber);
}

function zoomOut() {
    if(scale > 0.5) {
        scale -= 0.1;
        renderPage(currentPageNumber);
    }
}

function zoomIn() {
   if( scale <= 3) {
       scale += 0.1;
       renderPage(currentPageNumber);
   }
}

function reset() {
    scale = 1.5;
    renderPage(currentPageNumber);
}

function loadDocument() {
    loading = true;
    Requester.get(Gateway.public + "units/document/" + originalUnit.id).then(function (result) {
        documentUnit = result;
        loadPdfDocumentInView(documentUnit?.documentPath);
    }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).then(function () {
        loading = false;
    });
}


function loadPdfDocumentInView(path){
    loading = true;
    pdfJsLib.getDocument(path).promise.then((doc) => {
        pdfDoc = doc;
        totalPageNum = doc.numPages;
        m.redraw();
    }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t("UnableToProcessPdf"));
    }).then(function () {
        loading = false;
        renderPage(currentPageNumber);
    });
}

const Component_Document_Viewer = {
    oninit: function (vnode) {
        enrolmentId = vnode.attrs.enrolmentId;
        originalUnit = vnode.attrs.unit;
        loadDocument();
        exitCallback = vnode.attrs.callback;
    },
    oncreate: function () {
        canvas = document.getElementById("pdf_canvas");
        ctx = canvas.getContext("2d");
    },
    view: function () {
        return [
            m("#player-bg"),
            m("#player", m("#PdfViewer", [
                documentUnit ? m("div#header", {class: loading ? "disabled" : "" }, [
                    m("div.box-header mt-2 mb-2", [
                        m("div.box-title item", {style: "align-self:flex-center;"}, [
                            m("h4#", documentUnit ? documentUnit.name : m(".loading-dots"))
                        ]),
                        m("div.box-controls", [
                            m("span.ml-2", {class:"box-zoom"}, [
                                m("button", {class:"ml-2", onclick: zoomOut}, m("i", {class:"icon-minus"})),
                                m("button", {class:"ml-2", onclick: reset}, Math.round(scale * 100) + '%', m("i", {class:""})),
                                m("button", {class:"ml-2", onclick: zoomIn}, m("i", {class:"icon-plus"}))
                            ]),
                            m("span.ml-2", {class:"box-page-controls"}, [
                                m("button.ml-2", {class: currentPageNumber <= 1 ? "disabled" : "", onclick: onPrevPage}, [
                                    m("i", {class:"icon-back"})
                                ]),
                                m("button.ml-2", {class: currentPageNumber >= totalPageNum ? "disabled" :"", onclick: onNextPage}, [
                                    m("i", {class:"icon-forward"})
                                ])
                            ]),
                            m("span.ml-2 box-info", {class: (allPageVisited && isAcknowledged && documentUnit.acknowledgeRequired || allPageVisited && !documentUnit.acknowledgeRequired)? "" : "disabled"}, [
                                m("a.ml-2 tooltip", {"data-tooltip": i18n.t("download"), href: documentUnit.documentPath, target:"_blank", download: documentUnit.name + ".pdf" },
                                    m("i", {class: "icon-download"}))
                            ])
                        ]),
                        m("div.box-close item",
                            {class: (allPageVisited && isAcknowledged && documentUnit.acknowledgeRequired || allPageVisited && !documentUnit.acknowledgeRequired)? "" : "tooltip tooltip-bottom", "data-tooltip" : i18n.t("ReadLastPage")},
                            m("button.btn-cta", {class: (allPageVisited && isAcknowledged && documentUnit.acknowledgeRequired || allPageVisited && !documentUnit.acknowledgeRequired)? "" : "disabled", onclick: closeDocument}, i18n.t("SaveAndClose"))
                       ,
                        m("button", {class:"btn-error ml-2", onclick: close}, "x"),

                    )
                    ])
                ]) : !loading ?
                    m("div.box-header mt-2 mb-2", m("div.box-controls", i18n.t("cant_load")))
                    : m("div.box-header mt-2 mb-2",  m("div.box-controls",i18n.t("loading"))),
                m("section.section-content",{ style: ""}, [
                    m("div.box-scrollable", [
                        m("div.canvasWrapper", [
                            m("canvas", { id: "pdf_canvas"})
                        ])
                    ])
                ]),
                !loading ?  m("div.footer", [
                    m("div.box-header mt-2 mb-2", [
                        m("div.box-controls", allPageVisited && documentUnit && documentUnit.acknowledgeRequired ? [
                            m("span", {onclick: toggleAcknowledgement, style: "position: relative; top: -8px;"}, [
                                isAcknowledged ? m("i.icon-checkbox-checked mr-2", {style: "position: relative; top: 5px;"}) :
                                    m("i.icon-checkbox-empty mr-2", {style: "position: relative; top: 5px;"})], documentUnit.acknowledgeStatement
                            )] : m("span.ml-2 mr-2", i18n.t("ReadLastPage")))
                    ])
                ]) : m(Loading)
            ]))
        ];
    }
};
export default Component_Document_Viewer;
