﻿const UnitUtilities = {
	unitTypes: {
		scorm: "Interactive",
		form: "Form",
		event: "Face to Face",
		document: "Document",
		certificate: "Certificate",
		action: "Action",
		approval: "Approval",
		download: "Download",
		upload: "Upload"
	},
	getUnitTypeIcon: function (type) {
		return (type === UnitUtilities.unitTypes.scorm || type === "Scorm") ? "icon-grid"
			: (type === UnitUtilities.unitTypes.event || type === "Event") ? "icon-user-pair"
				: type === UnitUtilities.unitTypes.certificate ? "icon-certificate-2"
					: type === UnitUtilities.unitTypes.document ? "icon-document"
						: type === UnitUtilities.unitTypes.form ? "icon-document-edit"
							: type === UnitUtilities.unitTypes.action ? "icon-star"
								: type === UnitUtilities.unitTypes.approval ? "icon-checkbox-checked"
									: type === UnitUtilities.unitTypes.download ? "icon-download"
										: type === UnitUtilities.unitTypes.upload ? "icon-download rotate-180"
											: "";
	},
	getUnitTypeName: function (type) {
		return type === UnitUtilities.unitTypes.scorm || type === "Scorm" ? "Interactive"
			: type === UnitUtilities.unitTypes.event || type === "Event" ? "Face to Face"
				: type === UnitUtilities.unitTypes.certificate ? "Certificate"
					: type === UnitUtilities.unitTypes.document ? "Document"
						: type === UnitUtilities.unitTypes.form ? "Form"
							: type === UnitUtilities.unitTypes.action ? "Action"
								: type === UnitUtilities.unitTypes.approval ? "Approval"
									: type === UnitUtilities.unitTypes.download ? "Download"
										: type === UnitUtilities.unitTypes.upload ? "Upload"
											: "";
	}
};

export default UnitUtilities;
