﻿import m from "mithril";
const {Sortable} = require("sortablejs")
import FormUtilities from "../../shared/utilities/form_utilities";
import Requester from "../../shared/request";
import Toasts from "../../shared/toasts";
import Gateway from "../../shared/app_settings";
import ModalManager from "../../shared/modal";
import Auth from "../../shared/auth";
import Modal_Checklist_Edit from "./modal_checklist_edit";
import i18n from "../../shared/i18n/i18n";

let loading, courseId, items, saving, selected = [];

function loadItems() {
    loading = true;
    selected = [];

    Requester.get(Gateway.admin + "courses/" + courseId + "/checklist")
        .then(function (result) {
            items = result.properties.checklist;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            loading = false;
        });
}

function editChecklistItem(item) {
    ModalManager.open(item ? "Edit Checklist Item" : "New Checklist Item", [], Modal_Checklist_Edit, {courseId: courseId, item: item, callback: updateItem});
}

function updateItem(item) {
    if (item.id) {
        let existingItem = items.filter(obj => {
            return obj.id === item.id;
        });
        existingItem.name = item.name;
    } else {
        items.push(item);
    }

    saveItems();
}

function deleteItems() {
    items = items.filter(obj => {
        return selected.indexOf(obj.id) === -1;
    });
    saveItems();
}

function saveItems() {
    if (FormUtilities.validateFields(".modal input")) {
        saving = true;
        Requester.post(Gateway.admin + "courses/" + courseId + "/checklist", {courseId: courseId, items: items})
            .then(function () {
                loadItems();
            }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            saving = false;
        });
    }
}

function toggleAllSelected() {
    if (selected.length > 0)
        selected = [];
    else {
        if (items) {
            for (let i = 0; i < items.length; i++) {
                selected.push(items[i].id);
            }
        }
    }
}

function toggleSelected(id) {
    let index = selected.indexOf(id);
    if (index === -1)
        selected.push(id);
    else
        selected.splice(index, 1);
}


const Component_Course_Checklist = {
    oninit: function (vnode) {
        courseId = vnode.attrs.courseId;
        loadItems();
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", [
                    m(".toolbar-section", [
                        Auth.validateRights(["courses_edit_checklist"], m("button.with-icon", {
                            onclick: function () {
                                editChecklistItem(null);
                            }
                        }, [m("i.icon-plus"), i18n.t("new_checklist_item")])),
                        Auth.validateRights(["courses_edit_checklist"], m("button.with-icon", {
                            onclick: deleteItems,
                            class: selected.length === 0 ? "disabled" : ""
                        }, [m("i.icon-delete"), i18n.t("delete")])),
                        selected && selected.length > 0 ? m("span.ml-1", [selected.length, i18n.t("space_selected")]) : ""
                    ])
                ]),
                !loading ? [
                    items ? [
                        items.length > 0 ? [
                            m(".table-wrapper", [
                                m("table.full-width", {class: Auth.validateRights(["courses_edit_checklist"]) ? "sortable-table" : ""}, [
                                    m("thead",
                                        m("tr", [
                                            m("th", m(".toolbar-checkbox", {onclick: toggleAllSelected}, m("i", {class: selected.length > 0 ? "icon-checkbox-checked" : "icon-checkbox-empty"}))),
                                            m("th", i18n.t("name")),
                                            Auth.validateRights(["courses_edit_checklist"], m("th", "")),
                                        ])
                                    ),
                                    m("tbody", {
                                        oncreate: Auth.validateRights(["courses_edit_checklist"], function (v) {
                                            Sortable.create(v.dom, {
                                                draggable: "tr",
                                                handle: ".icon-arrange",
                                                onEnd: function (e) {
                                                    if (e.oldIndex !== e.newIndex) {
                                                        reorderItem(e.item.getAttribute("data-id"), e.oldIndex, e.newIndex);
                                                    }
                                                }
                                            });
                                        })
                                    }, items.map(function (item) {
                                        return m("tr", {"data-id": item.id}, [
                                            m("td.list-checkbox c-hand pl-1 pr-1", {
                                                onclick: function () {
                                                    toggleSelected(item.id);
                                                }, style: "width: 18px;"
                                            }, m("i", {class: selected.indexOf(item.id) !== -1 ? "icon-checkbox-checked" : "icon-checkbox-empty"})),
                                            m("td.c-hand", {
                                                onclick: function () {
                                                    editChecklistItem(item);
                                                }
                                            }, item.name),
                                            m("td"/*, Auth.validateRights(["courses_edit_checklist"], m("i.icon-arrange"))*/)
                                        ])
                                    }))
                                ])
                            ])
                        ] : m(".text-gray padding", "No checklist items")
                    ] : m(".text-gray padding", "Unable to load checklist items")
                ] : m(".text-gray padding", ["Loading", m(".loading-dots")])
            ])
        ];
    }
};
export default Component_Course_Checklist;
