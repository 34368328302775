﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import FormUtilities from "../../shared/utilities/form_utilities";
import i18n from "../../shared/i18n/i18n";
import ModalManager from "../../shared/modal";
import Component_Autocomplete from "../../shared/components/component_autocomplete";
import Modal_Confirm from "../../shared/components/modal_confirm";

let saving, courses, email, userId, loading, modalId;

function loadCourses() {
    loading = true;
    Requester.get(Gateway.admin + "courses/assignable")
        .then(function (result) {
            courses = result.properties.courses;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function checkIsReenrolment() {
    let selectedCourses = [];

    if (!userId && (!email || !FormUtilities.validateEmail(email)))
        Toasts.add("error", i18n.t("cant_send"), i18n.t("enter_valid_email"));
    else {
        let sel = document.getElementById("create-enrolments-course-select");
        for (let i = 0; i < sel.options.length; i++) {
            if (sel.options[i].selected)
                selectedCourses.push(sel.options[i].value);
        }
    }

    saving = true;
    Requester.post(Gateway.admin + "courses/check-reenrolment", {
        UserId: userId,
        CourseIds: selectedCourses
    })
        .then(function (isReenrolment) {
            if (isReenrolment) {
                confirmReenrolmentPrompt(selectedCourses);
            } else {
                createEnrolments(selectedCourses);
            }
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(() => saving = false);
}

function createEnrolments(selectedCourses) {
    if (!selectedCourses.length > 0)
        Toasts.add("error", i18n.t("cant_send"), i18n.t("one_course"));
    else {
        saving = true;
        Requester.post(Gateway.admin + "courses/enrolments", {
            UserId: userId,
            CourseIds: selectedCourses
        })
            .then(function (response) {
                if (response.properties.errors && response.properties.errors.length > 0) {
                    let errs = [];
                    for (let i = 0; i < response.properties.errors.length; i++)
                        errs.push(response.properties.errors[i].message);
                    Toasts.add("warn", i18n.t("successful_but_errors"), errs.join("\n") + "\n" + i18n.t("refresh_for_changes"), true);
                } else
                    Toasts.add("success", i18n.t("saved_changes"), i18n.t("refresh_for_changes"));

                ModalManager.close(modalId);
            })
            .catch(function (error) {
                saving = false;
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            })
            .finally(() => saving = false);
    }
}

function confirmReenrolmentPrompt(selectedCourses) {
    let createEnrolmentsCallback = () => createEnrolments(selectedCourses);

    const attributes = {
        confirmCallback: createEnrolmentsCallback,
        cancelCallback: null,
        messageText: i18n.t("confirm_reenrolment_message"),
        confirmButtonLabel: i18n.t("reenrol"),
        cancelButtonLabel: i18n.t("cancel"),
        confirmButtonClasses: "button",
        cancelButtonClasses: "button.btn-error"
    };
    ModalManager.open(i18n.t("confirm_reenrolment"), [], Modal_Confirm, attributes)
}

function setEmail(autocompleteValue) {
    email = autocompleteValue.email || (!autocompleteValue.id ? autocompleteValue : null);
    userId = autocompleteValue.id;
}

const Modal_Enrolments_New = {

    oninit: function () {
        loadCourses();
    },
    view: function () {
        return [
            m(".form-section", [
                m(".form-label", i18n.t("name_or_email")),
                m(Component_Autocomplete, {
                    endpoint: "users",
                    updateCallback: setEmail,
                    submitCallback: setEmail,
                    class: "search"
                })
            ]),
            m(".form-section", [
                !loading ? [
                    courses ? [
                        m(".form-label", i18n.t("select_course")),
                        m("select[multiple]#create-enrolments-course-select",
                            courses.map(function (course) {
                                return [
                                    m("option", {value: course.id}, course.name)
                                ];
                            }))
                    ] : "No courses"
                ] : ["Loading", m(".loading-dots")]
            ]),
            m(".form-buttons", {class: loading ? "disable-buttons" : ""}, [
                !saving ? m("button", {onclick: checkIsReenrolment}, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};

export default Modal_Enrolments_New;
