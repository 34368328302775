﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import FormUtilities from "../../shared/utilities/form_utilities";
import SessionController from "../../shared/session";
import AuthLogo from "../../shared/components/component_logo";
import Loading from "../../shared/loading";
import i18n from "../../shared/i18n/i18n";
import Language_Selector from "../../shared/components/component_language_selector";
import RouterService from "../../shared/services/router.service";

let loading = false;
let loadingSso = false;
let saving = false;
let values = {
    emailOrUsername: null,
    password: null
};
let unverifiedError = false;

let ssoLinks;

function getSsoLinks() {
    loadingSso = true;
    Requester.get(Gateway.identity + "sso")
        .then(function (result) {
            ssoLinks = result;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            loadingSso = false;
        });
}

function submit() {
    if (FormUtilities.validateFields("input")) {
        saving = true;
        Requester.post(Gateway.identity + "login", values)
            .then(function (result) {
                SessionController.setSession(result);
                localStorage.removeItem("sso-logout");
                RouterService.navigateOnLogin();
            })
            .catch(function (error) {
                if (error.code === 409) {
                    unverifiedError = true;
                    m.redraw();
                } else {
                    Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                    values.password = "";
                }
            })
            .finally(function () {
                saving = false;
            });
    }
}

const PublicPage_Login = {
    oninit: function () {
        loading = true;
        console.log('translation status', i18n.status);
        if (SessionController.session && SessionController.session.identity) {
            RouterService.navigateOnLogin();
            Toasts.add("success", i18n.t("already_logged_in"), "");
        }

        if (SessionController.session && SessionController.session.environment && SessionController.session.environment.useExternalLogin && SessionController.session.environment.externalLoginUrl)
            window.location = SessionController.session.environment.externalLoginUrl;

        if (SessionController.session && SessionController.session.environment && SessionController.session.environment.ssoConfigured)
            getSsoLinks();

        loading = false;
    },
    view: function () {
        return [
            m(".relative", [
                !loading ? [
                    m(AuthLogo),
                    SessionController.session && SessionController.session.environment && SessionController.session.environment.about ? [
                        m("div", SessionController.session.environment.about),
                        m(".mt-5")
                    ] : "",
                    m('.pt-2.pb-2', [m("strong", i18n.t("language_preference")),
                        m(Language_Selector, {dropdown_class: "pb-2 pt-1"})
                    ]),
                    SessionController.session && SessionController.session.environment && SessionController.session.environment.ssoConfigured ? [
                        m(".form-header", i18n.t("single_sign_on")),
                        !loadingSso ? [
                            ssoLinks && ssoLinks.length > 0 ? [
                                ssoLinks.map(function (link) {
                                    return [
                                        m("a.btn with-icon", {href: link.url}, [
                                            link.name,
                                            m("i.icon-forward")
                                        ])
                                    ];
                                })
                            ] : m(".text-gray", "No SSO links"),
                            m(".mt-5")
                        ] : m(Loading, {class: "loading-inline"})
                    ] : "",
                    SessionController.session && SessionController.session.environment && !SessionController.session.environment.ssoOnly ? [
                        m(".form-header", i18n.t("login")),
                        !unverifiedError ? [
                            m("form", {
                                onsubmit: function (e) {
                                    e.preventDefault();
                                    submit();
                                }
                            }, [
                                m(".form-section required", [
                                    m(".form-label", [i18n.t("email"), "/", i18n.t("username")]),
                                    m("input[type=text]", {
                                        oninput: function () {
                                            values.emailOrUsername = this.value;
                                        }, value: values.emailOrUsername
                                    })
                                ]),
                                m(".form-section required", [
                                    m(".form-label", i18n.t("password")),
                                    m("input[type=password]", {
                                        oninput: function () {
                                            values.password = this.value;
                                        }, value: values.password
                                    })
                                ]),
                                m(".form-buttons", [
                                    !saving ? m(m.route.Link, {
                                        href: RouterService.getPublicForgotPasswordUrl(),
                                        class: "btn-text flex-float-left",
                                        style: "margin-left: 0;"
                                    }, i18n.t("forgot_password")) : "",
                                    !saving ? m("button", {"type": "submit"}, i18n.t("login")) : m("button.btn-text no-click", [i18n.t("login"), m(".loading-dots")])
                                ]),
                                m(".form-buttons", [m(m.route.Link, {href: RouterService.getPublicLoginAsVisitorUrl()}, i18n.t("guest_login"))])
                            ])
                        ] : [
                            m(".form-section required", [
                                m(".form-label", i18n.t("unverified")),
                                i18n.t("verification_resent"),
                                m("br"),
                                i18n.t("can_close_window")
                            ]),
                            m(".form-buttons", [
                                SessionController.session && SessionController.session.environment && SessionController.session.environment.redirectToLogin ? "" : m("button.btn-text", {
                                    onclick: function () {
                                        unverifiedError = false;
                                        values = {};
                                    }
                                }, i18n.t("back_to_login"))
                            ])
                        ]
                    ] : ""
                ] : m(Loading, {class: "loading-inline"})
            ])
        ];
    }
};
export default PublicPage_Login;
