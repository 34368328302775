import i18n from "../i18n/i18n";

export function getLabelClass(enrolment) {
    return enrolment.isCompleted ? (enrolment.isPassed ? 'label-success' : 'label-error') : enrolment.isStarted ? 'label-warning' : '';
}

export function getBackgroundClass(enrolment) {
    return enrolment.isCompleted ? (enrolment.isPassed ? 'bg-success' : 'bg-error') : enrolment.isStarted ? 'bg-warning' : 'bg-secondary';
}

export function getLabelResult(enrolment) {
    return enrolment.isCompleted ? (enrolment.isPassed ? i18n.t("passed") : i18n.t("failed")) : enrolment.isStarted ? i18n.t("in_progress") : i18n.t("not_started");
}

// module.exports = {getLabelResult, getBackgroundClass, getLabelClass}
// export default {getLabelResult, getBackgroundClass, getLabelClass};
