﻿import m from "mithril";
import i18n from "../../../shared/i18n/i18n";
import {ACTION_TYPES} from "../../values";
import Component_Unit_Action_SendEmail_Settings from "./component_unit_action_sendemail_settings";

let loading = false;
let tab = "settings";
let unit = null;

const Page_Unit_Action = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section with-menu", [
                        m("h1", unit ? [
                            unit.properties.actionType === ACTION_TYPES.sendEmail ? i18n.t("send_email-action_unit")
                                : "unknown_action_unit_type"
                        ] : i18n.t("action_unit")),
                        m(".toolbar-menu", [
                            m("div", {class: tab === "settings" ? "active" : ""}, i18n.t("settings"))
                        ])
                    ])
                ])
            ]),
            !loading ? [
                unit ? [
                    unit.properties.actionType === ACTION_TYPES.sendEmail ? m(Component_Unit_Action_SendEmail_Settings, {unit: unit.properties})
                        : "Unknown action type"
                ] : m(".text-gray padding", "Unable to load unit")
            ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
        ];
    }
};
export default Page_Unit_Action;
