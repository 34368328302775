﻿import m from "mithril";
import ModalManager from "../../shared/modal";
import Modal_Unit_Scorm_New from "./scorm/modal_unit_scorm_new";
import Modal_Unit_Form_New from "./form/modal_unit_form_new";
import Modal_Unit_Event_New from "./event/modal_unit_event_new";
import Modal_Unit_Certificate_New from "./certificate/modal_unit_certificate_new";
import Modal_Unit_Document_New from "./document/modal_unit_document_new";
import Modal_Unit_Action_New from "./action/modal_unit_action_new";
import Modal_Unit_Approval_New from "./approval/modal_unit_approval_new";
import Modal_Unit_Download_New from "./download/modal_unit_download_new";
import Modal_Unit_Upload_New from "./upload/modal_unit_upload_new";
import UnitUtilities from "../../shared/utilities/unit_utilities";
import Auth from "../../shared/auth";

let modalId;
function newInteractiveUnit() {
    ModalManager.open("Create Interactive Unit", [], Modal_Unit_Scorm_New);
}

function newFormUnit() {
    ModalManager.open("Create Form Unit", [], Modal_Unit_Form_New);
}

function newEventUnit() {
    ModalManager.open("Create Face to Face Unit", [], Modal_Unit_Event_New);
}

function newCertificateUnit() {
    ModalManager.open("Create Certificate Unit", [], Modal_Unit_Certificate_New);
}

function newDocumentUnit() {
    ModalManager.open("Create Document Unit", [], Modal_Unit_Document_New);
}

function newActionUnit() {
    ModalManager.open("Create Action Unit", [], Modal_Unit_Action_New);
}

function newApprovalUnit() {
    ModalManager.open("Create Approval Unit", [], Modal_Unit_Approval_New);
}

function newDownloadUnit() {
    ModalManager.open("Create Download Unit", [], Modal_Unit_Download_New);
}

function newUploadUnit() {
    ModalManager.open("Create Upload Unit", [], Modal_Unit_Upload_New);
}

function getButtonClass(claim) {
    return Auth.validateRights([claim])
        ? ""
        : "disabled";
}

let launchUnit = (newUnitFunction) => {
    newUnitFunction();
    ModalManager.close(modalId);
}

const Modal_Unit_New = {
    view: function () {
        return [
            m("button.with-icon btn-big", {class: getButtonClass("unit_scorm_create"), onclick: () => launchUnit(newInteractiveUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.scorm)}), UnitUtilities.unitTypes.scorm]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_form_create"), onclick: () => launchUnit(newFormUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.form)}), UnitUtilities.unitTypes.form]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_event_create"), onclick: () => launchUnit(newEventUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.event)}), UnitUtilities.unitTypes.event]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_certificate_create"), onclick: () => launchUnit(newCertificateUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.certificate)}), UnitUtilities.unitTypes.certificate]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_document_create"), onclick: () => launchUnit(newDocumentUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.document)}), UnitUtilities.unitTypes.document]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_action_create"), onclick: () => launchUnit(newActionUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.action)}), UnitUtilities.unitTypes.action]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_approval_create"), onclick: () => launchUnit(newApprovalUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.approval)}), UnitUtilities.unitTypes.approval]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_download_create"), onclick: () => launchUnit(newDownloadUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.download)}), UnitUtilities.unitTypes.download]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_upload_create"), onclick: () => launchUnit(newUploadUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.upload)}), UnitUtilities.unitTypes.upload])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Unit_New;
