﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import ModalManager from "../../shared/modal";

let saving, user, password, confirmPassword, modalId;

function sendResetPasswordEmail() {
    saving = true;
    Requester.post(Gateway.admin + "users/" + user.id + "/send-password-reset")
        .then(function () {
            ModalManager.close(modalId);
            Toasts.add("success", "Reset Password Email Sent", "");
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        saving = false;
    });
}

function updateUserPassword() {
    if (password !== confirmPassword) {
        Toasts.add("error", i18n.t("cant_save"), "Please ensure both fields match");
    } else {
        saving = true;
        Requester.post(Gateway.admin + "users/" + user.id + "/change-password", {password: password})
            .then(function () {
                ModalManager.close(modalId);
                Toasts.add("success", "Password Updated", "");
            }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            saving = false;
        });
    }
}

const Modal_User_Reset_Password = {
    oninit: function (vnode) {
        user = vnode.attrs.user;
    },
    view: function () {
        return [
            user.email ? [
                m(".form-section", [
                    (user.firstName ? [user.firstName, " ", user.lastName] : "This user"), " has an email address (", user.email, ") to send a password reset prompt to.",
                    m("br"),
                    m("br"),
                    "Click the button below to send them a ", m("em", "Reset Password"), " email.",
                    m("br"),
                    m("br"),
                    "If they're having trouble receiving the email or resetting their password, please contact support."
                ]),
                m(".form-buttons", [
                    !saving ? m("button", {onclick: sendResetPasswordEmail}, "Send Reset Password Email") : m("button.btn-text no-click", ["Sending", m(".loading-dots")])
                ])

            ] : [
                m(".form-section required", [
                    m(".form-label", "New Password"),
                    m("input[type=password]", {
                        oninput: function () {
                            password = this.value
                        }, value: password
                    })
                ]),
                m(".form-section required", [
                    m(".form-label", "Confirm New Password"),
                    m("input[type=password]", {
                        oninput: function () {
                            confirmPassword = this.value
                        }, value: confirmPassword
                    })
                ]),
                m(".form-buttons", [
                    !saving ? m("button", {onclick: updateUserPassword}, "Save") : m("button.btn-text no-click", ["Saving", m(".loading-dots")])
                ])
            ]
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_User_Reset_Password;
