﻿import m from "mithril";
import ModalManager from "../../shared/modal";

let message;
let modalApprovalRejectVnode;
let modalId;

function submit() {
    if (modalApprovalRejectVnode.attrs.callback)
        modalApprovalRejectVnode.attrs.callback(modalApprovalRejectVnode.attrs.unitEnrolment, false, message);
    ModalManager.close(modalId);
}

const Modal_Approval_Reject = {
    view: function (vnode) {
        modalApprovalRejectVnode = vnode; // if this doesn't work change this to vnode: https://mithril.js.org/components.html
        return [
            m(".form-section", [
                m(".form-label", "Message"),
                m("textarea", {
                    oninput: function () {
                        message = this.value;
                    }, value: message
                })
            ]),
            m(".form-buttons", [
                m("button", {onclick: submit}, "Reject")
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Approval_Reject;
