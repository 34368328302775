import m from "mithril";
import Requester from "../request";
import UrlUtilities from "../utilities/url_utilities";
import Gateway from "../app_settings";
import Toasts from "../toasts";
import Loading from "../loading";
import i18n from "../../shared/i18n/i18n";


const Component_Autocomplete = {
    current: null, // A reference for _this_ autocomplete, so there can be more than one on a page
    loading: false,
    requestRef: null, // To keep track of (and abort) executing searches when typing
    timeout: null,
    endpoint: null, // Where we'll be querying the input value
    updateCallback: null, // Updating on each keystroke
    submitCallback: null, // Submitting on selection
    entities: [], // The values returned from the search
    responseTotal: 0, // The total number of results found
    page: 0, // An offset for the entities so the list is manageable
    propertyName: "name", // The name of the response property we want to show
    selectedValue: null, // Response will be the field value or the selected object
    check: function (searchValue) {
        Component_Autocomplete.current.loading = true;
        Requester.abort(Component_Autocomplete.current.requestRef);
        clearTimeout(Component_Autocomplete.timeout);
        Component_Autocomplete.timeout = setTimeout(function () {
            return m.request({
                method: "GET",
                url: UrlUtilities.createUrl(Gateway.admin + Component_Autocomplete.current.endpoint, "search=" + searchValue, "offset=" + Component_Autocomplete.current.page * 10),
                withCredentials: true,
                headers: Requester.headers(),
                config: function (xhr) {
                    Component_Autocomplete.current.requestRef = xhr;
                }
            }).then(function (result) {
                if (result && result.properties && result.entities) {
                    Component_Autocomplete.current.entities = result.entities;
                    Component_Autocomplete.current.responseTotal = result.properties.totalCount;
                }
            }).catch(function (error) {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            })
                .finally(function () {
                    Component_Autocomplete.current.loading = false;
                });
        }, 400);
    },
    submitValue: function (selectedEntity) {
        Component_Autocomplete.current.selectedValue = selectedEntity;
        Component_Autocomplete.current.submitCallback(Component_Autocomplete.current.selectedValue);
        Component_Autocomplete.current.reset();
    },
    updateValue: function () {
        Component_Autocomplete.current.updateCallback(Component_Autocomplete.current.selectedValue);
    },
    getRemainingCount: function () {
        let remaining = (Component_Autocomplete.current.responseTotal - (Component_Autocomplete.current.entities.length + Component_Autocomplete.current.page * 10));
        if (remaining > 10)
            return 10;
        else
            return remaining;
    },
    reset: function () {
        Component_Autocomplete.current.entities = [];
        Component_Autocomplete.current.page = 0;
        Component_Autocomplete.current.responseTotal = 0;
    },
    oninit: function (vnode) {
        Component_Autocomplete.current = this;
        Component_Autocomplete.current.selectedValue = vnode.attrs.initialValue;
        Component_Autocomplete.current.endpoint = vnode.attrs.endpoint;
        Component_Autocomplete.current.updateCallback = vnode.attrs.updateCallback || function () {
        };
        Component_Autocomplete.current.submitCallback = vnode.attrs.submitCallback || function () {
        };

        if (vnode.attrs.propertyName)
            Component_Autocomplete.current.propertyName = vnode.attrs.propertyName;
    },
    view: function (vnode) {
        return [
            m(".autocomplete", [
                m("input[type=text]", {
                    oninput: function (e) {
                        Component_Autocomplete.current.selectedValue = e.target.value;
                        Component_Autocomplete.current.updateValue();
                        Component_Autocomplete.current.check(e.target.value);
                    },
                    onkeyup: function (e) {
                        if (e.keyCode === 13) {
                            Component_Autocomplete.current.submitValue(Component_Autocomplete.current.selectedValue);
                        }
                    },
                    value: Component_Autocomplete.current.selectedValue && Component_Autocomplete.current.selectedValue[Component_Autocomplete.current.propertyName] || this.value
                }),
                Component_Autocomplete.current.loading ? m(Loading, {class: "input-loading"}) : "",
                m("ul", [
                    !Component_Autocomplete.current.loading && Component_Autocomplete.current.page > 0 ? m("li.text-primary", {
                        onclick: function () {
                            Component_Autocomplete.current.page--;
                            Component_Autocomplete.current.check(Component_Autocomplete.current.selectedValue.value);
                        }
                    }, "Load previous") : "",
                    Component_Autocomplete.current.entities.map(function (entity) {
                        return [
                            m("li", {
                                onclick: function () {
                                    Component_Autocomplete.current.submitValue(entity.properties);
                                }
                            }, entity.properties[Component_Autocomplete.current.propertyName])
                        ];
                    }),
                    !Component_Autocomplete.current.loading && Component_Autocomplete.current.getRemainingCount() > 0 ? m("li.text-primary", {
                        onclick: function () {
                            Component_Autocomplete.current.page++;
                            Component_Autocomplete.current.check(Component_Autocomplete.current.selectedValue.value);
                        }
                    }, "Load " + Component_Autocomplete.current.getRemainingCount() + " more") : ""
                ])
            ])
        ];
    }
};

export default Component_Autocomplete;
