﻿import m from 'mithril';
import i18n from '../../shared/i18n/i18n';
import SessionController from '../../shared/session';
import Auth from '../../shared/auth';
import OrgFileService from '../../shared/storage/org_file_service';
import Loading from '../../shared/loading';
import iconShield from '../../shared/img/icon-shield.svg';
import iconProfile from '../../shared/img/icon-profile.svg';
import iconGrid from '../../shared/img/icon-grid.svg';
import Language_Selector from '../../shared/components/component_language_selector';
import RouterService from '../../shared/services/router.service';

// Global let to keep track of current page for menu
let getCurrentPage = (pageUrl) => {
    switch (pageUrl) {
        case '/public':
            return 'courses';
        case '/public/':
            return 'courses';
        case '/public/profile':
            return 'profile';
        default:
            return '';
    }
};

const Component_Header = {
    view: function () {
        let currentPage = getCurrentPage(m.route.get());
        const hideHeader = m.route.param('hideHeader');
        return [
            hideHeader ? ''
                : m('header.SecondaryTheme.toolbar', [
                    m('.toolbar-section', [
                        m('.toolbar-title', [
                            SessionController.session ? [
                                SessionController.session.environment ? [
                                    SessionController.session.environment.logoName ? m('img.headerLogo', { src: OrgFileService.getLogoPath() })
                                        : SessionController.session.environment.name || ''
                                ] : m('img.headerLogo', { src: SessionController.session.environment.storageUrl + '/assets/img/logo.png' })
                            ] : m(Loading)
                        ]),
                        m('.toolbar-tabs spaced-tabs', [
                            m(m.route.Link, {
                                href: RouterService.getPublicCoursesUrl(),
                                class: currentPage === 'courses' ? 'active' : '',
                                onclick: function () {
                                    currentPage = 'courses';
                                }
                            }, [
                                m('.headerLink', [
                                    m.trust(iconGrid),
                                    i18n.t('myCourses')
                                ]),
                            ]),
                            SessionController.session && SessionController.session.identity ? m(m.route.Link, {
                                href: RouterService.getProfileUrl(),
                                class: currentPage === 'profile' ? 'active' : '',
                                onclick: function () {
                                    currentPage = 'profile';
                                }
                            }, [
                                m('.headerLink', [
                                    m.trust(iconProfile),
                                    i18n.t('myProfile')
                                ]),
                            ]) : '',
                            m(m.route.Link, {
                                href: RouterService.getAdminUrl(),
                                class: currentPage === 'admin' ? 'active' : '',
                                onclick: function () {
                                    currentPage = 'admin';
                                }
                            }, [
                                m('', Auth.validateRights(["admin_section"],
                                    m('.headerLink', [
                                        m.trust(iconShield), i18n.t('myAdmin')
                                    ]),
                                ))
                            ]),
                        ]),
                    ]),
                    m('.toolbar-section language-selector ', { class: SessionController.session.identity ? 'logged-in' : 'public' }, [
                        SessionController.session ? [
                            SessionController.session.identity ? [
                                m('.toolbar-title', [m('.headerLink.m-tooltip-left.mb-1', {
                                    'm-data-tooltip': SessionController.session.identity.name
                                }, [
                                    m('i.icon-list.profile-icon.hidden')])
                                    , m('span.mr-2.hide-text', SessionController.session.identity.name)]),

                                m(Language_Selector, { button_class: 'btn-secondary', dropdown_class: '' }),
                                m('button.btn.btn-secondary', [
                                    Auth.validateInternalAccess([m(m.route.Link, {
                                        href: RouterService.getAccountUrl(),
                                        class: currentPage === 'account' ? 'active' : '',
                                        onclick: function () {
                                            currentPage = 'account';
                                        }
                                    }, i18n.t('account'))])
                                ]),
                                m('button.btn.btn-secondary', [
                                    m('a', { onclick: SessionController.logout }, i18n.t('logout'))
                                ])
                            ] : [
                                m(Language_Selector, { button_class: 'btn-secondary show-icon', dropdown_class: '' }),
                                m('.toolbar-tabs', [
                                    SessionController.session && SessionController.session.environment && SessionController.session.environment.useExternalLogin && !SessionController.session.environment.externalLoginUrl ? '' : m(m.route.Link, { href: RouterService.getPublicLoginUrl() },
                                        m('', i18n.t('login'))
                                    ),
                                ])
                            ]
                        ] : '',

                    ]),
                ])
        ];
    }
};
export default Component_Header;

