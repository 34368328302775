import m from "mithril";
import i18n from "../i18n/i18n";
import translate from "../../shared/img/icon-translate.svg"
const Language_Selector = {
    oninit: function(vnode) {
    },
    view: function (vnode) {
        return [
            m('.dropdown', { class: vnode.attrs.dropdown_class },
                m('button.btn pl-1 pr-1 flex-display',{ class: vnode.attrs.button_class} ,[
                 m('.btn-text', i18n.getLanguageSelectorTitle()),
                    m('.icon.hidden', [
                        m.trust(translate)
                    ]),
                    m('i.icon-back rotate-270')
                ]),
                m('.dropdown-list mb-0 pb-0 lang-selector',
                    i18n.supportedLocales.map(local => {
                        return local.disable ? "" : m('div', {
                            class: i18n.isSelectedLanguageCode(local.code) ? 'active' : '',
                            onclick: function () {
                                if(!local.disable) {
                                    i18n.loadAndSetLocale(local.code);
                                }
                            }
                        }, [m('div', {class: local.disable ? "disabled" : ""}, local.display)])
                    })
                )
            ),
        ];
    }
};

export default Language_Selector;
