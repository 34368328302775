﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import SessionController from "../../shared/session";
import Loading from "../../shared/loading";
import RouterService from "../../shared/services/router.service";

let loading = true;

function verifyToken(ticket) {
    Requester.post(Gateway.identity + "login/cas?path=account&ticket=" + ticket,
        {
            "ticket": ticket
        },
        {"Content-Type": "application/json"})
        .then(function (result) {
            SessionController.setSession(result);
            localStorage.setItem("sso-logout", result.identity.ssoLogout);
            RouterService.navigateToAccount();
        })
        .catch(function (error) {
            loading = false;
            Toasts.error(JSON.stringify(error.response));
        });
}


const AccountPage_Login_SSO_CAS = {
    oninit: function (vnode) {
        if (SessionController.session && SessionController.session.identity) {
            RouterService.navigateToAccount();
            Toasts.add("success", i18n.t("already_logged_in"), "");
        }

        if (m.route.param("ticket")) {
            verifyToken(m.route.param("ticket"));
        } else {
            RouterService.navigateToAccountCasLogin();
            Toasts.add("error", i18n.t("cant_verify"), "Invalid Service URL");
        }

    },
    view: function () {
        return [
            m(".relative", [
                loading ? m(Loading, {class: "loading-inline"})
                    : [
                        m("h3.mb-2", "Unable to log in"),
                        m(".mb-1", "Please try again or contact support"),
                        SessionController.session && SessionController.session.environment && !SessionController.session.environment.ssoOnly ? [
                            m(m.route.Link, {href: RouterService.getAccountLoginUrl(), class: "btn-text"}, "Back to Log In")
                        ] : ""
                    ]
            ])
        ];
    }
};
export default AccountPage_Login_SSO_CAS;
