import m from "mithril";
const {Sortable} = require("sortablejs")
import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import i18n from "../../../shared/i18n/i18n";
import Toasts from "../../../shared/toasts";
import Auth from "../../../shared/auth";
import EditFieldToolbox from "./EditFieldToolbox";
import EditSectionToolbox from "./EditSectionToolbox";
import FormElementsMenu from "./FormElementsMenu";

let loading = false;
let saving = false;
let unit = null;
let originalUnit = null;
const history = [];

function saveForm() {
    saving = true;
    Requester.post(Gateway.admin + "units/form/" + unit.id + "/content", unit)
        .then(function (result) {
            unit = result;
            Toasts.add("success", i18n.t("saved_changes"));
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .then(function () {
            saving = false;
        });
}



function Section(id, name, information, fields, deps) {
    return {
        id: id || Math.random().toString(36).slice(2),
        name: name || "",
        information: information || "",
        prerequisiteOptionIds: deps || [],
        fields: fields || []
    };
}

function Field(id, type, name, information, isRequired, options, deps) {
    return {
        id: id || Math.random().toString(36).slice(2),
        name: name || "",
        information: information || "",
        type: type || "textfield",
        isRequired: isRequired || false,
        prerequisiteOptionIds: deps || [],
        options: options || []
    }
}

function initialiseFormSectionSorting() {
    Sortable.create(document.querySelector(".form-sections"), {
        group: {
            name: "layout"
        },
        animation: 100,
        ghostClass: "dragging",
        onSort: function (evt) {
            let section;
            let isNew = evt.from.closest(".form-toolbox");

            // If the section was added from the toolbox
            if (isNew) {
                section = new Section(null, "New Section");
            } else {
                let id = evt.item.getAttribute("id");
                for (let i = 0; i < unit.sections.length; i++) {
                    if (unit.sections[i].id === id) {
                        section = unit.sections[i];
                        unit.sections.splice(i, 1);
                    }
                }
            }

            unit.sections.splice(evt.newIndex, 0, section);

            // Remove the HTML element if added from toolbox or different section
            if (isNew) {
                if (evt.item && evt.item.parentNode)
                    evt.item.parentNode.removeChild(evt.item);
            }

            // Show the field edit toolbox
            if (isNew)
                mountSectionToolbox(section);

            // Make sure the view is up to date
            m.redraw();
        }
    });
}

function initialiseFormFieldSorting() {
    let fieldGroups = document.querySelectorAll(".form-section-fields");
    for (let i = 0; i < fieldGroups.length; i++) {
        Sortable.create(fieldGroups[i], {
            group: {
                name: "fields"
            },
            animation: 100,
            ghostClass: "dragging",
            onSort: function (evt) {
                let field;
                let isNew = evt.from.closest(".form-toolbox");


                // If the field was added from the toolbox
                if (isNew) {
                    const type = evt.item.getAttribute("data-type");
                    field = new Field(null, type, i18n.t("new_field"));
                } else {
                    const id = evt.item.getAttribute("id");
                    for (let i = 0; i < unit.sections.length; i++) {
                        for (let x = 0; x < unit.sections[i].fields.length; x++)
                            if (unit.sections[i].fields[x].id === id) {
                                field = unit.sections[i].fields[x];
                                unit.sections[i].fields.splice(x, 1);
                            }
                    }
                }

                // Get the ids of the sections the element was moved from/to
                const fromSecId = evt.from.parentNode.getAttribute("id");
                const toSecId = evt.to.parentNode.getAttribute("id");

                // Add the field to the section
                for (i = 0; i < unit.sections.length; i++) {
                    if (unit.sections[i].id === toSecId)
                        unit.sections[i].fields.splice(evt.newIndex, 0, field);
                }

                // Remove the HTML element if added from toolbox or different section
                if (isNew || fromSecId !== toSecId) {
                    if (evt.item && evt.item.parentNode)
                        evt.item.parentNode.removeChild(evt.item);
                }

                // Show the field edit toolbox
                if (isNew)
                    mountFieldToolbox(field);

                // Make sure the view is up to date
                m.redraw();
            }
        });
    }
}

function undoLast() {
    unit = JSON.parse(JSON.stringify(history.pop()));
}

function mountSectionToolbox(s) {
    const sec = unit.sections.filter(function (e) {
        return e.id === s.id
    })[0];
    if (sec)
        m.mount(document.querySelector(".form-toolbox"), {
            view: function () {
                return m(EditSectionToolbox, {sec: sec, unit: unit, undoHistory: history})
            }
        });
}

function mountFieldToolbox(f) {
    let fld;
    for (let i = 0; i < unit.sections.length; i++) {
        for (let x = 0; x < unit.sections[i].fields.length; x++)
            if (unit.sections[i].fields[x].id === f.id)
                fld = unit.sections[i].fields[x];
    }
    if (fld)
        m.mount(document.querySelector(".form-toolbox"), {
            view: function () {
                return m(EditFieldToolbox, {fld: fld, unit: unit, undoHistory: history})
            }
        });
}

const Component_Unit_Form_Editor = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit.properties;
        originalUnit = JSON.parse(JSON.stringify(unit));
    },
    view: function () {
        return [
            m(".content-box", [
                !loading ? [
                    unit ? m(".flex-row", [
                        m(".form-toolbox-container", {style: "width: 30%; max-width: 400px; min-width: 300px;"}, [
                            Auth.validateRights(["unit_form_edit"], [
                                m(".toolbar mb-2", [
                                    m(".toolbar-section", [
                                        m("button.with-icon btn-cta", {
                                            onclick: saveForm,
                                            class: unit === originalUnit || saving ? "disabled" : ""
                                        }, [m("i.icon-check"), i18n.t("save_form")]),
                                        m("button.with-icon btn-error", {
                                            onclick: undoLast,
                                            class: history.length > 0 ? "" : "disabled"
                                        }, [m("i.icon-undo"), i18n.t("undo_last")])
                                    ])
                                ])
                            ]),
                            m(".form-toolbox", m(FormElementsMenu))
                        ]),
                        m(".flex-auto flex-column ml-2", [
                            m("h3.text-center", i18n.t("form_editor")),
                            m(".form-unit form-preview", [
                                m(".mb-2", [
                                    m(".text-bold", unit.name),
                                    m("div", unit.description)
                                ]),
                                m(".form-sections form full-width", {
                                    oncreate: Auth.validateRights(["unit_form_edit"], function () {
                                        initialiseFormSectionSorting();
                                    })
                                }, [
                                    unit.sections.length > 0 ? unit.sections.map(function (section) {
                                        return m(".form-section mb-2 full-width", {
                                            key: section.id,
                                            id: section.id,
                                            onclick: function () {
                                                mountSectionToolbox(section);
                                            }
                                        }, [
                                            m(".form-label", section.name),
                                            section.information ? m(".form-subtitle text-italic mb-3", section.information) : "",
                                            m(".form form-section-fields full-width", {
                                                oncreate: Auth.validateRights(["unit_form_edit"], function () {
                                                    initialiseFormFieldSorting();
                                                })
                                            }, [
                                                section.fields.map(function (field) {
                                                    return m(".form-section", {
                                                        key: field.id,
                                                        id: field.id,
                                                        onclick: function (e) {
                                                            e.stopPropagation();
                                                            mountFieldToolbox(field);
                                                        }
                                                    }, [
                                                        m(".form-label", field.name),
                                                        field.type === "textfield" ? m("input[type=text][disabled]", {placeholder: field.information})
                                                            : field.type === "textbox" ? m("textarea[disabled]", {placeholder: field.information})
                                                                : field.type === "email" ? m("input[type=email][disabled]", {placeholder: i18n.t("enter_email")})
                                                                    : field.type === "number" ? m("input[type=number][disabled]", {placeholder: field.information})
                                                                        : field.type === "date" ? m("input[type=text][disabled]", {placeholder: field.information})
                                                                            : field.type === "checkbox" ? [
                                                                                    field.options.map(function (option) {
                                                                                        return m(".checkbox-section", [
                                                                                            m(".div",
                                                                                                m("i.icon-checkbox-empty text-gray"),
                                                                                                m("span.ml-1", option.value)
                                                                                            )
                                                                                        ])
                                                                                    })
                                                                                ]
                                                                                : field.type === "radio" ? [
                                                                                    field.options.map(function (option) {
                                                                                        return m(".checkbox-section", [
                                                                                            m(".div",
                                                                                                m(".radio-button text-gray"),
                                                                                                m("span.ml-1", option.value)
                                                                                            )
                                                                                        ])
                                                                                    })
                                                                                ] : ""
                                                    ])
                                                })
                                            ])
                                        ])
                                    }) : m(".text-gray", i18n.t("drag_section_here"))
                                ])
                            ])
                        ])
                    ]) : m(".text-gray padding", i18n.t("unable_to_load_unit"))
                ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
            ])
        ];
    }
};

export default Component_Unit_Form_Editor;
