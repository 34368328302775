﻿import m from 'mithril';
import Requester from '../../shared/request';
import Gateway from '../../shared/app_settings';
import Toasts from '../../shared/toasts';
import ModalManager from '../../shared/modal';
import Modal_Confirm_Delete from '../../shared/components/modal_confirm_delete';
import Modal_Text from "../../shared/components/modal_text";
import i18n from '../../shared/i18n/i18n';

let reloadCallback,
    loading,
    resubmitted;

function submitForApproval(unit, course) {
    loading = true;
    Requester.post(Gateway.public + 'units/approval/' + unit.enrolment.id + '?enrolmentId=' + course.enrolment.id)
        .then(function (result) {
            unit.enrolment.submitted = true;
            if (reloadCallback)
                reloadCallback();
        })
        .catch(function (error) {
            Toasts.add('error', i18n.t('error'), i18n.t(error.response.properties.errorCode));
        })
        .then(function () {
            loading = false;
        });
}

function reSubmitForApproval() {
    ModalManager.open('Resubmit for Approval', [], Modal_Confirm_Delete, {
        callback: function () {
            submitForApproval();
            resubmitted = true;
        }, text: 'Are you sure you want to resubmit for approval?', btnText: 'Resubmit'
    });
}

function viewFeedback(unit) {
    ModalManager.open(unit.name, [], Modal_Text, {text: unit.enrolment.approverFeedback});
}

const Component_Unit_Actions_Approval = {
    oninit: function (vnode) {
        let unit = vnode.attrs.unit;
        let course = vnode.attrs.course;
        let unitNumber = vnode.attrs.unitNumber;
        reloadCallback = vnode.attrs.reloadCallback;
        if (unit && course && unitNumber && unit.id === unitNumber) {
            return [
                unit.enrolment.allowAccess ? [
                    !unit.enrolment.isCompleted ? [
                        unit.enrolment.submitted ? reSubmitForApproval
                            : submitForApproval(unit, course)
                    ] : '',
                    unit.enrolment.approverFeedback ?
                        viewFeedback(unit) : ''
                ] : ''
            ];
        }
    },
    view: function (vnode) {
        let unit = vnode.attrs.unit;
        let course = vnode.attrs.course;
        reloadCallback = vnode.attrs.reloadCallback;
        return [
            unit?.enrolment?.allowAccess ? [
                !unit.enrolment.isCompleted ? [
                    unit.enrolment.submitted ? m('button.with-icon', {
                            onclick: reSubmitForApproval,
                            class: resubmitted ? 'disabled' : ''
                        }, resubmitted ? ['Submitted', m('i.icon-check ml-1 pr-0')] : 'Resubmit for Approval')
                        : m('button', {
                            onclick: function () {
                                submitForApproval(unit, course);
                            },
                            class: 'btn-cta' + (loading ? 'disabled' : '')
                        }, 'Submit for Approval')
                ] : '',
                unit.enrolment.approverFeedback ?
                    m('button', {
                        onclick: function () {
                            viewFeedback(unit);
                        },
                        class: 'btn-cta' + (!unit.enrolment.isCompleted ? ' ml-2' : '')
                    }, 'View Feedback')
                    : ''
            ] : ''

        ];
    }
};
export default Component_Unit_Actions_Approval;
