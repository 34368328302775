﻿import m from "mithril";
import Auth from "../../../shared/auth";
import i18n from "../../../shared/i18n/i18n";
import Component_Autocomplete from "../../../shared/components/component_autocomplete";
import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import Toasts from "../../../shared/toasts";
import FormUtilities from "../../../shared/utilities/form_utilities";
import ModalManager from "../../../shared/modal";
import Modal_Confirm_Delete from "../../../shared/components/modal_confirm_delete";
import UnitSettingsUtilities from "../unit_settings_utilities";
import RouterService from "../../../shared/services/router.service";

let saving, uploading, deleting, unit, templates, loading;

function saveUnitDetails() {
    if (FormUtilities.validateFields(".form input")) {
        saving = true;
        Requester.post(Gateway.admin + "units/approval/" + unit.id, {
            id: unit.id,
            name: unit.name,
            description: unit.description
        })
            .then(function (result) {
                saving = false;
                Toasts.add("success", i18n.t("saved_changes"), "");
            }).catch(function (error) {
            saving = false;
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
    }
}

function saveUnitSettings() {
    saving = true;
    Requester.post(Gateway.admin + "units/approval/" + unit.id + "/settings", {
        approverIds: unit.approvers.map(x => x.id),
        approvalEmailTemplateId: unit.approvalEmailTemplateId,
        rejectionEmailTemplateId: unit.rejectionEmailTemplateId
    })
        .then(function (result) {
            saving = false;
            Toasts.add("success", i18n.t("saved_changes"), "");
        }).catch(function (error) {
        saving = false;
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    });
}

function getTemplates() {
    Requester.get(Gateway.admin + "emails/templates")
        .then(function (result) {
            templates = result.entities;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    })
}

function getTemplateName(templateId) {
    if (templateId && templates)
        for (let i = 0; i < templates.length; i++)
            if (templates[i].properties.id === templateId)
                return templates[i].properties.name;

    return "None";
}

function confirmDeleteUnit() {
    ModalManager.open("Delete Unit", [], Modal_Confirm_Delete, {callback: deleteUnit});
}

function deleteUnit() {
    deleting = true;
    Requester.delete(Gateway.admin + "units/approval/" + unit.id)
        .then(function () {
            RouterService.navigateToUnits();
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            deleting = false;
        });
}

function getApproverDetails() {
    Requester.get(Gateway.admin + "users?userIds=" + unit.approverIds.join(','))
        .then(function (result) {
            unit.approvers = [];
            for (let i = 0; i < result.entities.length; i++)
                unit.approvers.push({id: result.entities[i].properties.id, name: result.entities[i].properties.name});
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function addApprover(user) {
    unit.approvers.push({id: user.id, name: user.firstName + " " + user.lastName});
}

function removeApprover(user) {
    unit.approvers = unit.approvers.filter(function (u) {
        return u.id !== user.id;
    });
}

const Component_Unit_Approval_Settings = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
        if (!unit.toAddresses)
            unit.toAddresses = [];

        if (unit.approverIds && unit.approverIds.length > 0)
            getApproverDetails();

        getTemplates();
    },
    view: function () {
        return [
            m(".content-box", [
                m("h3", "Details"),
                m(".form", {class: !Auth.validateRights(["unit_approval_edit"]) ? "disabled" : ""}, [
                    m(".form-section required", [
                        m(".form-label", i18n.t("name")),
                        m("input[type=text]", {
                            oninput: function () {
                                unit.name = this.value;
                            }, value: unit.name
                        })
                    ]),
                    m(".form-section", [
                        m(".form-label", i18n.t("description")),
                        m("textarea", {
                            oninput: function () {
                                unit.description = this.value;
                            }, value: unit.description
                        })
                    ]),
                    m(".form-buttons", [
                        !saving && !uploading ? m("button", {onclick: saveUnitDetails}, i18n.t("save_details")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
                    ])
                ])
            ]),
            m(".content-box", [
                m("h3", "Settings"),
                m(".form", {class: !Auth.validateRights(["unit_approval_edit"]) ? "disabled" : ""}, [
                    m(".form-section", [
                        m(".form-label", "Approvers"),
                        m(Component_Autocomplete, {
                            endpoint: "users?permissions=enrolments_view,enrolments_edit",
                            submitCallback: addApprover,
                            propertyName: "name"
                        }),
                        unit.approvers ? unit.approvers.map(function (user) {
                            return [
                                m("div", [
                                    m("button.btn-text", {
                                        onclick: function () {
                                            removeApprover(user);
                                        }
                                    }, "x"),
                                    user.name
                                ])
                            ];
                        }) : m(".text-gray padding", "Loading..."),
                    ]),
                    m(".form-section-shared", [
                        m(".form-section", [
                            m(".form-label", "Approved Email Template"),
                            templates ? [
                                m(".dropdown",
                                    m("button.with-icon pl-2", [
                                        getTemplateName(unit.approvalEmailTemplateId),
                                        m("i.icon-back rotate-270 ml-2")
                                    ]),
                                    m(".dropdown-list", [
                                        m("div", {
                                            onclick: function () {
                                                unit.approvalEmailTemplateId = null;
                                            }, class: !unit.approvalEmailTemplateId ? "text-bold" : ""
                                        }, "None"),
                                        templates && templates.length > 0 ? templates.map(function (template) {
                                            return [
                                                m("div", {
                                                    onclick: function () {
                                                        unit.approvalEmailTemplateId = template.properties.id;
                                                    },
                                                    class: unit.approvalEmailTemplateId === template.properties.id ? "text-bold" : ""
                                                }, template.properties.name)
                                            ];
                                        }) : ""
                                    ])
                                )
                            ] : m(".text-gray padding", "Loading...")
                        ]),
                        m(".form-section", [
                            m(".form-label", "Rejected Email Template"),
                            templates ? [
                                m(".dropdown",
                                    m("button.with-icon pl-2", [
                                        getTemplateName(unit.rejectionEmailTemplateId),
                                        m("i.icon-back rotate-270 ml-2")
                                    ]),
                                    m(".dropdown-list", [
                                        m("div", {
                                            onclick: function () {
                                                unit.rejectionEmailTemplateId = null;
                                            }, class: !unit.rejectionEmailTemplateId ? "text-bold" : ""
                                        }, "None"),
                                        templates && templates.length > 0 ? templates.map(function (template) {
                                            return [
                                                m("div", {
                                                    onclick: function () {
                                                        unit.rejectionEmailTemplateId = template.properties.id;
                                                    },
                                                    class: unit.rejectionEmailTemplateId === template.properties.id ? "text-bold" : ""
                                                }, template.properties.name)
                                            ];
                                        }) : ""
                                    ])
                                )
                            ] : m(".text-gray padding", "Loading...")
                        ])
                    ]),
                    m(".form-buttons", [
                        !saving && !uploading ? m("button", {onclick: saveUnitSettings}, "Save Settings") : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
                    ])
                ])
            ]),
            m(".content-box", [
                m(".form", {class: !Auth.validateRights(["unit_approval_edit"]) ? "disabled" : ""}, [
                    m(".flex-row justify-between", [
                        m("h3.mb-0", i18n.t("active")),
                        m(".btn btn-toggle", {
                            onclick: function () {
                                UnitSettingsUtilities.toggleActiveStatus([unit.id], !unit.isActive, saving);
                                unit.isActive = !unit.isActive;
                            }, class: unit.isActive ? "active" : ""
                        })
                    ])
                ])
            ]),
            Auth.validateRights(["unit_approval_delete"], [
                m(".content-box", [
                    !deleting ? m("button.btn-error", {onclick: confirmDeleteUnit}, "Delete Unit") : m("button.btn-text no-click text-error", ["Deleting", m(".loading-dots")])
                ])
            ])
        ];
    }
};
export default Component_Unit_Approval_Settings;
