﻿import m from 'mithril';
import Component_Document_Viewer from './component_document_viewer';

let reloadCallback;

function openDocument(unit, course) {
    m.mount(document.getElementById('document-mount'), {
        view: function () {
            return m(Component_Document_Viewer, {
                enrolmentId: course.enrolment.id,
                unit: unit,
                callback: closeDocument
            });
        }
    });
}

function closeDocument() {
    m.mount(document.getElementById('document-mount'), null);
    if (reloadCallback)
        reloadCallback();
}

const Component_Unit_Actions_Document = {
    oninit: function (vnode) {
        let unit = vnode.attrs.unit;
        let course = vnode.attrs.course;
        let unitNumber = vnode.attrs.unitNumber;
        reloadCallback = vnode.attrs.reloadCallback;
        if (unit && course && unitNumber && unit.id === unitNumber) {
            return unit?.enrolment?.allowAccess ? openDocument(unit, course) : '';
        }
    },
    view: function (vnode) {
        let unit = vnode.attrs.unit;
        let course = vnode.attrs.course;
        reloadCallback = vnode.attrs.reloadCallback;
        return unit?.enrolment?.allowAccess ?
            m('button', {
                onclick: function () {
                    openDocument(unit, course);
                },
                class: unit.enrolment.isCompleted ? '' : 'btn-cta'
            }, 'View Document')
            : '';
    }
};

export default Component_Unit_Actions_Document;
