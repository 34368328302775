import m from "mithril";
const {Sortable} = require("sortablejs")
import i18n from "../../../shared/i18n/i18n";

function initialiseLayoutOptionList() {
    Sortable.create(document.querySelector(".layout-options"), {
        group: {
            name: "layout",
            pull: "clone",
            put: false
        },
        animation: 100,
        sort: false,
        ghostClass: "dragging"
    });
}

function initialiseFieldOptionList() {
    Sortable.create(document.querySelector(".field-options"), {
        group: {
            name: "fields",
            pull: "clone",
            put: false
        },
        animation: 100,
        sort: false,
        ghostClass: "dragging",
        filter: ".disabled"
    });
}

const FormElementsMenu = {
    view: function () {
        return [
            m("h3", i18n.t("form_elements")),
            m("h5.mb-1", i18n.t("layout")),
            m(".form layout-options full-width", {oncreate: initialiseLayoutOptionList}, [
                m(".form-section", [m("i.icon-layout-horizontal mr-1"), i18n.t("section")])
            ]),
            m("h5.mb-1", "Fields"),
            m(".form field-options full-width", {oncreate: initialiseFieldOptionList}, [
                m(".form-section", {"data-type": "textfield"}, [m("i.icon-text mr-1"), i18n.t("textfield")]),
                m(".form-section", {"data-type": "textbox"}, [m("i.icon-text mr-1"), i18n.t("textbox")]),
                m(".form-section", {"data-type": "email"}, [m("i.icon-email mr-1"), i18n.t("email_address")]),
                m(".form-section", {"data-type": "number"}, [m("i.number-icon mr-1"), i18n.t("number")]),
                m(".form-section", {"data-type": "checkbox"}, [m("i.icon-checkbox-checked mr-1"), i18n.t("multiple_choice")]),
                m(".form-section", {"data-type": "radio"}, [m("i.radio-button.checked mr-1"), i18n.t("single_choice")])
            ])
        ];
    }
};
export default FormElementsMenu;
