﻿import m from "mithril";
import Toasts from "../../shared/toasts";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import ModalManager from "../../shared/modal";
import Modal_User_Change_Role from "./modal_user_change_role";
import Modal_User_Reset_Password from "./modal_user_reset_password";
import Modal_User_Assign_Courses from "./modal_user_assign_courses";
import Modal_User_Edit_Email from "./modal_user_edit_email";
import Auth from "../../shared/auth";
import Component_Enrolments from "../enrolment/component_enrolments";
import DateUtilities from "../../shared/utilities/date_utilities";
import i18n from "../../shared/i18n/i18n";
import UserService from "../../shared/services/user.service";
import RouterService from "../../shared/services/router.service";
import Modal_Confirm_Delete from "../../shared/components/modal_confirm_delete";

let loading = false;
let user = null;

function reloadUser() {
    setTimeout(function () {
        loadUser(user.properties.id);
    }, 1000);
}

function loadUser(id) {
    loading = true;
    Requester.get(Gateway.admin + "users/" + id)
        .then(function (result) {
            user = result;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function resend() {
    loading = true;
    Requester.post(Gateway.admin + "users/resend-invitation", user.properties.id)
        .then(function (result) {
            Toasts.add("success", i18n.t("email_sent"));
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function activateUser() {
    loading = true;
    Requester.post(Gateway.admin + "users/activate", user.properties.id)
        .then(function (result) {
            if (result.setPassword)
                ModalManager.open(i18n.t("set_user_password"), [], Modal_User_Reset_Password, {user: user.properties});
            else
                Toasts.add("success", i18n.t("user_activated"));
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
        loadUser(user.properties.id);
    });
}

function confirmRemoveFromOrganisation() {
    let message = i18n.t("remove_from_organisation_message");
    debugger;
    message = message.replace('{0}', getName());
    ModalManager.open(i18n.t("remove_from_organisation"), [], Modal_Confirm_Delete, {
        text: message,
        btnText: i18n.t("remove_user"),
        callback: removeFromOrganisation
    });
}

function removeFromOrganisation() {
    loading = true;
    UserService.removeFromOrganisation(user.properties.id)
        .then(() => RouterService.navigateToAdminUsers())
        .catch(error => Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode)))
        .finally(() => loading = false);
}

function changeRole() {
    const selectedRole = user.properties.accessLevel
        ? user.properties.accessLevel.name
        : null;
    ModalManager.open(i18n.t("changeRole"), [], Modal_User_Change_Role, {userIds: [user.properties.id], selectedRole: selectedRole, isInternalRole: false, callback: reloadUser});
}

function resetPassword() {
    ModalManager.open(i18n.t("reset_password"), [], Modal_User_Reset_Password, {user: user.properties});
}

function assignCourses() {
    ModalManager.open(i18n.t("assign_courses"), [], Modal_User_Assign_Courses, {userId: user.properties.id, callback: reloadUser});
}

function editEmail() {
    ModalManager.open(i18n.t("edit_email"), [], Modal_User_Edit_Email, {userId: user.properties.id, email: user.properties.email, callback: reloadUser});
}

function getName() {
    const fullName = user.properties.firstName + " " + user.properties.lastName;
    return fullName.trim();
}

let currentPage;
const Page_AdminUser = {
    oninit: function (vnode) {
        loadUser(vnode.attrs.id);
        currentPage = "users";
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        m("h1", i18n.t("user"))
                    ]),
                    m(".toolbar-section", [
                        Auth.validateRights(["users_email_edit"], m("button.with-icon", {onclick: editEmail}, [i18n.t("edit_email")])),
                        Auth.validateRights(["enrolments_create"], m("button.with-icon", {onclick: assignCourses}, [m("i.icon-sending"), i18n.t("assign_courses")])),
                        Auth.validateRights(["users_edit"], m("button.with-icon", {class: user && !user.properties.isRoleEditable ? "disabled" : "", onclick: changeRole}, [m("i.icon-user-id"), i18n.t("changeRole")])),
                        user && user.properties.isActive ? Auth.validateRights(["users_password_reset"], m("button.with-icon", {onclick: resetPassword}, [m("i.icon-lock"), i18n.t("reset_password")])) : "",
                        Auth.validateRights([Auth.rights.users_organisation_remove], m("button.with-icon", {onclick: confirmRemoveFromOrganisation}, [m("i.icon-delete"), i18n.t("remove_from_organisation")]))
                    ])
                ])
            ]),
            m(".content-box", [
                m(".form-section", [
                    m(".text-label", i18n.t("name")),
                    user ? getName() ? getName() : m(".text-gray", "Invited") : ""
                ]),
                user && user.properties.email ? m(".form-section", [
                    m(".text-label", i18n.t("email")),
                    user ? user.properties.email || "" : m(".loading-dots")
                ]) : "",
                user && user.properties.email !== user.properties.username ? m(".form-section", [
                    m(".text-label", i18n.t("username")),
                    user ? user.properties.username || "" : m(".loading-dots")
                ]) : "",
                m(".form-section", [
                    m(".text-label", i18n.t("role")),
                    user ? (user.properties.accessLevel ? i18n.t(user.properties.accessLevel.name) : i18n.t("default")) : m(".loading-dots")
                ]),
                m(".form-section", [
                    m(".text-label", i18n.t("status")),
                    user ? user.properties.isActive ? i18n.t("active") : [
                        user.properties.hasSsoAccount ?  i18n.t("single sign-on active") :
                        i18n.t("inactive"),
                        user.properties.hasSsoAccount ? "":
                        user.properties.email  ? m("button.btn btn-text ml-2", {onclick: resend}, i18n.t("resend_invitation")) : m("button.btn btn-text ml-2", {onclick: activateUser}, i18n.t("activate"))
                    ] : m(".loading-dots")
                ]),
                m(".form-section", [
                    m(".text-label", i18n.t("last_active")),
                    user ? DateUtilities.getFormattedDateFromUTC(user.properties.lastActive) : m(".loading-dots")
                ])
            ]),
            !loading && user ? [
                m(Component_Enrolments, {userId: user.properties.id})
            ] : ""
        ];
    }
};
export default Page_AdminUser;
