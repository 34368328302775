import m from "mithril";
import i18n from "../../shared/i18n/i18n";
import FormUtilities from "../../shared/utilities/form_utilities";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import SessionController from "../../shared/session";
import AuthLogo from "../../shared/components/component_logo";
import Toasts from "../../shared/toasts";
import publicIp from "public-ip";
import { Datepicker } from "vanillajs-datepicker";
import Language_Selector from "../../shared/components/component_language_selector";
import DateUtilities from "../../shared/utilities/date_utilities";
import ResponsiveUtilities from "../../shared/utilities/responsive_utilities";
import SharedConstants from "../../shared/shared_constants";
import RouterService from "../../shared/services/router.service";

var moment = require('moment');
const { load } = require("recaptcha-v3");

let saving = false;
let unverifiedError = false;
let clientIP;
let countryCodes, allcountryCodes, codeValue, defaultCodeValue, loadingPhone, parsePhoneNumberResult,
    phoneValidatorMessage;
let values = {
    firstName: null,
    lastName: null,
    email: null,
    mobileNo: null,
    mobile: null,
    confirmEmail: null,
    middleName: "",
    locale: i18n.locale,
    dateOfBirth: null,
};
let day, year, month
let emailUsed = false;
let emailValid = true;
const dateOfBirthTracker = {
    inputDate: "",
    calendarDate: undefined,
    inputDateUpdated: false
}

let birthDate, dobValidatorMessage, dobIsValid, phoneValid, hasAgreed;
const getClientIp = async () => await publicIp.v4({
    fallbackUrls: ['https://ifconfig.co/ip']
});
let ssoLink;
let loadingSso = false;

function getSsoLinks() {
    loadingSso = true;
    Requester.get(Gateway.identity + "sso")
        .then(function (result) {
            result.map(function (link) {
                if (link.url) {
                    ssoLink = link.url;
                }
            });
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            loadingSso = false;
        });
}

function setCountryCode(country) {
    values.countryCode = country.countryCode + " (+" + country.countryCodeNumber + ")";
    codeValue = values.countryCode;
    defaultCodeValue = codeValue;
    countryCodes = [];
}

function parsePhoneNumber(phone) {
    loadingPhone = true;
    Requester.get(Gateway.public + "phone/parse-phone-number?countryCode=" + codeValue.substring(0, 2) + "&phoneNumber=" + phone)
        .then(function (result) {
            if (result && result.validNumber && result.numberType.includes('MOBILE')) {
                parsePhoneNumberResult = result;
                values.mobileNo = result.formatted;
                values.mobile = result.internationalFormat;
                phoneValidatorMessage = null;
                phoneValid = true;
            } else {
                phoneValid = false;
                phoneValidatorMessage = i18n.t("mobile_not_valid");
            }
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            loadingSso = false;
        });
}

function getAllCountryCodes() {
    loadingPhone = true;
    phoneValid = false;
    Requester.get(Gateway.public + "phone/countries?language=" + i18n.currentLocale.substring(0, 2))
        .then(function (result) {
            if (result) {
                allcountryCodes = result;
                countryCodes = [];
                defaultCodeValue = allcountryCodes.find(x => x.countryCode == i18n.countryCode).countryCode + " (+" + allcountryCodes.find(x => x.countryCode == i18n.countryCode).countryCodeNumber + ")";
                codeValue = defaultCodeValue;
            }
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            loadingSso = false;
        });
}

function redirectToSso() {
    if (SessionController.session && SessionController.session.environment && SessionController.session.environment.ssoConfigured && ssoLink && !loadingSso) {
        window.setTimeout(function () {
            window.open(ssoLink, "_self");
        }, 5000);
    }
}

function submit() {
    if (!FormUtilities.validateFields("input")) {
        return;
    }
    saving = true;
    if (month < 10) {
        month = '0' + month
    }
    if (day < 10) {
        day = '0' + day
    }
    const datePickerValues = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        mobileNo: values.mobile,
        confirmEmail: values.confirmEmail,
        middleName: values.middleName,
        locale: i18n.locale,
        dateOfBirth: year + '-' + month + '-' + day,
    }
    getRecaptchaToken().then(recaptchaToken => {
        Requester.post(Gateway.identity + `register-visitor?recaptchaToken=${recaptchaToken}`, datePickerValues, { "x-forwarded-for": clientIP })
            .then(function (result) {
                if (result.success) {
                    Toasts.add("success", i18n.t("success"), i18n.t(result.messageCode));
                    if (result.messageCode == 'IdentitySSOAccountConnected') {
                        redirectToSso();
                    }
                } else {
                    Toasts.add("warn", i18n.t("warning"), i18n.t(result.messageCode));
                }
                window.setTimeout(function () {
                    RouterService.navigateToPublicLogin();
                }, 5000);
            })
            .catch(function (error) {
                if (error.code === 409) {
                    unverifiedError = true;
                    m.redraw();
                }
                if (error.code === 500) {
                    if (error.response.properties.errorCode === 'IdentityReCaptchaValidationFailed') {
                        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                        window.setTimeout(function () {
                            RouterService.navigateToPublicLogin();
                        }, 3000);
                    } else if (error.response.properties.errorCode === 'IdentityUnableToCreateSSOAccount' || error.response.properties.errorCode === 'IdentityUnableToCreateOnsiteAccount') {
                        Toasts.add("error", i18n.t("error"), i18n.t('IdentityUnableToCreateAccount'));
                    } else {
                        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                    }
                } else {
                    Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                }
            })
            .finally(function () {
                saving = false;
            });
    });
}

function getRecaptchaToken() {
    return load(window.ENV.RECAPTCHA_SITE).then(recaptcha => recaptcha.execute('validate_captcha'));
}

function dobValidator(date) {
    dobIsValid = null;
    if (date) {
        const dob = new Date(date);
        const today = new Date();
        const difference = today - dob;
        const age = Math.floor(difference / (1000 * 3600 * 24) / 365)
        const minDob = new Date('11 January 1900');
        age >= 16 && dob > minDob ? dobIsValid = true : dobIsValid = false
        return age >= 16 && dob > minDob;
    }
}

var isDate = function (date) {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

function getDobErrorMessage(dob, dateFormat = null) {
    dobValidatorMessage = ''

    if (!isDate(dob)) {
        dobValidatorMessage = dateFormat ? `${i18n.t('Please enter a valid date value')} (${dateFormat})` : i18n.t('Please enter a valid date value');
        return;
    }

    const today = new Date();
    const age = (today.getFullYear()) - (dob.getFullYear());
    const minDob = new Date('1 January 1900');
    if (age < 0) {
        dobValidatorMessage = i18n.t('Cannot enter / save a future date');
    }
    if (age < 16) {
        dobValidatorMessage = i18n.t('Worker can not be younger than 16 years of age');
    }
    if (dob < minDob) {
        dobValidatorMessage = i18n.t('Cannot enter / save a date before 1st January 1900');
    }
}

function isEmailUsed(email) {
    if (email && isEmailValid(email)) {
        Requester.get(Gateway.identity + "IsEmailUnique?email=" + email)
            .then(function (result) {
                emailUsed = result.value;
            })
            .catch(function (error) {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            });
    }
}

function isEmailValid(email) {
    if (email) {
        const getIndex = email.indexOf('@')
        if (getIndex > 0) {
            emailValid = true;
            return true
        } else {
            emailValid = false;
            return false;
        }
    }
}

const Component_visitor_form = {
    oninit: function () {
        dobValidatorMessage = '';
        values = [''];
        hasAgreed = false;
        getClientIp().then((res) => clientIP = res);
        getAllCountryCodes();
        if (SessionController.session && SessionController.session.environment && SessionController.session.environment.ssoConfigured)
            getSsoLinks();
    },
    view: function () {
        return [
            m(".relative", [
                m(AuthLogo),
                m("text", i18n.t("register_as_guest")),
                m('.pt-2.pb-2', [m("strong", i18n.t("language_preference")),
                m(Language_Selector, { dropdown_class: "pb-2 pt-1" })
                ]),
                m("form", {
                        onsubmit: function (e) {
                            e.preventDefault();
                            submit();
                        }
                    }, [
                        m(".form-header", { class: "pt-2" }, i18n.t("personal_details")),
                        m(".form-section-container", [
                            m(".form-section required", [
                                m(".form-label", i18n.t("first_name")),
                                m("input[type=text]", {
                                    oninput: function () {
                                        values.firstName = this.value;
                                    },
                                    value: values.firstName
                                })
                            ]),
                            m(".form-section", [
                                m(".form-label", i18n.t("middle_name")),
                                m("input[type=text]", {
                                    oninput: function () {
                                        values.middleName = this.value;
                                    }, value: values.middleName
                                })
                            ]),
                            m(".form-section required", [
                                m(".form-label", i18n.t("last_name")),
                                m("input[type=text]", {
                                    oninput: function () {
                                        values.lastName = this.value;
                                    }, value: values.lastName
                                })
                            ]),
                            m(".form-section required", [
                                m(".form-label", i18n.t("date_of_birth")),
                                m("input[type=text] .calendar", {
                                placeholder: DateUtilities.getLocaleDateString(i18n.locale),
                                    oncreate: v => {
                                        new Datepicker(v.dom, {
                                            language: i18n.locale,
                                            updateOnBlur: true,
                                            container: "div",
                                            minDate: "1/1/1900",
                                            showOnClick: !ResponsiveUtilities.mobileAndTabletCheck(),
                                            showOnFocus: !ResponsiveUtilities.mobileAndTabletCheck()
                                        });
                                    },
                                    oninput: function () {                                        
                                        dateOfBirthTracker.inputDate = this.value;
                                        dateOfBirthTracker.inputDateUpdated = true;
                                    },
                                    onblur: function () {
                                        let localeDateFormat = DateUtilities.getLocaleDateString(i18n.locale);
                                        let dob = null;
                                        let calendarDateUpdated = this.datepicker.getDate('dd/mm/yyyy') != dateOfBirthTracker.calendarDate;

                                        if (dateOfBirthTracker.inputDateUpdated || !calendarDateUpdated) {
                                            this.datepicker.setDate(dateOfBirthTracker.inputDate);
                                            dateOfBirthTracker.calendarDate = this.datepicker.getDate('dd/mm/yyyy');
                                            dateOfBirthTracker.inputDateUpdated = false;
                                            dob = moment(this.value, localeDateFormat).toDate();
                                        } else {
                                            dob = this.datepicker.getDate();
                                        }

                                        values.dateOfBirth = this.value;
                                        birthDate = dob;
                                        dobValidator(dob) ? (
                                            day = dob.getDate(),
                                            month = dob.getMonth() + 1,
                                            year = dob.getFullYear()
                                        ) : getDobErrorMessage(dob, localeDateFormat);
                                    },
                                    value: this.value
                                }),
                                dobValidator(birthDate) ? "" : m(".text-error padding", {style: "font-size: 0.75rem;"}, dobValidatorMessage)
                            ]),
                            m(".form-section required", [
                                m(".form-label", i18n.t("email_address")),
                                m("input[type=email]", {
                                    oninput: function () {
                                        values.email = this.value.toLowerCase();
                                    },
                                    onblur:function () {
                                        isEmailUsed(values.email);
                                        isEmailValid(values.email);
                                    },
                                    value: values.email
                                }),
                                emailUsed ? m(".text-error padding", {style: "font-size: 0.75rem;"}, i18n.t('Email must be unique')) : "",
                                !emailValid ? m(".text-error padding", {style: "font-size: 0.75rem;"}, i18n.t('Email must be valid')): '',
                            ]),
                            m(".form-section required", [
                                m(".form-label", i18n.t("confirm_email")),
                                m("input[type=email]", {
                                    oninput: function () {
                                        values.confirmEmail = this.value.toLowerCase();
                                    }, value: values.confirmEmail
                                }),
                                values.confirmEmail != undefined && values.confirmEmail != values.email ? m(".text-error padding", {style: "font-size: 0.75rem;"}, i18n.t('email_fields_match')) : '',
                            ]),
                            m(".form-section required", [
                                m('.dropdown-list mb-0 pb-0',
                                    i18n.supportedLocales.map(local => {
                                        return m('div', {
                                            class: i18n.isSelectedLanguageCode(local.code) ? 'active' : '',
                                            onclick: function () {
                                                if (!local.disable) {
                                                    i18n.loadAndSetLocale(local.code);
                                                }
                                            }
                                        }, [m('div', {class: local.disable ? "" : ""}, local.display)])
                                    })
                                ),
                                m(".form-label", i18n.t("mobile_number")),
                                m("input[type=text] .countryCode", {
                                    style: "width: 33%;",
                                    onfocus: function () {
                                        this.setSelectionRange(0, this.value.length)
                                        countryCodes = allcountryCodes
                                    },
                                    onblur: function () {
                                        if (!this.value || this.value.length == 0 || (this.value && !allcountryCodes.indexOf(this.value) >= 0)) {
                                            codeValue = defaultCodeValue;
                                        }
                                    },
                                    oninput: function (e) {
                                        if (this.value) {
                                            countryCodes = allcountryCodes.filter(x => x.countryName.toLowerCase().includes(this.value.toLowerCase()))
                                        }
                                        codeValue = this.value;
                                    },
                                    value: codeValue

                                }),
                                m("input[type=text] .phoneNo .mobileNo", {
                                    style: "width: 65%; margin-left:2%;",
                                    onfocus: function () {
                                        countryCodes = [];
                                    },
                                    oninput: function () {
                                        values.mobileNo = this.value;
                                        if (this.value.length > 2) {
                                            parsePhoneNumber(this.value);
                                        } else {
                                            phoneValidatorMessage = i18n.t("mobile_not_valid");
                                        }
                                    }, value: values.mobileNo
                                }),
                                phoneValidatorMessage ? m(".text-error padding", {style: "font-size: 0.75rem;"}, phoneValidatorMessage) : "",
                                m("ul.ul-no-style.list", [
                                    countryCodes ? countryCodes.map(cc => {
                                        return m('li', {
                                            class: 'list-items',
                                            onclick: function () {
                                                setCountryCode(cc);
                                            }
                                        }, [m('div', {class: ""}, [
                                            cc.countryName,
                                            m("span", {class: "muted"}, ", (+" + cc.countryCodeNumber + ")")
                                        ])])
                                    }) : ""
                                ])

                            ])
                        ]),
                        m(".form-section checkbox-section", [
                            m(".perm-checkbox", {
                                    onclick: function () {
                                        hasAgreed = !hasAgreed;
                                        countryCodes = [];
                                    }
                                }, [
                                    !!hasAgreed ? m("i.icon-checkbox-checked") : m("i.icon-checkbox-empty text-gray"),
                                    m("text", i18n.t("agree")),
                                ],
                                m("a", {
                                        href: SharedConstants.avettaEnduserAgreementUrl,
                                        target: "_blank"
                                    }, i18n.t("end_user_service_agreement")
                                ))
                        ]),
                        m("button", {
                            "type": "submit",
                            class: !hasAgreed || saving || !dobIsValid || !phoneValid || !emailValid || emailUsed ? "disabled" : ""
                        }, i18n.t("submit"))]
                )
            ])
        ]
    }
}

export default Component_visitor_form;
