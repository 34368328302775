import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import ModalManager from "../../shared/modal";
import FormUtilities from "../../shared/utilities/form_utilities";
import Modal_Confirm from "../../shared/components/modal_confirm";

let originalUnit, enrolmentId, formUnit, exitCallback, saving, loading, dirty, modalId;

function loadForm() {
    loading = true;
    formUnit = null;
    Requester.get(Gateway.public + "units/form/" + originalUnit.enrolment.id)
        .then(function (result) {
            formUnit = result;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .then(function () {
            loading = false;
        });
}

function saveForm() {
    if (formHasValidationErrors()) {
        displayFieldRequiredErrors = true;
        return;
    };

    if (FormUtilities.validateFields("#form .form-input")) {
        saving = true;

        let fields = [];
        for (let i = 0; i < formUnit.sections.length; i++) {
            for (let x = 0; x < formUnit.sections[i].fields.length; x++) {
                let field = formUnit.sections[i].fields[x];

                if (field.type === "checkbox") {
                    field.value = [];
                    for (let o = 0; o < field.options.length; o++) {
                        if (field.options[o].selected)
                            field.value.push(field.options[o].value);
                    }
                }

                if (field.type === "radio") {
                    for (let o = 0; o < field.options.length; o++) {
                        if (field.options[o].selected)
                            field.value = field.options[o].value;
                    }
                }

                if (!field.value)
                    field.value = "";

                fields.push({
                    fieldEnrolmentId: field.fieldEnrolmentId,
                    id: field.id,
                    name: field.name,
                    type: field.type,
                    value: field.type === "checkbox" ? field.value : [field.value]
                });
            }
        }

        let data = {
            id: originalUnit.enrolment.id,
            unitId: formUnit.id,
            unitName: formUnit.name,
            fields: fields
        };

        Requester.post(Gateway.public + "units/form?enrolmentId=" + enrolmentId, data)
            .then(function (result) {
                exitCallback();
                ModalManager.close(modalId);
                Toasts.add("success", i18n.t("progress_saved"));
            })
            .catch(function (error) {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            })
            .finally(function () {
                saving = false;
            });
    }
}

function onCancel() {
    if (dirty) {
        promptToCancel();
    } else {
        cancel();
    }
}

function promptToCancel() {
    const attributes = {
        confirmCallback: null,
        cancelCallback: cancel,
        messageText: i18n.t("unsaved_changes_message"),
        confirmButtonLabel: i18n.t("continue_editing"),
        cancelButtonLabel: i18n.t("discard"),
        confirmButtonClasses: "button",
        cancelButtonClasses: "button.btn-error"
    };
    ModalManager.open(i18n.t("unsaved_changes"), [], Modal_Confirm, attributes)
}

function cancel() {
    exitCallback();
    ModalManager.close(modalId);
}

function checkSectionDependencies(section) {
    let ids = [];
    let reachedCurrent = false;

    if (!section.prerequisiteOptionIds || section.prerequisiteOptionIds.length === 0)
        return true;
    else {
        for (let i = 0; i < formUnit.sections.length; i++) {
            if (formUnit.sections.id === section.id)
                reachedCurrent = true;

            if (!reachedCurrent) {
                for (let x = 0; x < formUnit.sections[i].fields.length; x++) {
                    if (formUnit.sections[i].fields[x].type === "checkbox" || formUnit.sections[i].fields[x].type === "radio") {
                        for (let z = 0; z < formUnit.sections[i].fields[x].options.length; z++) {
                            if (formUnit.sections[i].fields[x].options[z].selected)
                                ids.push(formUnit.sections[i].fields[x].options[z].id);
                        }
                    }
                }
            }
        }
    }

    if (ids.length === 0)
        return false;

    for (let i = 0; i < section.prerequisiteOptionIds.length; i++) {
        if (ids.indexOf(section.prerequisiteOptionIds[i]) === -1) {
            return false;
        }
    }

    return true;
}

function checkFieldDependencies(field) {
    let ids = [];
    let reachedCurrent = false;

    if (!field.prerequisiteOptionIds || field.prerequisiteOptionIds.length === 0)
        return true;
    else {
        for (let i = 0; i < formUnit.sections.length; i++) {
            for (let x = 0; x < formUnit.sections[i].fields.length; x++) {
                if (formUnit.sections[i].fields[x].id === field.id)
                    reachedCurrent = true;

                if (!reachedCurrent) {
                    if (formUnit.sections[i].fields[x].type === "checkbox" || formUnit.sections[i].fields[x].type === "radio") {
                        for (let z = 0; z < formUnit.sections[i].fields[x].options.length; z++) {
                            if (formUnit.sections[i].fields[x].options[z].selected)
                                ids.push(formUnit.sections[i].fields[x].options[z].id);
                        }
                    }
                }
            }
        }
    }

    if (ids.length === 0)
        return false;

    for (let i = 0; i < field.prerequisiteOptionIds.length; i++) {
        if (ids.indexOf(field.prerequisiteOptionIds[i]) === -1) {
            return false;
        }
    }

    return true;
}

function isEmptyString(string) {
    return string === null || string.trim() === '';
}

function requiredFieldIsEmpty(field) {
    if (!field.isRequired) {
        return false;
    }

    if (field.type === "radio" || field.type === "checkbox") {
        return !field.options.some(o => o.selected);
    } else {
        return isEmptyString(field.value);
    }
}

function fieldIsRequiredMessage(field) {
    let message = "";
    switch (field.type) {
        case "email":
            message = i18n.t("email_not_valid");
            break;
        case "number":
            message = i18n.t("number_not_valid");
            break;
        case "date":
            message = i18n.t("date_not_valid");
            break;
        case "checkbox":
            message = i18n.t("checkbox_not_valid");
            break;
        case "radio":
            message = i18n.t("radio_button_not_valid");
            break;
        case "textfield":
        case "textbox":
        default:
            message = i18n.t("field_mandatory");
            break;
    }

    return message;
}

function getTextErrorElem(message, field) {
    addFieldValidationErrors(message, field);
    return m(".text-error padding", { style: "font-size: 0.75rem;" }, message)
}

function addFieldValidationErrors(message, field) {
    fieldValidationErrorsDict[field.id] = `${message}`;
    return "";
}

function clearFieldValidationErrors(field) {
    delete fieldValidationErrorsDict[field.id]
    return "";
}

function formHasValidationErrors() {
    return Object.keys(fieldValidationErrorsDict).length > 0;
}

function getFieldClass(field) {
    let c = [];
    if (field.isRequired)
        c.push("required");
    if (field.hasError)
        c.push("has-error");

    return c.join(" ");
}

let invalidEmailFieldIds;
let isEmailValid;

let displayFieldRequiredErrors;
var fieldValidationErrorsDict;

const Modal_Form = {
    oninit: function (vnode) {
        enrolmentId = vnode.attrs.enrolmentId;
        originalUnit = vnode.attrs.unit;
        exitCallback = vnode.attrs.callback;
        invalidEmailFieldIds = [];
        isEmailValid = invalidEmailFieldIds.length === 0;
        displayFieldRequiredErrors = false;
        fieldValidationErrorsDict = {};

        loadForm();
        dirty = false;
    },
    view: function (vnode) {
        return [
            formUnit ? [
                m(".form-sections form full-width", { class: vnode.attrs.readOnly ? "disable-fields" : "" }, [
                    formUnit.sections.map(function (section) {
                        return checkSectionDependencies(section) ?
                            m(".form-section mb-2 full-width", [
                                m(".tile tile-centered m-0", [
                                    m(".tile-content", [
                                        m(".form-label", section.name),
                                        section.info ? m(".form-subtitle text-italic mb-3", section.info) : ""
                                    ])
                                ]),
                                section.fields.length > 0 ? m(".form form-section-fields full-width", [
                                    section.fields.map(function (field) {
                                        return checkFieldDependencies(field) ?
                                            m(".form-section", { class: getFieldClass(field) }, [
                                                m(".form-label", field.name),
                                                field.type === "textfield" ? m("input[type=text].form-input", {
                                                    value: field.value,
                                                    oninput: function () {
                                                        dirty = true;
                                                        field.value = this.value;
                                                    },
                                                    placeholder: field.info
                                                })
                                                    : field.type === "textbox" ? m("textarea.form-input", {
                                                        value: field.value,
                                                        oninput: function () {
                                                            dirty = true;
                                                            field.value = this.value;
                                                        },
                                                        placeholder: field.info
                                                    })
                                                        : field.type === "email" ? m("input[type=email].form-input", {
                                                            value: field.value,
                                                            oninput: function () {
                                                                dirty = true;
                                                                field.value = this.value;
                                                                FormUtilities.validateEmail(this.value, !field.isRequired) ? invalidEmailFieldIds.splice(invalidEmailFieldIds.indexOf(this.value)) : invalidEmailFieldIds.push(this.value);
                                                            },
                                                            placeholder: i18n.t("enter_email")
                                                        })
                                                            : field.type === "number" ? m("input[type=number].form-input", {
                                                                value: field.value,
                                                                oninput: function () {
                                                                    dirty = true;
                                                                    field.value = this.value;
                                                                },
                                                                placeholder: field.info
                                                            })
                                                                : field.type === "date" ? m("input[type=text].form-input", {
                                                                    value: field.value,
                                                                    oninput: function () {
                                                                        dirty = true;
                                                                        field.value = this.value;
                                                                    },
                                                                    placeholder: field.info
                                                                })
                                                                    : field.type === "checkbox" ? [
                                                                        m(".form-input checkbox-group", [
                                                                            field.options.map(function (option) {
                                                                                return [
                                                                                    m("label", [
                                                                                        m("input[type=checkbox]", {
                                                                                            name: field.id,
                                                                                            oncreate: function (e) {
                                                                                                e.dom.checked = option.selected;
                                                                                            },
                                                                                            onchange: function () {
                                                                                                option.selected = this.checked;
                                                                                            }
                                                                                        }),
                                                                                        option.value
                                                                                    ])
                                                                                ]
                                                                            })
                                                                        ])
                                                                    ] : field.type === "radio" ? [
                                                                        m(".form-input radio-group", [
                                                                            field.options.map(function (option) {
                                                                                return [
                                                                                    m("label", [
                                                                                        m("input[type=radio]", {
                                                                                            name: field.id,
                                                                                            oncreate: function (e) {
                                                                                                e.dom.checked = option.selected;
                                                                                            },
                                                                                            onchange: function () {
                                                                                                for (let i = 0; i < field.options.length; i++) {
                                                                                                    field.options[i].selected = false;
                                                                                                }

                                                                                                option.selected = this.checked;
                                                                                            }
                                                                                        }),
                                                                                        option.value
                                                                                    ])
                                                                                ]
                                                                            })
                                                                        ])
                                                                    ] : ""
                                                , requiredFieldIsEmpty(field) ? (displayFieldRequiredErrors ? getTextErrorElem(fieldIsRequiredMessage(field), field) : addFieldValidationErrors(fieldIsRequiredMessage(field), field)) : field.type === "email" && invalidEmailFieldIds.indexOf(field.value) != -1 ? getTextErrorElem(i18n.t('email_must_be_valid'), field) : clearFieldValidationErrors(field)
                                            ])
                                            : ""
                                    })
                                ]) : ""
                            ])
                            : ""
                    })
                ]),
                m(".form-buttons", [
                    !vnode.attrs.readOnly ? [
                        !saving && isEmailValid ? m("button.btn-focus", { onclick: saveForm }, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("save"), m(".loading-dots")]),
                        !saving ? m("button", { onclick: onCancel }, i18n.t("cancel")) : ""
                    ] : ""
                ])
            ] : m(".text-gray", [i18n.t("loading"), m(".loading-dots")]),
            formHasValidationErrors() && displayFieldRequiredErrors ? m(".text-error padding", { style: "font-size: 0.75rem;" }, i18n.t("complete_required_fields")) : ""
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};

export default Modal_Form;
