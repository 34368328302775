import m from "mithril";
const defaultLocale = "en-AU";
const messageUrl = "/lang/{locale}.json";
// https://medium.com/i18n-and-l10n-resources-for-developers/localizing-mithril-applications-728fca63eaf3
// https://github.com/PhraseApp-Blog/mithriljs-i18n-2021.git
import Datepicker from 'vanillajs-datepicker/Datepicker';
import es from 'vanillajs-datepicker/locales/es';
import fr from 'vanillajs-datepicker/locales/fr';
import de from 'vanillajs-datepicker/locales/de';
import enAU from 'vanillajs-datepicker/locales/en-AU';
import enCA from 'vanillajs-datepicker/locales/en-CA';
import enIE from 'vanillajs-datepicker/locales/en-IE';
import ptBR from 'vanillajs-datepicker/locales/pt-BR';
//Q2 Languages:
import cs from 'vanillajs-datepicker/locales/cs';
import da from 'vanillajs-datepicker/locales/da';
import enGB from 'vanillajs-datepicker/locales/en-GB';
import hu from 'vanillajs-datepicker/locales/hu';
import nl from 'vanillajs-datepicker/locales/nl';
import pl from 'vanillajs-datepicker/locales/pl';
import pt from 'vanillajs-datepicker/locales/pt';
import fi from 'vanillajs-datepicker/locales/fi';
import it from 'vanillajs-datepicker/locales/it';

let loading = true;
const SUPPORTED_LOCALES = [
    {display: 'العربية', code: 'ar-001', disable: true, countryCode: 'AE'},
    {display: 'čeština', code: 'cs-CZ', disable: false, countryCode: 'CZ'},
    {display: 'dansk', code: 'da-DK', disable: false, countryCode: 'DK'},
    {display: 'Deutsch (Deutschland)', code: 'de', disable: false, countryCode: 'DE'},
    {display: 'English (Australia)', code: 'en-AU', disable: false, countryCode: 'AU'},
    {display: 'English (Canada)', code: 'en-CA', disable: false, countryCode: 'CA'},
    {display: 'English (United Kingdom)', code: 'en-GB', disable: false, countryCode: 'GB'},
    {display: 'English (United States)', code: 'en-US', disable: false, countryCode: 'US'},
    {display: 'español (España)', code: 'es', disable: false, countryCode: 'ES'},
    {display: 'español (Latinoamérica)', code: 'es-419', disable: false, countryCode: 'ES'},
    {display: 'suomi', code: 'fi-FI', disable: false, countryCode: 'FI'},
    {display: 'Filipino/Tagalog (Pilipinas)', code: 'fp-PH', disable: true, countryCode: 'PH'},
    {display: 'français (Canada)', code: 'fr-CA', disable: false, countryCode: 'CA'},
    {display: 'français (France)', code: 'fr', disable: false, countryCode: 'FR'},
    {display: 'हिन्दी', code: 'hi-IN', disable: true, countryCode: 'IN'},
    {display: 'magyar', code: 'hu-HU', disable: false, countryCode: 'HU'},
    {display: 'Bahasa Indonesia', code: 'id-ID', disable: true, countryCode: 'ID'},
    {display: 'italiano (Italia)', code: 'it-IT', disable: false, countryCode: 'IT'},
    {display: '日本語', code: 'ja-JP', disable: true, countryCode: 'JP'},
    {display: '한국어', code: 'ko-KR', disable: true, countryCode: 'KR'},
    {display: 'Bahasa Melayu', code: 'ms-MY', disable: true, countryCode: 'MY'},
    {display: 'Nederlands (Nederland)', code: 'nl-NL', disable: false, countryCode: 'NL'},
    {display: 'norsk', code: 'no-NO', disable: true, countryCode: 'NO'},
    {display: 'polski', code: 'pl-PL', disable: false, countryCode: 'PL'},
    {display: 'português (Brasil)', code: 'pt-BR', disable: false, countryCode: 'BR'},
    {display: 'português (Portugal)', code: 'pt-PT', disable: false, countryCode: 'PT'},
    {display: 'română', code: 'ro-RO', disable: true, countryCode: 'RO'},
    {display: 'slovenčina', code: 'sk-SK', disable: true, countryCode: 'SK'},
    {display: 'svenska', code: 'sv-SE', disable: true, countryCode: 'SE'},
    {display: 'ไทย', code: 'th-TH', disable: true, countryCode: 'TH'},
    {display: 'Türkçe', code: 'tr-TR', disable: true, countryCode: 'TR'},
    {display: 'Tiếng Việt', code: 'vi-VN', disable: true, countryCode: 'VN'},
    {display: '简体中文', code: 'zh-CN', disable: true, countryCode: 'CN'},
    {display: '中文（繁體）', code: 'zh-TW', disable: true, countryCode: 'TW'}
]
Object.assign(Datepicker.locales, es, fr, de, enAU, enCA, enIE, ptBR, cs, da, enGB, hu, nl, pl, pt, fi, it);
//TODO Create a map for following languages. These languages locale keys are different from datepicker keys:
// cs-CZ, da-DK, en-CA, en-GB, en-US, es-419, fr-CA, hu-HU, nl-NL, pl-PL, pt-BR, pt-PT, fi-FI, it-IT
//Above locales to be mapped to datePicker keys as following: cs, da, enCA, enGB, enIE, es, fr, hu, nl, pl, pt, fi, it
function t(key) {
    if (i18n && i18n.messages && i18n.status === "idle") {
        return i18n.messages[key] || key;
    } else {
        if (key !== 'loading') {
            // loading page shows up when the translation is not ready.
            console.log('WARN language translation is not ready for key:', key, 'status:', i18n.status, 'messages:', i18n.messages);
        }
        return key
    }
}


function setDefaultLocale() {
    const storageLocale = window.localStorage.getItem("locale");
    if (!!storageLocale) {
        loadAndSetLocale(storageLocale);
    } else {
        loadAndSetLocale(defaultLocale);
    }
}

function loadAndSetLocale(newLocale) {
    console.log('loading locale file, for locale:', newLocale)
    if (i18n.currentLocale === newLocale) {
        return;
    }
    i18n.status = "loading";
    loading = true;
    fetchMessages(newLocale, (messages) => {
        i18n.messages = messages;
        i18n.currentLocale = newLocale;
        i18n.locale = newLocale;
        i18n.countryCode = SUPPORTED_LOCALES.find(x => x.code == newLocale).countryCode,
        i18n.status = "idle";
        loading = false;
        console.log('locale loaded for locale:', newLocale);
        window.localStorage.setItem("locale", newLocale);
    });
}

function fetchMessages(locale, onComplete) {
    let url_ = messageUrl.replace("{locale}", locale);
    console.log('fetching locale:', url_);
    m.request(url_).then(
        onComplete,
    );
}

function getLanguageSelectorTitle() {
    let locale = i18n.locale;
    if (locale) {
        if (locale === 'en-AU') {
            return 'English (Australia)';
        }
        if (locale === 'en-CA') {
            return 'English (Canada)';
        }
        if (locale === 'en-US') {
            return 'English (United States)';
        }
        if (locale === 'en-GB') {
            return 'English(United Kingdom)';
        }
        if (locale === 'pt-BR') {
            return 'português (Brasil)';
        }
        if (locale === 'pt-PT') {
            return 'português (Portugal)';
        }
        if (locale === 'cs-CZ') {
            return 'čeština';
        }
        if (locale === 'da-DK') {
            return 'dansk';
        }
        if (locale === 'de') {
            return 'Deutsch (Deutschland)';
        }
        if (locale === 'hu-HU') {
            return 'magyar';
        }
        if (locale === 'nl-NL') {
            return 'Nederlands (Nederland)';
        }
        if (locale === 'pl-PL') {
            return 'polski';
        }
        if (locale === 'fi-FI') {
            return 'suomi';
        }
        if (locale === 'it-IT') {
            return 'italiano (Italia)';
        }
        if (locale === 'es-419') {
            return 'español (Latinoamérica)';
        }
        if (locale === 'es') {
            return 'español (España)';
        }
        if (locale === 'fr-CA') {
            return 'français (Canada)';
        }
        if (locale === 'fr') {
            return 'français (France)';
        }
    } else {
        return i18n.t('language_selector');
    }
}

function isSelectedLanguageCode(code) {
    return i18n.locale === code;
}

const i18n = {
    supportedLocales: SUPPORTED_LOCALES,
    defaultLocale,
    locale: defaultLocale,
    messages: {}, // loadAndSetLocale() populates these
    status: "loading",
    countryCode: SUPPORTED_LOCALES.find(x => x.code == defaultLocale).countryCode,
    t,
    loadAndSetLocale,
    setDefaultLocale,
    getLanguageSelectorTitle,
    isSelectedLanguageCode
};

export default i18n;
