import SessionController from "../../shared/session";
import m from "mithril";

let loading = false;

const Page_Logout = {
    oninit: function () {
        SessionController.logout();
    },
    view: function () {
        return m(".text", "You have been logged out!")
    }
}

export default Page_Logout;
