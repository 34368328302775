import m from 'mithril';
import Requester from './request';
import Gateway from './app_settings';
import StyleUtilities from './utilities/style_utilities';
import RouterService from './services/router.service';

const SessionController = {
    session: null,
    logout: function () {
        Requester.post(Gateway.identity + "logout", null)
            .finally(function () {
                SessionController.clearSession();

                if (localStorage.getItem("sso-logout")) {
                    const url = localStorage.getItem("sso-logout");
                    localStorage.removeItem("sso-logout");
                    window.location = url;
                } else {
                    RouterService.navigateToPublicLogin();
                }
            });
    },
    clearSession: function () {
        console.log('Clearing session.');
        SessionController.session = null;
        localStorage.removeItem("session");
    },
    checkSession: function () {
        if (!SessionController.getCookie("auth")) {
            SessionController.clearSession();
            m.redraw();
        }
    },
    setSession: function (res) {
        return new Promise(function (resolve) {
            const localSession = localStorage.getItem("session") ? JSON.parse(localStorage.getItem("session")) : {};

            if (!SessionController.session)
                SessionController.session = {};

            if (res.identity) {
                SessionController.session.identity = res.identity;
                localSession.identity = res.identity;
            }

            if (res.environment) {
                SessionController.session.environment = res.environment;
                localSession.environment = res.environment;
            }

            localStorage.setItem("session", JSON.stringify(localSession));

            localStorage.removeItem("xsrf-token");
            const token = SessionController.getCookie("http-xsrf-token");
            if (token)
                localStorage.setItem("xsrf-token", token);

            SessionController.setStyle();

            resolve();
        });
    },
    setStyle: function () {
        if (SessionController.session && SessionController.session.environment) {
            const existing = document.getElementById("custom-styles");
            if (existing)
                existing.parentNode.removeChild(existing);

            let primary = SessionController.session.environment.primaryColour;
            primary = null; // todo primary colour from the session has a wrong value.
            const lightText = SessionController.session.environment.lightText;

            if (primary || lightText) {
                let styles = ":root * {";
                if (primary) {
                    styles += "--accent-1: " + primary + ";";
                    styles += "--accent-2: " + StyleUtilities.adjustColour(primary, 30) + ";";
                }

                if (lightText !== null)
                    styles += lightText ? "--text: #fff;" : "--text-1: #000;";

                styles += "}";
                const stylesheet = document.createElement("style");
                stylesheet.type = "text/css";
                stylesheet.innerText = styles;
                stylesheet.id = "custom-styles";
                document.head.appendChild(stylesheet);
            }
        }
    },
    getSession: function () {
        const expired = !SessionController.getCookie("http-xsrf-token");
        return new Promise(function (resolve, reject) {
            if (!expired && SessionController.session) {
                resolve(SessionController.session);
            } else if (!expired && localStorage.getItem("session")) {
                SessionController.session = JSON.parse(localStorage.getItem("session"));
                SessionController.setStyle();
                resolve(SessionController.session);
            } else {
                Requester.get(Gateway.identity + "auth" + (!localStorage.getItem("session") ? "?env=true" : ""))
                    .then(function (result) {
                        SessionController.setSession(result);
                        resolve(result);
                    })
                    .catch(function () {
                        console.log('ERROR when fetching a new session.')
                        reject(null);
                    });
            }
        });
    },
    verifySso: function (token) {
        return new Promise(function (resolve, reject) {
            Requester.post(Gateway.identity + "login/ext?token=" + token)
                .then(function (result) {
                    SessionController.setSession(result);
                    resolve();
                })
                .catch(function (error) {
                    reject(error);
                })
                .finally(function () {
                    window.history.replaceState(null, "", window.location.pathname);
                });
        });
    },
    sso: function () {
        return !!(SessionController.session && SessionController.session.identity && SessionController.session.identity.sso);
    },
    getCookie: function (name) {
        const nameEquals = name + "=";
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let c = cookies[i];

            while (c.charAt(0) === ' ')
                c = c.substring(1, c.length);

            if (c.indexOf(nameEquals) === 0)
                return c.substring(nameEquals.length, c.length);
        }
        return null;
    }
};

export default SessionController;
