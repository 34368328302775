import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import Loading from "../../shared/loading";
import ResponsiveUtilities from "../../shared/utilities/responsive_utilities";
let loading, unit, enrolmentId, exitCallback, certLink;


function loadCertificate() {
    loading = true;
    Requester.post(Gateway.public + "units/certificate/" + unit.enrolment.id + "?enrolmentId=" + enrolmentId)
        .then(function () {
            certLink = Gateway.public + "units/certificate/" + unit.enrolment.id + "?enrolmentId=" + enrolmentId;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .then(function () {
            loading = false;
        });
}

const Component_Certificate_Viewer = {
    oninit: function (vnode) {
        enrolmentId = vnode.attrs.enrolmentId;
        unit = vnode.attrs.unit;
        loadCertificate();
        exitCallback = vnode.attrs.callback;
    },
    view: function () {
        return [
            m("#player-bg"),
            m("#player", [
                m(".flex-row justify-between align-center padding", [
                    m("h3", unit ? unit.name : m(".loading-dots")),
                    m("button.btn-cta", { onclick: exitCallback }, i18n.t("close"))
                ]),
                !loading ? [
                    unit ? [
                        ResponsiveUtilities.mobileAndTabletCheck()
                        ? m("div", { class: "centre-content" }, [
                            m("p", "Oops! Your browser doesn't support previewing PDFs"),
                            m("p", {}, [m("a", { class: "hyperlink", target: "_blank", href: certLink + "&locale=" + window.localStorage.getItem("locale") }, "Download Instead")])
                        ])
                        : m("object", { data: certLink + "&locale=" + window.localStorage.getItem("locale"), class: "embed-test-responsive", type: "application/pdf", width: "100%", height: "100%" })
                    ] : i18n.t("cant_load")
                ] : m(Loading)
            ])
        ];
    }
};

export default Component_Certificate_Viewer;
