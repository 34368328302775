import SessionController from "../../shared/session";
import m from "mithril";
import OrgFileService from "../../shared/storage/org_file_service";
import Loading from "../../shared/loading";
import i18n from "../../shared/i18n/i18n";
import RouterService from "../../shared/services/router.service";

let loading = false;

function setBg() {
    if (SessionController.session.environment && SessionController.session.environment.backgroundName)
        document.getElementById("auth-right").style.backgroundImage = "url('" + OrgFileService.getBackgroundPath() + "')";
}

const PublicLayout_Auth = {
    oninit: function () {
        document.documentElement.setAttribute("spud-theme", "light");

        SessionController.checkSession();

        if (!SessionController.session) {
            loading = true;
            SessionController.getSession()
                .finally(function () {
                    loading = false;
                    m.redraw();
                });
        }
    },
    view: function (vnode) {
        return i18n.status === "loading"
            ? m("p", i18n.t("loading") + "...")
            : [!loading || SessionController.session ?
                m("#page-wrapper", [
                    m("#page", [
                        m("main.flex-row", [
                            m("#auth-left", [
                                vnode.children,
                                m("small", {class: "grecaptcha-badge-replacement"}, [
                                    "This site is protected by reCAPTCHA and the Google",
                                    m("a", {class: "hyperlink", target: "_blank", href: "https://policies.google.com/privacy"}, " Privacy Policy"),
                                    " and",
                                    m("a", {class: "hyperlink", target: "_blank", href: "https://policies.google.com/terms"}, " Terms of Service"),
                                    " apply."
                                ]),
                                m(".auth-left-footer", [
                                    SessionController.session?.environment?.redirectToLogin ? "" : m(m.route.Link, {href: RouterService.getPublicCoursesUrl()}, i18n.t("return_course_library"))
                                ])
                            ]),
                            m("#auth-right", {oncreate: setBg})
                        ])
                    ])
                ])
                : m(Loading),
                m("#toaster")
            ];
    }
}
export default PublicLayout_Auth
