﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import Modal_Confirm_Delete from "../../shared/components/modal_confirm_delete";
import ModalManager from "../../shared/modal";
import Auth from "../../shared/auth";
import DateUtilities from "../../shared/utilities/date_utilities";
import Component_Table_Footer from "../../shared/components/component_table_footer";
import RouterService from "../../shared/services/router.service";

let loading, filter, search, courseId, userId,
    enrolments, callback, selected = [],
    total = 0, limit = 25, offset = 0, orderBy;

function submitPagination(lim, off) {
    limit = lim;
    offset = off;
    loadEnrolments();
}

function loadEnrolments() {
    loading = true;
    enrolments = null;
    selected = [];

    let params = [];
    if (filter)
        params.push("filter=" + filter);
    if (search)
        params.push("search=" + search);
    if (orderBy)
        params.push("orderBy=" + orderBy);

    params.push("limit=" + limit);
    params.push("offset=" + offset * limit);

    let endpoint = "courses/enrolments";
    if (courseId)
        endpoint = "courses/" + courseId + "/enrolments";
    else if (userId)
        endpoint = "users/" + userId + "/enrolments";

    Requester.get(Gateway.admin + endpoint + (params.length > 0 ? "?" + params.join("&") : ""))
        .then(function (result) {
            total = result.properties.totalCount;
            enrolments = result.entities;
            if (callback)
                callback(enrolments);
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function toggleAllSelected() {
    if (selected.length > 0)
        selected = [];
    else {
        if (enrolments) {
            for (let i = 0; i < enrolments.length; i++) {
                selected.push(enrolments[i].properties.id);
            }
        }
    }
}

function toggleSelected(id) {
    let index = selected.indexOf(id);
    if (index === -1)
        selected.push(id);
    else
        selected.splice(index, 1);
}

function changeResult(status) {
    loading = true;
    Requester.post(Gateway.admin + "courses/enrolments/result", {ids: selected, status: status})
        .then(function () {
            loadEnrolments();
            selected = [];
        }).catch(function (error) {
        Toasts.add("error", i18n.t("cant_save"), error.response.properties.detail);
        loading = false;
    });
}

function archiveEnrolments() {
    ModalManager.open(i18n.t("archive_enrolments"), [], Modal_Confirm_Delete, {
        callback: function () {
            submitArchiveEnrolments(true);
        },
        text: i18n.t("archived_enrolments_in_database"),
        btnText: i18n.t("archive")
    });
}

function submitArchiveEnrolments(isArchived) {
    loading = true;
    Requester.post(Gateway.admin + "courses/enrolments/archive", {ids: selected, isArchived: isArchived, skipUnarchiveLatestActiveEnrolment: true})
        .then(function () {
            loadEnrolments();
            selected = [];
        }).catch(function (error) {
        Toasts.add("error", i18n.t("cant_save"), error.response.status.detail);
        loading = false;
    });
}

function orderByUserName() {
    if (orderBy === "userName") {
        orderBy = "userNameDesc";
    } else if (orderBy === "userNameDesc") {
        orderBy = null;
    } else {
        orderBy = "userName";
    }

    loadEnrolments();
}

function orderByCourseName() {
    if (orderBy === "courseName") {
        orderBy = "courseNameDesc"
    } else if (orderBy === "courseNameDesc") {
        orderBy = null;
    } else {
        orderBy = "courseName"
    }

    loadEnrolments();
}

function orderByLastActive() {
    if (orderBy === "lastActiveDate") {
        orderBy = "lastActiveDateDesc"
    } else if (orderBy === "lastActiveDateDesc") {
        orderBy = null;
    } else {
        orderBy = "lastActiveDate"
    }
    loadEnrolments();
}

function orderByCompletion() {
    if (orderBy === "completionDate") {
        orderBy = "completionDateDesc"
    } else if (orderBy === "completionDateDesc") {
        orderBy = null;
    } else {
        orderBy = "completionDate"
    }

    loadEnrolments();
}

const Component_Enrolments = {
    oninit: function (vnode) {
        callback = vnode.attrs.callback;
        search = vnode.attrs.search;
        courseId = vnode.attrs.courseId;
        userId = vnode.attrs.userId;
        loadEnrolments();
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        Auth.validateRights(["enrolments_edit"],
                            m(".dropdown", {class: selected.length === 0 ? "disabled" : ""},
                                m("button.with-icon pl-2", [
                                    m("i.icon-edit"),
                                    i18n.t("change_result"),
                                    m("i.icon-back rotate-270 ml-2")
                                ]),
                                m(".dropdown-list", [
                                    filter !== "archived" ?
                                        [
                                            m("div", {
                                                onclick: function () {
                                                    changeResult();
                                                }
                                            }, i18n.t("not_started")),
                                            m("div", {
                                                onclick: function () {
                                                    changeResult("passed");
                                                }
                                            }, i18n.t("passed")),
                                            m("div", {
                                                onclick: function () {
                                                    changeResult("failed");
                                                }
                                            }, i18n.t("failed")),
                                            Auth.validateRights([Auth.rights.enrolments_delete], m("div", {onclick: archiveEnrolments}, i18n.t("archived")))
                                        ] : Auth.validateRights([Auth.rights.enrolments_delete], m("div", {
                                            onclick: function () {
                                                submitArchiveEnrolments(false);
                                            }
                                        }, i18n.t("un_archive")))
                                ])
                            )
                        ),
                        selected && selected.length > 0 ? m("span.ml-1", [selected.length, " selected"]) : ""
                    ]),
                    m(".toolbar-section", [
                        m(".dropdown",
                            m("button.with-icon pl-2", [
                                filter === "passed" ? i18n.t("passed") : filter === "incomplete" ? i18n.t("incomplete") : filter === "not_passed" ? i18n.t("failed") : filter === "archived" ? i18n.t("archived") : i18n.t("all_enrolments"),
                                m("i.icon-back rotate-270 ml-2")
                            ]),
                            m(".dropdown-list", [
                                m("div", {
                                    class: !filter ? "active" : "", onclick: function () {
                                        filter = null;
                                        loadEnrolments();
                                    }
                                }, i18n.t("all_enrolments")),
                                m(".label-circle label-success", {
                                    class: filter === "passed" ? "active" : "",
                                    onclick: function () {
                                        filter = "passed";
                                        loadEnrolments();
                                    }
                                }, i18n.t("passed")),
                                m(".label-circle label-warning", {
                                    class: filter === "incomplete" ? "active" : "",
                                    onclick: function () {
                                        filter = "incomplete";
                                        loadEnrolments();
                                    }
                                }, i18n.t("incomplete")),
                                m(".label-circle label-error", {
                                    class: filter === "not_passed" ? "active" : "",
                                    onclick: function () {
                                        filter = "not_passed";
                                        loadEnrolments();
                                    }
                                }, i18n.t("failed")),
                                Auth.validateRights([Auth.rights.enrolments_archived_view],
                                    m(".label-circle", {
                                        class: filter === "archived" ? "active" : "",
                                        onclick: function () {
                                            filter = "archived";
                                            loadEnrolments();
                                        }
                                    }, i18n.t("archived")))
                            ])
                        ),
                        m(".has-icon-right", [
                            m("input[type=search]", {
                                oninput: function () {
                                    search = this.value;
                                }, onkeyup: function (e) {
                                    if (e.keyCode === 13) {
                                        loadEnrolments();
                                    }
                                }, value: search, placeholder: i18n.t("search")
                            }),
                            m("i.icon-x c-hand", {
                                onclick: function () {
                                    search = null;
                                    loadEnrolments();
                                }
                            }),
                            m("i.icon-search c-hand", {
                                onclick: function () {
                                    loadEnrolments();
                                }
                            })
                        ])
                    ])
                ]),
                m(".flex-column", [
                    !loading ? [
                        enrolments ? [
                            m(".table-wrapper flex-auto", [
                                enrolments.length > 0 ? [
                                    m("table.full-width", [
                                        m("thead",
                                            m("tr", [
                                                m("th", m(".toolbar-checkbox", {onclick: toggleAllSelected}, m("i", {class: selected.length > 0 ? "icon-checkbox-checked" : "icon-checkbox-empty"}))),
                                                m("th.c-hand", {onclick: orderByUserName}, [i18n.t("user"), m("i.icon-forward align-middle", {class: orderBy === "userName" ? "rotate-90" : orderBy === "userNameDesc" ? "rotate-270" : "rotate-90 translucent"})]),
                                                m("th.c-hand", {onclick: orderByCourseName}, [i18n.t("course"), m("i.icon-forward align-middle", {class: orderBy === "courseName" ? "rotate-90" : orderBy === "courseNameDesc" ? "rotate-270" : "rotate-90 translucent"})]),
                                                m("th", i18n.t("enrolment_version")),
                                                m("th", i18n.t("result")),
                                                m("th.c-hand", {onclick: orderByLastActive}, [i18n.t("last_active"), m("i.icon-forward align-middle", {class: orderBy === "lastActiveDate" ? "rotate-90" : orderBy === "lastActiveDateDesc" ? "rotate-270" : "rotate-90 translucent"})]),
                                                m("th.c-hand", {onclick: orderByCompletion}, [i18n.t("completed"), m("i.icon-forward align-middle", {class: orderBy === "completionDate" ? "rotate-90" : orderBy === "completionDateDesc" ? "rotate-270" : "rotate-90 translucent"})])
                                            ])
                                        ),
                                        m("tbody", enrolments.map(function (enr) {
                                            return [
                                                m("tr", [
                                                    m("td.list-checkbox c-hand pl-1 pr-1", {
                                                        onclick: function () {
                                                            toggleSelected(enr.properties.id);
                                                        }, style: "width: 18px;"
                                                    }, m("i", {class: selected.indexOf(enr.properties.id) !== -1 ? "icon-checkbox-checked" : "icon-checkbox-empty"})),
                                                    m("td.text-bolder", m(m.route.Link, {href: RouterService.getEnrolmentUrl(enr.properties.id)}, enr.properties.firstName ? (enr.properties.firstName + " " + enr.properties.lastName) : m(".text-gray", "Invited"))),
                                                    m("td", m(m.route.Link, {
                                                        href: RouterService.getEnrolmentUrl(enr.properties.id),
                                                        tabindex: "-1"
                                                    }, enr.properties.courseName)),
                                                    m("td", m(m.route.Link, {
                                                        href: RouterService.getEnrolmentUrl(enr.properties.id),
                                                        tabindex: "-1"
                                                    }, enr.properties.courseVersion)),
                                                    m("td", m(m.route.Link, {
                                                        href: RouterService.getEnrolmentUrl(enr.properties.id),
                                                        tabindex: "-1"
                                                    }, enr.properties.isCompleted && enr.properties.isPassed ? m(".label-circle label-success", i18n.t("passed")) : enr.properties.isCompleted && !enr.properties.isPassed ? m(".label-circle label-error", i18n.t("failed")) : enr.properties.isStarted ? m(".label-circle label-warning", i18n.t("in_progress")) : m(".label-circle", i18n.t("not_started")))),
                                                    m("td", m(m.route.Link, {
                                                        href: RouterService.getEnrolmentUrl(enr.properties.id),
                                                        tabindex: "-1"
                                                    }, DateUtilities.getFormattedDateFromUTC(enr.properties.lastActiveDate))),
                                                    m("td", m(m.route.Link, {
                                                        href: RouterService.getEnrolmentUrl(enr.properties.id),
                                                        tabindex: "-1"
                                                    }, DateUtilities.getFormattedDateFromUTC(enr.properties.completionDate)))
                                                ])
                                            ];
                                        }))
                                    ])
                                ] : m(".text-gray padding", i18n.t("no_enrolments"))
                            ]),
                            m(Component_Table_Footer, {
                                callback: submitPagination,
                                total: total,
                                limit: limit,
                                offset: offset,
                                loading: loading,
                                item: "enrolments"
                            })
                        ] : m(".text-gray padding", i18n.t("unable_load_enrolments"))
                    ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
                ])
            ])
        ];
    }
};


export default Component_Enrolments;
