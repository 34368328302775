﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import SessionController from "../../shared/session";
import FormUtilities from "../../shared/utilities/form_utilities";
import AuthLogo from "../../shared/components/component_logo";
import Loading from "../../shared/loading";
import RouterService from "../../shared/services/router.service";

let loading = false;
let saving = false;
let values = {
    firstName: "",
    lastName: "",
    email: ""
};
let courseId = null;

function submit() {
    if (FormUtilities.validateFields("input")) {
        saving = true;
        Requester.post(Gateway.identity + "signup-guest", values)
            .then(function (result) {
                SessionController.setSession(result);

                if (courseId) {
                    RouterService.navigateToPublicCourse(courseId);
                } else {
                    RouterService.navigateOnLogin();
                }
            })
            .catch(function (error) {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            })
            .finally(function () {
                saving = false;
            });
    }
}

const PublicPage_Signup_Guest = {
    oninit: function () {
        if (SessionController.session && SessionController.session.identity) {
            RouterService.navigateOnLogin();
            Toasts.add("success", i18n.t("already_logged_in"), "");
        }
    },
    view: function () {
        return [
            m(".relative", [
                !loading ? [
                    m(AuthLogo),
                    m(".form-header", i18n.t("signup")),
                    m("form", {
                        onsubmit: function (e) {
                            e.preventDefault();
                            submit();
                        }
                    }, [
                        m(".form-section required", [
                            m(".form-label", i18n.t("first_name")),
                            m("input[type=text]", {
                                oninput: function () {
                                    values.firstName = this.value;
                                }, value: values.firstName
                            })
                        ]),
                        m(".form-section required", [
                            m(".form-label", i18n.t("last_name")),
                            m("input[type=text]", {
                                oninput: function () {
                                    values.lastName = this.value;
                                }, value: values.lastName
                            })
                        ]),
                        m(".form-section required", [
                            m(".form-label", i18n.t("email")),
                            m("input[type=email]", {
                                oninput: function () {
                                    values.email = this.value;
                                }, value: values.email
                            })
                        ]),
                        m(".form-buttons", [
                            !saving ? m("button", {"type": "submit"}, i18n.t("submit")) : m("button.btn-text no-click", [i18n.t("submit"), m(".loading-dots")])
                        ]),
                        m(".form-section mt-5", [
                            i18n.t("agreement"),
                            m(".form-buttons flex-left mt-1", [
                                m("a", {
                                    href: SessionController.session.environment.homepageUrl + "/terms",
                                    target: "_blank"
                                }, i18n.t("terms_of_use")),
                                m("a.ml-2", {
                                    href: SessionController.session.environment.homepageUrl + "/privacy",
                                    target: "_blank"
                                }, i18n.t("privacy_policy"))
                            ])
                        ])
                    ]),
                    m(".mt-5 text-center", [
                        "Already have an account?",
                        m(m.route.Link, {href: RouterService.getPublicLoginUrl(), class: "btn-text ml-1"}, "Log In")
                    ])
                ] : m(Loading, {class: "loading-inline"})
            ])
        ];
    }
};
export default PublicPage_Signup_Guest;
