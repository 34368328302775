import Page_Logout from "./account/auth/logout";
import Page_Account from "./account/account/account";
import Page_User from "./account/user/user";
import Page_Organisation from "./account/organisation/organisation";
import Page_Profile from "./account/profile/profile";
import Page_Invitation_Accept from "./account/auth/invitation_accept";
import Page_Reauthorise from "./account/auth/reauth";
import Page_Password_Forgot from "./account/auth/password_forgot";
import Page_Password_Forgot_Sent from "./account/auth/password_forgot_sent";
import Page_Password_Reset from "./account/auth/password_reset";
import AdminLayout from "./admin/layout/layout";
import Page_Enrolments from "./admin/enrolment/enrolments";
import Page_Enrolment from "./admin/enrolment/enrolment";
import Page_AdminReauthorise from "./admin/auth/reauth";
import AdminLayoutAuth from "./admin/layout/layout_auth";
import Page_Courses from "./admin/course/courses";
import Page_Course from "./admin/course/course";
import Page_Units from "./admin/unit/units";
import Page_Unit from "./admin/unit/unit";
import Page_Reports from "./admin/reports/reports";
import Page_AdminUser from "./admin/user/user";
import Page_AdminUsers from "./admin/user/users";
import Page_AdminOrganisation from "./admin/organisation/organisation";
import PublicLayout_Library from "./public/layout/layout_library";
import PublicPage_Courses from "./public/library/courses";
import PublicLayout from "./public/layout/layout";
import PublicPage_Course from "./public/course/course";
import PublicPage_Profile from "./public/profile/profile";
import Redirect_PegGateway_LoginPage from "./public/layout/layout_sso_login_redirect";
import PublicLayout_Auth from "./public/layout/layout_auth";
import PublicPage_Login_SSO_CAS from "./public/auth/login_sso_cas";
import AccountPage_Login_SSO_CAS from "./account/auth/login_sso_cas";
import PublicPage_Signup_Guest from "./public/auth/signup_guest";
import PublicPage_Signup_Post from "./public/auth/signup_post";
import PublicPage_Verify from "./public/auth/verify";
import PublicPage_Redeem from "./public/auth/redeem";
import PublicPage_Transition from "./public/auth/transition";
import PublicPage_Password_Forgot from "./public/auth/password_forgot";
import PublicPage_Password_Forgot_Sent from "./public/auth/password_forgot_sent";
import PublicPage_Password_Reset from "./public/auth/password_reset";
import PublicPage_Login from "./public/auth/login";
import Redirect_MobileAuth from "./public/auth/login_mobile_sso";
import Component_visitor_form from "./public/visitor/component_visitor_form";

import m from 'mithril';
import AccountLayout from './account/layout/layout';
import Page_Organisations from './account/organisation/organisations';
import Layout_Auth from './account/layout/layout_auth';
import Page_No_Account from './account/auth/no_account';
import Page_Users from "./account/user/users";
import PublicPage_Verify_SSO_Proxy from "./public/auth/verify_sso_proxy";


m.route.mode = "search"

function redirectWithTicket(path, courseId) {
    // Extract the ticket
    let url = window.location.href
    let ticketLength = url.indexOf('#!') - url.indexOf('ticket=');
    let ticket = url.substr(url.indexOf('ticket='), ticketLength)
    let target = url.substr(0, url.indexOf('?ticket=')) +`#!${path}?${ticket}`;
    if(!!courseId) {
        target += `&courseId=${courseId}`;
    }
    window.location.href = target;
}

const Routes = {
    "/": {
        render: function (vnode) {
            // In order to get CAS redirects working correctly alongside Hash routing we needed the root page to
            // point to the Public Login page.
            return m(PublicLayout_Auth, m(PublicPage_Login_SSO_CAS, vnode.attrs));
        }
    },
    "/proxy/:enrolmentId" : {
        render: function(vnode) {
            return m(PublicPage_Verify_SSO_Proxy,  vnode.attrs);
        }
    },
    "/public/mobile/login": {
        render: function(vnode) {
            return m(Redirect_MobileAuth);
        }
    },
    "/public/login": {
        render: function (vnode) {
            /*
            Because the way mithril does hash routing, cas will put the ticket before the hash. Even when the
            service url encodes the #!. So we have to check if we are on a CAS redirect and if so, pull the ticket out
            and redirect manually to the cas login route with the ticket in the right spot.
             */
            if(window.location.href.includes('ticket=')) {
                redirectWithTicket("/login/cas");
            }
            else {
                // Leaving this here for when we go full SSO only we can skip straight
                // to the SSO Login Page.
                // return m(Redirect_PegGateway_LoginPage);
                return m(PublicLayout_Auth, m(PublicPage_Login, vnode.attrs));
            }
        }
    },
    "/login/cas": {
        /*
        This is a processing path, nothing really to render. It is basically:
        if(logged_in) -> go to /public
        else -> go to public/login
         */
        render: function (vnode) {
            return m(PublicLayout_Auth, m(PublicPage_Login_SSO_CAS, vnode.attrs));
        }
    },
    "/invitation": {
        render: function (vnode) {
            return m(Layout_Auth, m(Page_Invitation_Accept, vnode.attrs));
        }
    },
    "/reset-password": {
        render: function (vnode) {
            return m(Layout_Auth, m(Page_Password_Reset, vnode.attrs));
        }
    },
    "/public/reset-password": {
        render: function (vnode) {
            // todo this reset password route was duplicated in the original code.
            return m(PublicLayout_Auth, m(PublicPage_Password_Reset, vnode.attrs));
        }
    },
    "/reports": {
        render: function (vnode) {
            return m(AdminLayout, m(Page_Reports, vnode.attrs));
        }
    },
    "/verify": {
        render: function (vnode) {
            return m(PublicLayout_Auth, m(PublicPage_Verify, vnode.attrs));
        }
    },
    "/redeem": {
        render: function (vnode) {
            return m(PublicLayout_Auth, m(PublicPage_Redeem, vnode.attrs));
        }
    },
    "/transition": {
        render: function (vnode) {
            return m(PublicLayout_Auth, m(PublicPage_Transition, vnode.attrs));
        }
    },
    "/login/jwt": {
        render: function (vnode) {
            return m(PublicLayout_Auth, m(PublicPage_Login_SSO, vnode.attrs));
        }
    },
    "/account/": {
        render: function (vnode) {
            return m(AccountLayout, m(Page_Organisations, vnode.attrs));
        }
    },
    "/account/organisations": {
        render: function (vnode) {
            return m(AccountLayout, m(Page_Organisations, vnode.attrs));
        }
    },
    "/account/organisations/:id": {
        render: function (vnode) {
            return m(AccountLayout, m(Page_Organisation, vnode.attrs));
        }
    },
    "/account/users": {
        render: function (vnode) {
            return m(AccountLayout, m(Page_Users, vnode.attrs));
        }
    },
    "/account/users/:id": {
        render: function (vnode) {
            return m(AccountLayout, m(Page_User, vnode.attrs));
        }
    },
    "/account/settings": {
        render: function (vnode) {
            return m(AccountLayout, m(Page_Account, vnode.attrs));
        }
    },
    "/account/profile": {
        render: function (vnode) {
            return m(AccountLayout, m(Page_Profile, vnode.attrs));
        }
    },
    "/account/no-account": {
        render: function (vnode) {
            return m(Layout_Auth, m(Page_No_Account, vnode.attrs));
        }
    },
    "/account/login": {
        render: function (vnode) {
            const SHEBANG = "%2F%23%21%2F";
            if(window.location.href.includes('ticket=')) {
                redirectWithTicket("/account/login/cas");
            }
            else {
                return m(Redirect_PegGateway_LoginPage, {path: `${SHEBANG}account`});
            }
        }
    },
    "/account/login/cas": {
        /*
        This is a processing path, nothing really to render. It is basically:
        if(logged_in) -> go to /account
        else -> go to acount/login
         */
        render: function (vnode) {
            return m(PublicLayout_Auth, m(AccountPage_Login_SSO_CAS, vnode.attrs));
        }
    },
    "/account/logout": {
        render: function (vnode) {
            return m(Page_Logout, vnode.attrs);
        }
    },
    "/account/reauth": {
        render: function (vnode) {
            return m(Layout_Auth, m(Page_Reauthorise, vnode.attrs));
        }
    },
    "/account/forgot-password": {
        render: function (vnode) {
            return m(Layout_Auth, m(Page_Password_Forgot, vnode.attrs));
        }
    },
    "/account/forgot-password-sent": {
        render: function (vnode) {
            return m(Layout_Auth, m(Page_Password_Forgot_Sent, vnode.attrs));
        }
    },
    "/admin/": {
        render: function (vnode) {
            return m(AdminLayout, m(Page_Enrolments, vnode.attrs));
        }
    },
    "/admin/enrolments": {
        render: function (vnode) {
            return m(AdminLayout, m(Page_Enrolments, vnode.attrs));
        }
    },
    "/admin/enrolments/:id": {
        render: function (vnode) {
            return m(AdminLayout, m(Page_Enrolment, vnode.attrs));
        }
    },
    "/admin/courses": {
        render: function (vnode) {
            return m(AdminLayout, m(Page_Courses, vnode.attrs));
        }
    },
    "/admin/courses/:id": {
        render: function (vnode) {
            return m(AdminLayout, m(Page_Course, vnode.attrs));
        }
    },
    "/admin/units": {
        render: function (vnode) {
            return m(AdminLayout, m(Page_Units, vnode.attrs));
        }
    },
    "/admin/units/:id": {
        render: function (vnode) {
            return m(AdminLayout, m(Page_Unit, vnode.attrs));
        }
    },
    "/admin/users": {
        render: function (vnode) {
            return m(AdminLayout, m(Page_AdminUsers, vnode.attrs));
        }
    },
    "/admin/users/:id": {
        render: function (vnode) {
            return m(AdminLayout, m(Page_AdminUser, vnode.attrs));
        }
    },
    "/admin/settings": {
        render: function (vnode) {
            return m(AdminLayout, m(Page_AdminOrganisation, vnode.attrs));
        }
    },
    "/admin/reauth": {
        render: function (vnode) {
            return m(AdminLayoutAuth, m(Page_AdminReauthorise, vnode.attrs));
        }
    },
    "/public/": {
        render: function (vnode) {
            return m(PublicLayout_Library, m(PublicPage_Courses, vnode.attrs));
        }
    },
    "/public/courses/:id": {
        // If coming from the proxy page we want the user to land at the course instead of the list of courses
        // The proxy page will add this route to the service redirect url so we must also check for the ticket
        // param and validate it from here as well.
        render: function (vnode) {
            if(window.location.href.includes('ticket=')) {
                redirectWithTicket("/login/cas", vnode.attrs.id);
            }
            return m(PublicLayout, m(PublicPage_Course, vnode.attrs));
        }
    },
    "/public/courses/:id/:unitNumber": {
        render: function (vnode) {
            if(window.location.href.includes('ticket=')) {
                redirectWithTicket("/login/cas", vnode.attrs.id, vnode.attrs.unitNumber);
            }
            return m(PublicLayout, m(PublicPage_Course, vnode.attrs));
        }
    },
    "/public/profile": {
        render: function (vnode) {
            return m(PublicLayout, m(PublicPage_Profile, vnode.attrs));
        }
    },
    "/public/login-as-visitor": {
        render: function (vnode) {
            return m(PublicLayout_Auth, m(Component_visitor_form, vnode.attrs));
        }
    },
    "/public/signup-guest": {
        render: function (vnode) {
            return m(PublicLayout_Auth, m(PublicPage_Signup_Guest, vnode.attrs));
        }
    },
    "/public/signup-post": {
        render: function (vnode) {
            return m(PublicLayout_Auth, m(PublicPage_Signup_Post, vnode.attrs));
        }
    },
    "/public/forgot-password": {
        render: function (vnode) {
            return m(PublicLayout_Auth, m(PublicPage_Password_Forgot, vnode.attrs));
        }
    },
    "/public/forgot-password-sent": {
        render: function (vnode) {
            return m(PublicLayout_Auth, m(PublicPage_Password_Forgot_Sent, vnode.attrs));
        }
    }
}

export { Routes };
