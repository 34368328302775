﻿import m from "mithril";
import {ACTION_TYPES} from "../../values";

import FormUtilities from "../../../shared/utilities/form_utilities";
import Requester from "../../../shared/request";
import ModalManager from "../../../shared/modal";
import Toasts from "../../../shared/toasts";
import Gateway from "../../../shared/app_settings";
import i18n from "../../../shared/i18n/i18n";
import UniqueName from "../../../shared/uniqueName";
import RouterService from "../../../shared/services/router.service";

let saving = false;
let uploading = false;
let unit = {
    name: "",
    description: "",
    actionType: ACTION_TYPES.sendEmail
};
let modalId;

function saveUnit() {
    if (FormUtilities.validateFields(".modal input")) {
        saving = true;
        Requester.post(Gateway.admin + "units/action", unit)
            .then(function (result) {
                saving = false;
                ModalManager.close(modalId);
                RouterService.navigateToUnit(result);
            }).catch(function (error) {
            saving = false;
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
    }
}

function getSelectedActionName() {
    if (unit.actionType === ACTION_TYPES.sendEmail)
        return "Send Email"
    else
        "None";
}

const Modal_Unit_Action_New = {
    oninit: function() {
        unit = {
            name: "",
            description: "",
            actionType: ACTION_TYPES.sendEmail
        };
    },
    view: function () {
        return [
            m(".form-section required", [
                m(".form-label", i18n.t("name")),
                m("input[type=text]", {
                    onchange: function () {
                        UniqueName.checkUniqueUnitName(this.value)
                    },
                    oninput: function () {
                        unit.name = this.value;
                    }, value: unit.name
                })
            ]),
            m(".form-section", [
                m(".form-label", i18n.t("description")),
                m("textarea", {
                    oninput: function () {
                        unit.description = this.value;
                    }, value: unit.description
                })
            ]),
            m(".form-section", [
                m(".form-label", "Action"),
                m(".dropdown",
                    m("button.with-icon pl-2", [
                        getSelectedActionName(),
                        m("i.icon-back rotate-270 ml-2")
                    ]),
                    m(".dropdown-list", [
                        m("div", {
                            onclick: function () {
                                unit.actionType = ACTION_TYPES.sendEmail
                            }
                        }, "Send Email")
                    ])
                )
            ]),
            m(".form-buttons", [
                !saving && !uploading && (UniqueName.isUnitNameUnique) && !(UniqueName.unitLoading) ? m("button", {onclick: saveUnit}, i18n.t("save")) : (UniqueName.isUnitNameUnique) ? m("button.btn-text no-click", ["Saving", m(".loading-dots")]) :
                    m("button.btn-text no-click", ["Save"])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Unit_Action_New;
