import m from "mithril";
import SessionController from '../../shared/session';
import Component_Profile_Settings from "./component_settings";
import i18n from "../../shared/i18n/i18n";
import RouterService from "../../shared/services/router.service";

let loading = false;
let tab = "settings";
let currentPage;

const Page_Profile = {
    oninit: function () {
        if (!SessionController.session || !SessionController.session.identity) {
            RouterService.navigateToAccount();
        }

        currentPage = "profile";
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section with-menu", [
                        m("h1", i18n.t("profile")),
                        m(".toolbar-menu", [
                            m("div", {class: "active"}, i18n.t("settings"))
                        ])
                    ])
                ])
            ]),
            m(".content-box", [
                !loading ? [
                    tab === "settings" ? m(Component_Profile_Settings)
                        : ""
                ] : m(".text-gray padding", ["Loading", m(".loading-dots")])
            ])
        ];
    }
}

export default Page_Profile;
