﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import SessionController from "../../shared/session";
import FormUtilities from "../../shared/utilities/form_utilities";
import AuthLogo from "../../shared/components/component_logo";
import Loading from "../../shared/loading";
import RouterService from "../../shared/services/router.service";
let loading, code, id, resent;

function submit() {
    if (FormUtilities.validateFields(".form-section input")) {
        loading = true;

        Requester.post(Gateway.identity + "verify-code", {user: id, token: code})
            .then(function (result) {
                SessionController.setSession(result);
                RouterService.navigateOnLogin();
            })
            .catch(function (error) {
                RouterService.navigateToPublicLogin();
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            })
            .finally(function () {
                loading = false;
            });
    }
}

function resend() {
    loading = true;
    Requester.post(Gateway.identity + "resend-verification", {value: id})
        .then(function () {
            resent = true;
            Toasts.add("success", "Verification Email Sent");
        })
        .catch(function () {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            loading = false;
        });
}

const PublicPage_Signup_Post = {
    oninit: function () {

        id = m.route.param("id");
        if (!id) {
            RouterService.navigateToPublicLogin();
        }

        if (SessionController.session && SessionController.session.identity && !SessionController.sso()) {
            RouterService.navigateOnLogin();
            Toasts.add("success", i18n.t("already_logged_in"), "");
        }
    },
    view: function () {
        return [
            m("div", [
                !loading ? [
                    m(AuthLogo),
                    m(".form-header", i18n.t("thanks_for_signup")),
                    m("div", [
                        m(".form-section", [
                            i18n.t("check_inbox_verification")
                        ]),
                        !resent ? m(".form-section", [
                            m("button.btn btn-text", {onclick: resend}, "Resend Verification Email")
                        ]) : ""
                    ]),
                    m(".mt-5", [
                        m(".form-section required", [
                            i18n.t("verification_code"),
                            m("input.form-input", {
                                oninput: function () {
                                    code = this.value
                                }
                            })
                        ]),
                        m(".form-buttons", [
                            m("button", {
                                onclick: submit,
                                class: code ? "btn-focus" : "disable-buttons"
                            }, i18n.t("submit"))
                        ])
                    ])
                ] : m(Loading, {class: "loading-inline"})
            ])
        ];
    }
};
export default PublicPage_Signup_Post;
