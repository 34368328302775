import m from "mithril";
import SessionController from "../../shared/session";
import OrgFileService from "../../shared/storage/org_file_service";
import Loading from "../../shared/loading";
import i18n from "../../shared/i18n/i18n";
import RouterService from "../../shared/services/router.service";

let loading = false;

function setBg() {
    if (SessionController.session && SessionController.session.environment && SessionController.session.environment.backgroundName)
        document.getElementById("auth-right").style.backgroundImage = "url('" + OrgFileService.getBackgroundPath() + "')";
}

const AdminLayoutAuth = {
    oninit: function (vnode) {
        document.documentElement.setAttribute("spud-theme", "light");

        SessionController.checkSession();

        if (!SessionController.session || (SessionController.session && vnode.attrs.authorise && !SessionController.session.identity)) {
            loading = true;
            SessionController.getSession()
                .finally(function () {
                    loading = false;
                    m.redraw();
                });
        }
    },
    view: function (vnode) {
        return i18n.status === "loading"
            ? m("p", i18n.t("loading") + "...")
            : [!loading ?
                m("#page-wrapper", [
                    m("#page", [
                        m("main.flex-row", [
                            m("#auth-left", [
                                vnode.children,
                                m(".auth-left-footer", [
                                    m(m.route.Link, {href: RouterService.getAdminUrl()}, i18n.t("home"))
                                ])
                            ]),
                            m("#auth-right", {oncreate: setBg})
                        ])
                    ])
                ])
                : m(Loading),
            m("#toaster")
        ];
    }
};

export default AdminLayoutAuth;
