import m from "mithril";

const Modal_Text = {
    view: function (vnode) {
        return [
            m("", vnode.attrs.text)
        ];
    }
};

export default Modal_Text;
