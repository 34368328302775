﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import Loading from "../../shared/loading";
import SessionController from "../../shared/session";
import RouterService from "../../shared/services/router.service";

let loading = true;
let enrolmentId;
const PublicPage_Verify_SSO_Proxy = {
    oninit: function (vnode) {
        enrolmentId = vnode.attrs.enrolmentId;
        loading = true;
        Requester.get(Gateway.public + `id/sso/is-account-active?enrolmentId=${enrolmentId}`)
                .then(function (result) {
                    loading = false;
                    if(result.isAccountActive) {
                        if (!!SessionController.session && SessionController.session.identity) {
                            RouterService.navigateToPublicCourse(result.courseId);
                        }
                        else {
                            Requester.get(Gateway.identity + "sso")
                                .then(function (ssoResult) {
                                    if(!!ssoResult && ssoResult.length) {
                                        // Create the cas service redirect to contain the course and course Id so
                                        // we can take the logged in user directly to the course they are looking for
                                        // The redirects to CAS gateway login.
                                        window.location.href = ssoResult[0].url + `%2F%23%21%2Fpublic%2Fcourses%2F${result.courseId}`;
                                    }
                                    else {
                                        RouterService.navigateToPublicLogin();
                                    }
                                })
                                .catch(function (error) {
                                    RouterService.navigateToPublicLogin();
                                });
                        }
                    }
                })
                .catch(function (error) {
                    Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                });
    },
    view: function () {
        return [
            loading ? m(Loading, {class: "loading-inline"}) :
                m("#page-wrapper", [
                    m("#page", [
                        m("main.flex-row", [
                            m("#auth-left", [
                                [
                                    m("div", {}, i18n.t("activate_sso_message"),
                                        m("div", [m("br"), m("button", { onclick: resendInvite }, i18n.t("resend_invitation"))])),
                                ]
                            ])
                        ])
                    ])
                ]),
                m("#toaster")
        ];
    }
};

function resendInvite() {
    Requester.get(Gateway.public + `id/sso/resend-invite?enrolmentId=${enrolmentId}`)
        .then(function (result) {
            if(result.value) {
                Toasts.add("success", i18n.t("email_sent"), "");
            }
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}
export default PublicPage_Verify_SSO_Proxy;
