import m from "mithril";
import SessionController from "../../shared/session";
import Toasts from "../../shared/toasts";
import Component_Header from "./header";
import Loading from "../../shared/loading";
import i18n from "../../shared/i18n/i18n";
import RouterService from "../../shared/services/router.service";

function auth() {
    SessionController.checkSession();

    if (!SessionController.session || !SessionController.session.identity) {
        SessionController.getSession().then(function () {
            if (SessionController.session.environment.redirectToLogin && !SessionController.session.identity) {
                RouterService.navigateToPublicLogin();
            }
        });
    }
}

const PublicLayout_Library = {
    oninit: function (vnode) {
        if (m.route.param("sso-auth")) {
            SessionController.verifySso(m.route.param("sso-auth"))
                .catch(function (error) {
                    Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                    auth();
                });
        } else {
            auth();
        }
    },
    view: function (vnode) {
        return i18n.status === "loading"
            ? m("p", i18n.t("loading") + "...")
            : [
            m("#layout", [
                m(Component_Header),
                SessionController.session ? m("main.flex-column", vnode.children) : m(Loading)
            ]),
            m("#toaster")
        ];
    }
};

export default PublicLayout_Library;
