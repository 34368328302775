import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import Auth from "../../shared/auth";
import TextEditor from "../../shared/components/component_text_editor";

let saving = false;
let course = null;
let uploading;

function saveDetails() {
    saving = true;
    course.description = TextEditor.content;
    Requester.post(Gateway.admin + "courses/" + course.id + "/edit-details", {
        Name: course.name,
        Description: course.description
    })
    .then(function () {
        if (document.getElementById("course-image-file-input").files.length > 0)
            uploadImage();
        else if (!course.imagePath)
            Toasts.add("error", i18n.t("select_file"), "");
        else
            Toasts.add("success", i18n.t("saved_changes"), "");
    })
    .catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    })
    .finally(function () {
        saving = false;
    });
}

function uploadImage() {
    const file = document.getElementById("course-image-file-input").files[0];
    uploading = true;

    const d = new FormData();

    if (!file)
        Toasts.add("error", i18n.t("select_file"), "");

    if (file.size / 2048 / 2048 > 1)
        Toasts.add("error", i18n.t("file_too_big"), i18n.t("file_less_than") + "1mb");

    d.append("file", file, file.name);

    Requester.upload(Gateway.admin + "courses/" + course.id + "/upload-image", d)
        .then(function () {
            Toasts.add("success", i18n.t("saved_changes"), "");
            course.imagePath = window.URL.createObjectURL(file);
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            saving = false;
        });
}

const Component_Course_Details = {
    oninit: function (vnode) {
        course = vnode.attrs.course;
    },
    view: function () {
        return [
            m(".content-box", [
                m(".form", {class: !Auth.validateRights(["courses_edit_details"]) ? "disabled" : ""}, [
                    m(".form-section required", [
                        m(".form-label", i18n.t("name")),
                        m("input[type=text]", {
                            oninput: function () {
                                course.name = this.value;
                            }, value: course.name
                        })
                    ]),
                    m(".form-section", [
                        m(".form-label", "Version"),
                        m(".form-label", course.version)
                    ]),
                    m(".form-section required", [
                        m(".form-label", i18n.t("description")),
                        m(TextEditor, {content: course.description})
                    ]),
                    m(".form-section required", [
                        m(".form-label inline-tip", {"data-tip": "480x300px 2MB max"}, i18n.t("image")),
                        m("input[type=file].form-input#course-image-file-input"),
                        course.imagePath ? [
                            m("img.mt-1", {src: course.imagePath})
                        ] : ""
                    ]),
                    m(".form-buttons", [
                        !saving ? m("button", {onclick: saveDetails}, i18n.t("save_details")) : m("button.btn-text no-click", ["Saving", m(".loading-dots")])
                    ])
                ])
            ])
        ];
    }
};

export default Component_Course_Details;
