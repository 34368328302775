﻿import m from "mithril";
import FormUtilities from "../../shared/utilities/form_utilities";
import ModalManager from "../../shared/modal";
import i18n from "../../shared/i18n/i18n";

let saving, item, callback, modalId;

function submit() {
    if (FormUtilities.validateFields(".modal input")) {
        if (callback)
            callback(item);

        ModalManager.close(modalId);
    }
}

const Modal_Checklist_Edit = {

    oninit: function (vnode) {
        item = vnode.attrs.item || {};
        callback = vnode.attrs.callback;
    },
    view: function (vnode) {
        return [
            m(".form-section required", [
                m(".form-label", i18n.t("name")),
                m("input[type=text]", {
                    oninput: function () {
                        item.name = this.value;
                    }, value: item.name
                })
            ]),
            m(".form-buttons", [
                !saving ? m("button", {onclick: submit}, "Save") : m("button.btn-text no-click", ["Saving", m(".loading-dots")])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Checklist_Edit;
