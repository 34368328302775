const RoleService = {
    getAccountRoleLabel: function(role) {
        switch(role) {
            case this.roles.owner:
                return "AccountOwner";
            case this.roles.internalSuperuser:
                return "AccountInternalSuperuser";
            case this.roles.configuration:
                return "AccountConfiguration";
            case this.roles.supportAdministrator:
                return "AccountSupportAdministrator";
            case this.roles.clientSuperuser:
                return "OrganisationClientSuperuser";
            case this.roles.clientAdministrator:
                return "OrganisationClientAdministrator";
            case this.roles.clientTrainer:
                return "OrganisationClientTrainer";
            case this.roles.learner:
                return "LearnerDefault";
        }
    },

    isInternalRole: function(role) {
        return role === this.roles.owner
            || role === this.roles.internalSuperuser
            || role === this.roles.configuration
            || role === this.roles.supportAdministrator;
    },

    roles: {
        learner: "Learner",
        clientTrainer: "ClientTrainer",
        clientAdministrator: "ClientAdministrator",
        clientSuperuser: "ClientSuperuser",
        supportAdministrator: "SupportAdministrator",
        configuration: "Configuration",
        internalSuperuser: "InternalSuperuser",
        owner: "Owner",
    }
}

export default RoleService;