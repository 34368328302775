﻿import m from "mithril";
import SessionController from "../../shared/session";
import Loading from "../../shared/loading";
import i18n from "../../shared/i18n/i18n";
import OrgFileService from "../../shared/storage/org_file_service";
import Auth from "../../shared/auth";
import RouterService from "../../shared/services/router.service";

let currentPage = ""
const AdminSidebar = {
    currentPage: "",
    setMenu: function (el, dontSelect, dontReset) {
        if (!dontReset) {
            AdminSidebar.resetMenu();

            if (!dontSelect)
                el.classList.add("selected");

            while (el && !el.classList.contains("menu-top")) {
                el = el.parentNode;
                if (el.tagName === "LI") {
                    el.classList.add("open");
                }
            }
        } else {
            if (!dontSelect)
                el.classList.toggle("selected");

            while (el && !el.classList.contains("menu-top")) {
                el = el.parentNode;
                if (el.tagName === "LI") {
                    el.classList.toggle("open");
                }
            }
        }
    },
    resetMenu: function () {
        let i;
        const selected = document.querySelectorAll(".selected");
        for (i = 0; i < selected.length; i++) {
            selected[i].classList.remove("selected");
        }

        const opened = document.querySelectorAll(".accordion.open");
        for (i = 0; i < opened.length; i++) {
            opened[i].classList.remove("open");
        }
    },
    toggleTheme: function () {
        let theme = "light";

        if (document.documentElement.getAttribute("theme") === "light")
            theme = "dark";

        document.documentElement.setAttribute("theme", theme);
        localStorage.setItem("theme", theme);
    },
    oninit: function (vnode) {
    },
    view: function () {
        return [
            m("#sidebar-wrapper", [
                m("#sidebar", [
                    m(".sidebar-section", [
                        m(".sidebar-header", [
                            SessionController.session ? [
                                SessionController.session.environment ? [
                                    SessionController.session.environment.logoName ? m("img", {src: OrgFileService.getLogoPath()})
                                        : SessionController.session.environment.name || ""
                                ] : m("img", {src: SessionController.session.environment.storageUrl + "/assets/img/logo.png"})
                            ] : m(Loading)
                        ]),
                        m("ul", [

                            Auth.validateRights(["enrolments_view"],
                                m("li", [
                                    m(m.route.Link, {
                                        href: RouterService.getEnrolmentsUrl(),
                                        onclick: function () {
                                            AdminSidebar.setMenu(this);
                                        }, class: "menu-top" + (currentPage === "enrolments" ? " selected" : "")
                                    }, i18n.t("enrolments"))
                                ])
                                ,true
                            ),
                            Auth.validateRights(["users_view"],
                                m("li", [
                                    m(m.route.Link, {
                                        href: RouterService.getAdminUsersUrl(),
                                        onclick: function () {
                                            AdminSidebar.setMenu(this);
                                        }, class: "menu-top" + (currentPage === "users" ? " selected" : "")
                                    }, i18n.t("users"))
                                ])
                            ),
                            Auth.validateRights(["courses_view"],
                                m("li", [
                                    m(m.route.Link, {
                                        href: RouterService.getCoursesUrl(),
                                        onclick: function () {
                                            AdminSidebar.setMenu(this);
                                        }, class: "menu-top" + (currentPage === "courses" ? " selected" : "")
                                    }, i18n.t("courses"))
                                ])
                                ,true
                            ),
                            Auth.validateRights(["unit_action_view",
                                    "unit_approval_view",
                                    "unit_certificate_view",
                                    "unit_document_view",
                                    "unit_download_view",
                                    "unit_event_view",
                                    "unit_form_view",
                                    "unit_scorm_view",
                                    "unit_upload_view"
                                ],
                                m("li", [
                                    m(m.route.Link, {
                                    href: RouterService.getUnitsUrl(),
                                    onclick: function () {
                                        AdminSidebar.setMenu(this);
                                    }, class: "menu-top" + (currentPage === "units" ? " selected" : "")
                                    }, i18n.t("units"))
                                ]),
                                false
                            ),
                            Auth.validateRights(["org_view"],
                                m("li", [
                                    m(m.route.Link, {
                                        href: RouterService.getAdminSettingsUrl(),
                                        onclick: function () {
                                            AdminSidebar.setMenu(this);
                                        }, class: "menu-top" + (currentPage === "settings" ? " selected" : "")
                                    }, i18n.t("settings")),
                                ])
                                ,true
                            ),

                        ])
                    ]),
                    m(".sidebar-section", [
                        m(m.route.Link, {
                            href: RouterService.getPublicCoursesUrl()
                        }, i18n.t("course_library")),
                        m("ul", [
                            SessionController.session && SessionController.session.identity ? [
                                m("li.accordion menu-top", [
                                    m("button", {
                                        onclick: function () {
                                            AdminSidebar.setMenu(this, false, true);
                                        }
                                    }, SessionController.session.identity.name),
                                    m("ul", [
                                        m("li", [
                                            Auth.validateInternalAccess(m(m.route.Link, {
                                                href: RouterService.getAccountUrl()
                                            }, i18n.t("account")))
                                        ]),
                                        m("li", [
                                            m(m.route.Link, {href: RouterService.getProfileUrl()}, i18n.t("profile"))
                                        ]),
                                        m("li", [
                                            m("button", {
                                                onclick: function () {
                                                    event.preventDefault();
                                                    SessionController.logout();
                                                }
                                            }, i18n.t("logout"))
                                        ]),
                                        m("li", [
                                            m(".button", {onclick: AdminSidebar.toggleTheme}, [
                                                document.documentElement.getAttribute("theme") === "light" ? "Dark Mode" : "Light Mode",
                                                m("#theme-toggle.ml-1", {style: "display: inline-block; vertical-align: middle;"})
                                            ])
                                        ])
                                    ])
                                ])
                            ] : ""
                        ])
                    ])
                ])
            ])
        ];
    }
};


export default AdminSidebar;
