﻿import m from 'mithril';
import Toasts from '../../shared/toasts';
import ModalManager from "../../shared/modal";
import Auth from "../../shared/auth";
import Modal_User_Invite from "./modal_user_invite";
import i18n from "../../shared/i18n/i18n";
import Modal_User_Change_Role from '../../admin/user/modal_user_change_role';
import RouterService from '../../shared/services/router.service';
import Component_Table_Footer from '../../shared/components/component_table_footer';
import UserService from '../../shared/services/user.service';
import RoleService from '../../shared/services/role.service';

let loading, filter, search, users, selectedUsers = [], organisations = [], total = 0, limit = 25, offset = 0, orgSearchFilter, selectedOrgFilter, includeAccountUsers;
let sortByField, sortDescending;

const sortUsersBy = {
    name: "name",
    email: "email",
    accountRole: "accountRole"
}

function getSortIcon(currSortBy) {
    if (sortDescending != null && currSortBy === sortByField) {
        return sortDescending ? m("i.icon-arrow-back rotate-270") : m("i.icon-arrow-up");
    }

    return;
}

function updateSortBy(sortOnField) {
    if (sortOnField === sortByField) {
        sortDescending = !sortDescending;
        onFilter();
        return;
    }

    sortByField = sortOnField;
    sortDescending = false;
    onFilter();
};

function loadUsers() {
    loading = true;
    users = null;
    selectedUsers = [];

    const organisationId = selectedOrgFilter ? selectedOrgFilter.id : null;

    UserService.getAccountUsers(filter, search, limit, offset * limit, organisationId, includeAccountUsers, sortByField, sortDescending)
        .then(function (result) {
            users = result.users;
            total = result.totalCount;
            organisations = result.organisations;
        }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            loading = false;
        });
}

function toggleIncludeAccountUsers() {
    includeAccountUsers = !includeAccountUsers;
    localStorage.setItem("include-account-users", includeAccountUsers);
    onFilter();
}

function inviteUsers() {
    ModalManager.open(i18n.t("invite_users"), [], Modal_User_Invite);
}

function toggleAllSelected() {
    if (selectedUsers.length > 0) {
        selectedUsers = [];
    } else {
        if (users) {
            for (let i = 0; i < users.length; i++) {
                selectedUsers.push(users[i]);
            }
        }
    }
}

function toggleSelected(user) {
    const index = selectedUsers.indexOf(user);
    if (index === -1) {
        selectedUsers.push(user);
    } else {
        selectedUsers.splice(index, 1);
    }
}

function filteredUsersByOrganisation(filterTxt = null) {
    return ["", ...organisations].map(org => {
        let option = m('a',
            {
                onclick: () => {
                    selectedOrgFilter = org;
                    onFilter();
                }
            }, org.name);

        if (filterTxt == null || org == "") {
            return option
        } else if (org && org.name.toLocaleLowerCase().includes(filterTxt.toLocaleLowerCase())) {
            return option
        }
    }
    )
}

function editAccountRoleOnClick() {
    const userIds = selectedUsers.map(selectedUser => selectedUser.id);
    const selectedRole = selectedUsers.length > 1
        ? null
        : selectedUsers[0].role;
    ModalManager.open(i18n.t("edit_account_role"), [], Modal_User_Change_Role, { userIds: userIds, selectedRole: selectedRole, isInternalRole: true, callback: loadUsers });
}

function getRole(user) {
    if (RoleService.isInternalRole(user.role)) {
        return i18n.t(user.role);
    }

    return i18n.t('none');
}

function getOrganisations(user) {
    if (RoleService.isInternalRole(user.role)) {
        return i18n.t(user.role);
    }

    if (!user.organisations) {
        return null;
    }

    return user.organisations.map(organisation => organisation.name).join(", ");
}

function onPaginationChanged(newLimit, newOffset) {
    limit = newLimit;
    offset = newOffset;
    loadUsers();
}

function getEditAccountRoleClass() {
    const disabled = "disabled";

    if (selectedUsers.length === 0) {
        return disabled;
    }

    if (selectedUsers.some(user => !user.isRoleEditable)) {
        return disabled;
    }

    return "";
}

function onFilter() {
    offset = 0;
    loadUsers();
}

const Page_Users = {
    oninit: function () {
        includeAccountUsers = localStorage.getItem("include-account-users") == null ? true : localStorage.getItem("include-account-users") == "true";
        sortByField = null;
        sortDescending = null;
        orgSearchFilter = null;
        selectedOrgFilter = null;
        search = null;

        loadUsers();
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", { class: loading ? "disable-buttons" : "" }, [
                    m(".toolbar-section", [
                        m("h1", i18n.t("users"))
                    ]),
                    m(".toolbar-section", [
                        Auth.validateRights(["users_create"], m("button.with-icon btn-cta", { onclick: inviteUsers }, [m("i.icon-plus"), i18n.t("invite_users")]))
                    ])
                ])
            ]),
            m(".content-box", [
                m(".toolbar", { class: loading ? "disable-buttons" : "" }, [
                    m(".toolbar-section", [
                        Auth.validateRights([Auth.rights.users_edit],
                            m("button.with-icon",
                                {
                                    onclick: editAccountRoleOnClick,
                                    class: getEditAccountRoleClass()
                                },
                                [
                                    m("i.icon-user-id"),
                                    i18n.t("edit_account_role")
                                ]
                            )
                        )
                    ]),
                    m(".toolbar-section", [
                        m(".form-section flex-row inc-acct-users mr-2 ml-1", [
                            m(".mr-2", [
                                m(".text-bolder", i18n.t("include_account_users"))
                            ]),
                            m(".btn btn-toggle", { onclick: toggleIncludeAccountUsers, class: includeAccountUsers ? "active" : "" })
                        ]),
                        m("div", {
                            class: "orgs-dropdown dropdown"
                        }, [
                            m(".has-icon-right", [
                                m("input[type=search]", {
                                    oncreate: vnode => {
                                        document.addEventListener('click', function handleClickOutsideRolesInputDropdown(event) {
                                            const orgsDropDown = document.getElementById('orgs-dropdown-items');
                                            const orgsDropDownIcon = document.getElementById('orgs-dropdown-icon');
                                            const classList = ["show"];

                                            if (vnode.attrs.id == event.target.id) {
                                                orgsDropDown.classList.add(classList);
                                            }
                                            else if (orgsDropDownIcon.id == event.target.id) {
                                                orgsDropDown.classList.toggle(classList);
                                            } else {
                                                orgsDropDown.classList.remove(classList);
                                            }
                                        });
                                    },
                                    type: "text",
                                    autoComplete: "off",
                                    placeholder: i18n.t('organisations'),
                                    id: "orgs-dropdown-input",
                                    value: selectedOrgFilter != null ? selectedOrgFilter.name : orgSearchFilter,
                                    onkeyup: e => {
                                        orgSearchFilter = e.target.value;
                                        selectedOrgFilter = null;
                                    }
                                }),
                                m("i.icon-x c-hand", {
                                    onclick: function () {
                                        selectedOrgFilter = null;
                                        orgSearchFilter = null;
                                        onFilter();
                                    }
                                }),
                                m('i.icon-back rotate-270', { id: "orgs-dropdown-icon" }),
                                m("div#orgs-dropdown-items", {
                                    class: "dropdown-content",
                                },
                                    filteredUsersByOrganisation(orgSearchFilter)
                                )
                            ])
                        ]
                        ),
                        m(".has-icon-right", [
                            m("input[type=search]", {
                                oninput: function () {
                                    search = this.value;
                                    if (search === null || search === "") {
                                        onFilter();
                                    }
                                }, onkeyup: function (e) {
                                    if (e.keyCode === 13) {
                                        onFilter();
                                    }
                                }, value: search, placeholder: i18n.t("search")
                            }),
                            m("i.icon-x c-hand", {
                                onclick: function () {
                                    search = null;
                                    onFilter();
                                }
                            }),
                            m("i.icon-search c-hand", {
                                onclick: function () {
                                    onFilter();
                                }
                            })
                        ])
                    ])
                ]),
                !loading ? [
                    users ? [
                        users.length > 0 ? [
                            m(".table-wrapper", [
                                m("table.full-width fixed-table ellipsis", [
                                    m("thead",
                                        m("tr", [
                                            m("th", { style: "width: 3%;" }, m(".toolbar-checkbox", { onclick: toggleAllSelected }, m("i", { class: selectedUsers.length > 0 ? "icon-checkbox-checked" : "icon-checkbox-empty" }))),
                                            m("th", { style: "width: 20%;" }, m("div", { class: "clickable flex-row justify-between", onclick: () => updateSortBy(sortUsersBy.name) }, [m("span", i18n.t("name")), getSortIcon(sortUsersBy.name)])),
                                            m("th", { style: "width: 25%;" }, m("div", { class: "clickable flex-row justify-between", onclick: () => updateSortBy(sortUsersBy.email) }, [m("span", i18n.t("email")), getSortIcon(sortUsersBy.email)])),
                                            m("th", { style: "width: 15%;" }, m("div", { class: "clickable flex-row justify-between", onclick: () => updateSortBy(sortUsersBy.accountRole) }, [m("span", i18n.t("account_role")), getSortIcon(sortUsersBy.accountRole)])),
                                            m("th", { style: "width: 37%;" }, m("div", { class: "flex-row justify-between" }, m("span", i18n.t("organisations")))),
                                        ])
                                    ),
                                    m("tbody", users.map(function (user) {
                                        return m("tr", [
                                            m("td.list-checkbox c-hand pl-1 pr-1", {
                                                onclick: function () {
                                                    toggleSelected(user);
                                                }, style: "width: 18px;"
                                            }, m("i", { class: selectedUsers.indexOf(user) !== -1 ? "icon-checkbox-checked" : "icon-checkbox-empty" })),
                                            m("td.text-bolder", m(m.route.Link, { href: RouterService.getAccountUserUrl(user.id) }, user.name ? user.name : m(".text-gray", "Invited"))),
                                            m("td", m(m.route.Link, {
                                                href: RouterService.getAccountUserUrl(user.id),
                                                tabindex: "-1"
                                            }, user.email)),
                                            m("td", m(m.route.Link, {
                                                href: RouterService.getAccountUserUrl(user.id),
                                                tabindex: "-1"
                                            }, getRole(user))),
                                            m("td", m(m.route.Link, {
                                                href: RouterService.getAccountUserUrl(user.id),
                                                tabindex: "-1"
                                            }, getOrganisations(user)))
                                        ])
                                    }))
                                ])
                            ]),
                            m(Component_Table_Footer, {
                                callback: onPaginationChanged,
                                total: total,
                                limit: limit,
                                offset: offset,
                                loading: loading,
                                item: "users"
                            })
                        ] : m(".text-gray padding", i18n.t("no_users"))
                    ] : m(".text-gray padding", i18n.t("unable_to_load_users"))
                ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
            ])
        ];
    }
}

export default Page_Users;
