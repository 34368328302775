import m from "mithril";
import Auth from '../auth';

let RouterService = {
    navigateOnLogin: function() {
        if (Auth.validateRights(["role_internal"])) {
            this.navigateToOrganisations();
        } else if (Auth.validateRights(["role_external"])) {
            this.navigateToEnrolments();
        } else {
            this.navigateToPublicCourses();
        }
    },

    getAccountUrl: function() {
        return `/account/`;
    },

    getAccountLoginUrl: function() {
        return `/account/login`;
    },

    getAccountCasLoginUrl: function() {
        return `/account/login/cas`;
    },

    getAccountForgotPasswordUrl: function() {
        return `/account/forgot-password`;
    },

    getAccountForgotPasswordSentUrl: function() {
        return `/account/forgot-password-sent`;
    },

    getNoAccountUrl: function() {
        return `/account/no-account`;
    },

    getAccountLogoutUrl: function() {
        return `/account/logout`;
    },

    getAccountUsersUrl: function() {
        return `/account/users`;
    },

    getAccountUserUrl: function(id) {
        return `/account/users/${id}`;
    },

    getAccountSettingsUrl: function() {
        return `/account/settings`;
    },

    getAccountProfileUrl: function() {
        return `/account/profile`;
    },

    getOrganisationsUrl: function() {
        return `/account/organisations`;
    },

    getOrganisationUrl: function(id) {
        return `/account/organisations/${id}`;
    },

    getOrganisationTransitionUrl: function(hostname, token, redirect) {
        return redirect
            ? `https://${hostname}/#!/transition?token=${token}&redirect=${redirect}`
            : `https://${hostname}/#!/transition?token=${token}`;
    },

    getAdminUrl: function() {
        return `/admin/`;
    },

    getAdminUsersUrl: function() {
        return `/admin/users`;
    },

    getAdminUserUrl: function(id) {
        return `/admin/users/${id}`;
    },

    getAdminSettingsUrl: function() {
        return `/admin/settings`;
    },

    getEnrolmentsUrl: function() {
        return `/admin/enrolments`;
    },

    getEnrolmentUrl: function(id) {
        return `/admin/enrolments/${id}`;
    },

    getCoursesUrl: function() {
        return `/admin/courses`;
    },

    getCourseUrl: function(id) {
        return `/admin/courses/${id}`;
    },

    getUnitsUrl: function() {
        return `/admin/units`;
    },

    getUnitUrl: function(id) {
        return `/admin/units/${id}`;
    },

    getEmailLayoutUrl: function(id) {
        return `/admin/emails/layouts/${id}`;
    },

    getPublicCoursesUrl: function() {
        return `/public/`;
    },

    getPublicCourseUrl: function(id) {
        return `/public/courses/${id}`;
    },

    getPublicLoginUrl: function() {
        return `/public/login`;
    },

    getPublicLoginAsVisitorUrl: function() {
        return `/public/login-as-visitor`;
    },

    getPublicLoginWithCourseRedirectUrl: function(id, allowGuestEnrolment) {
        return allowGuestEnrolment
            ? `/public/login?course=${id}?allowGuest=true`
            : `/public/login?course=${id}`;
    },

    getPublicForgotPasswordUrl: function() {
        return `/public/forgot-password`;
    },

    getPublicForgotPasswordSentUrl: function() {
        return `/public/forgot-password-sent`;
    },

    getPublicPostSignUpUrl: function(id) {
        return `/public/signup-post?id=${id}`;
    },

    getProfileUrl: function() {
        return `/public/profile`;
    },

    navigateToAccount: function() {
        m.route.set(this.getAccountUrl());
    },

    navigateToAccountLogin: function() {
        m.route.set(this.getAccountLoginUrl());
    },

    navigateToAccountCasLogin: function() {
        m.route.set(this.getAccountCasLoginUrl());
    },

    navigateToAccountForgotPassword: function() {
        m.route.set(this.getAccountForgotPasswordUrl());
    },

    navigateToAccountForgotPasswordSent: function() {
        m.route.set(this.getAccountForgotPasswordSentUrl());
    },

    navigateToNoAccount: function() {
        m.route.set(this.getNoAccountUrl());
    },

    navigateToAccountLogout: function() {
        m.route.set(this.getAccountLogoutUrl());
    },

    navigateToAccountUsers: function() {
        m.route.set(this.getAccountUsersUrl());
    },

    navigateToAccountUser: function(id) {
        m.route.set(this.getAccountUserUrl(id));
    },

    navigateToAccountSettings: function() {
        m.route.set(this.getAccountSettingsUrl());
    },

    navigateToAccountProfile: function() {
        m.route.set(this.getAccountProfileUrl());
    },

    navigateToOrganisations: function() {
        m.route.set(this.getOrganisationsUrl());
    },

    navigateToOrganisation: function(id) {
        m.route.set(this.getOrganisationUrl(id));
    },

    navigateToAdmin: function() {
        m.route.set(this.getAdminUrl());
    },

    navigateToAdminUsers: function() {
        m.route.set(this.getAdminUsersUrl());
    },

    navigateToAdminUser: function(id) {
        m.route.set(this.getAdminUserUrl(id));
    },

    navigateToAdminSettings: function() {
        m.route.set(this.getAdminSettingsUrl());
    },

    navigateToEnrolments: function() {
        m.route.set(this.getEnrolmentsUrl());
    },

    navigateToEnrolment: function(id) {
        m.route.set(this.getEnrolmentUrl(id));
    },

    navigateToCourses: function() {
        m.route.set(this.getCoursesUrl());
    },

    navigateToCourse: function(id) {
        m.route.set(this.getCourseUrl(id));
    },

    navigateToUnits: function() {
        m.route.set(this.getUnitsUrl());
    },

    navigateToUnit: function(id) {
        m.route.set(this.getUnitUrl(id));
    },

    navigateToEmailLayout: function(id) {
        m.route.set(this.getEmailLayoutUrl(id));
    },

    navigateToPublicCourses: function() {
        m.route.set(this.getPublicCoursesUrl());
    },

    navigateToPublicCourse: function(id) {
        m.route.set(this.getPublicCourseUrl(id));
    },

    navigateToPublicLogin: function() {
        m.route.set(this.getPublicLoginUrl());
    },

    navigateToPublicLoginAsVisitor: function() {
        m.route.set(this.getPublicLoginAsVisitorUrl());
    },

    navigateToPublicLoginWithCourseRedirect: function(id, allowGuestEnrolment) {
        m.route.set(this.getPublicLoginWithCourseRedirectUrl(id, allowGuestEnrolment));
    },

    navigateToPublicForgotPassword: function() {
        m.route.set(this.getPublicForgotPasswordUrl());
    },

    navigateToPublicForgotPasswordSent: function() {
        m.route.set(this.getPublicForgotPasswordSentUrl());
    },

    navigateToPublicPostSignUp: function(id) {
        m.route.set(this.getPublicPostSignUpUrl(id));
    },

    navigateToProfile: function() {
        m.route.set(this.getProfileUrl());
    },

    navigateTo: function(url) {
        m.route.set(url);
    }
}

export default RouterService;