﻿import m from "mithril";
import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import i18n from "../../../shared/i18n/i18n";
import Toasts from "../../../shared/toasts";
import ModalManager from "../../../shared/modal";
import FormUtilities from "../../../shared/utilities/form_utilities";
import Component_Agenda_Input from "./component_agenda_input";
import Component_Autocomplete from "../../../shared/components/component_autocomplete";
import {Datepicker} from "vanillajs-datepicker";
let saving, unit, callback, modalId;
const session = {
    name: "",
    information: "",
    location: "",
    periodicity: "weekly",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    minPlaces: null,
    maxPlaces: null,
    agendaItems: [],
    days: [],
    timeZoneOffsetMinutes: null
};
let realStartDate, realEndDate, startDate, endDate, timeZoneOffsetMinutes;

function saveSession() {
    if (FormUtilities.validateFields(".modal .form-section-container input")) {

        let month = 30 * 24 * 60 * 60 * 1000;
        if (session.endTime < session.startTime)
            Toasts.add("error", i18n.t("cant_save"), i18n.t("start_before_end"));
        else if (realEndDate < realStartDate)
            Toasts.add("error", i18n.t("cant_save"), i18n.t("before_to_date"));
        else if (Math.round((realEndDate - realStartDate) / month) > 12)
            Toasts.add("error", i18n.t("cant_save"), i18n.t("within_12_months"));
        else {
            saving = true;
            const startTimeSlp = session.startTime.split(":");
            const endTimeSlp = session.endTime.split(":");
            if(startTimeSlp[0] && startTimeSlp[1])
                realStartDate.setHours(startTimeSlp[0], startTimeSlp[1]);
            if(endTimeSlp[0] && endTimeSlp[1])
                realEndDate.setHours(endTimeSlp[0], endTimeSlp[1]);
            session.startDate = getISODateWithoutTimeInLocalTimeZone(realStartDate);
            session.endDate = getISODateWithoutTimeInLocalTimeZone(realEndDate);
            let daySelect = document.getElementById("edit-event-days-select");
            session.days = [];
            session.timeZoneOffsetMinutes = timeZoneOffsetMinutes;
            for (let i = 0; i < daySelect.options.length; i++)
                if (daySelect.options[i].selected && session.days.indexOf(daySelect.options[i]) === -1)
                    session.days.push(daySelect.options[i].value);
                Requester.post(Gateway.admin + "units/event/" + unit.id + "/sessions/bulk", session)
                    .then(function (result) {
                        ModalManager.close(modalId);
                        Toasts.add("success", i18n.t("saved_changes"), "");
                        if (callback)
                            callback();
                    }).catch(function (error) {
                        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                    }).finally(function () {
                        saving = false;
            });
        }
    }
}

function addAgendaItem() {
    let timeField = document.getElementById("agenda-time-field");
    let infoField = document.getElementById("agenda-info-field");
    timeField.classList.remove("has-error");
    infoField.classList.remove("has-error");

    if (timeField.value && infoField.value) {
        session.agendaItems.push({
            time: timeField.value,
            info: infoField.value
        });
        timeField.value = "";
        infoField.value = "";
    }
    else {
        if (!timeField.value)
            timeField.classList.add("has-error");

        if (!infoField.value)
            infoField.classList.add("has-error");
    }
}

function removeAgendaItem(item) {
    let index = session.agendaItems.indexOf(item);
    if (index > -1)
        session.agendaItems.splice(index, 1);
}

function setInstructorValue(autocompleteValue) {
    session.instructorId = autocompleteValue.id || null;
    session.instructorName = autocompleteValue.name || autocompleteValue;
}

function getISODateWithoutTimeInLocalTimeZone(dateTime) {
    const dateTimeWithoutTimeZoneOffset = new Date(dateTime.getTime() - timeZoneOffsetMinutes*60000);
    const isoString = dateTimeWithoutTimeZoneOffset.toISOString();
    return isoString.substring(0,10);
}

const Modal_Unit_Event_Session_New_Bulk = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
        callback = vnode.attrs.callback;

        if (!unit)
            ModalManager.close(modalId);

        session.name = unit.name;
        session.location = unit.location;
        session.information = unit.information;
        session.startTime = unit.startTime;
        session.endTime = unit.endTime;
        session.minPlaces = unit.minPlaces;
        session.maxPlaces = unit.maxPlaces;
        timeZoneOffsetMinutes = new Date().getTimezoneOffset();
        startDate = "";
        endDate = "";
    },
    view: function () {
        return [
            m(".form-section-container", [
                m(".form-section required", [
                    m(".form-label", i18n.t("name")),
                    m("input[type=text]", { oninput: function () { session.name = this.value; }, value: session.name })
                ]),
                m(".form-section", [
                    m(".form-label", i18n.t("info")),
                    m("textarea", { oninput: function () { session.information = this.value; }, value: session.information })
                ]),
                m(".form-section required", [
                    m(".form-label", i18n.t("location")),
                    m("input[type=text]", { oninput: function () { session.location = this.value; }, value: session.location })
                ]),
                m(".form-section-shared", [
                    m(".form-section", [
                        m(".form-label", i18n.t("min_places")),
                        m("input[type=number]", { oninput: function () { session.minPlaces = this.value; }, value: session.minPlaces })
                    ]),
                    m(".form-section", [
                        m(".form-label", i18n.t("max_places")),
                        m("input[type=number]", { oninput: function () { session.maxPlaces = this.value; }, value: session.maxPlaces })
                    ])
                ]),
                m(".form-section required", [
                    m(".form-label", "Instructor"),
                    m(Component_Autocomplete, { endpoint: "users", updateCallback: setInstructorValue, submitCallback: setInstructorValue })
                ]),
                m(".form-section", [
                    m(".form-label", i18n.t("recurrence")),
                    m("select", { onchange: function () { session.periodicity = this.value; }, value: session.periodicity }, [
                        m("option", { value: "weekly" }, i18n.t("weekly")),
                        m("option", { value: "biweekly" }, i18n.t("fortnightly")),
                        m("option", { value: "triweekly" }, i18n.t("every_three_weeks")),
                        m("option", { value: "fourweekly" }, i18n.t("every_four_weeks"))
                    ])
                ]),
                m(".form-section-shared", [
                    m(".form-section required", [
                        m(".form-label", "From"),
                        m("input[type=text] .calendar", {
                            oncreate: v => {
                                new Datepicker(v.dom, {
                                    language: i18n.locale,
                                    updateOnBlur: true,
                                    container: "div",
                                });
                            },
                            onblur: function () {
                                realStartDate = this.datepicker.getDate();
                                startDate = this.value;
                            },
                            value: startDate
                        })
                    ]),
                    m(".form-section required", [
                        m(".form-label", "To"),
                        m("input[type=text] .calendar", {
                            oncreate: v => {
                                new Datepicker(v.dom, {
                                    language: i18n.locale,
                                    updateOnBlur: true,
                                    container: "div",
                                });
                            },
                            onblur: function () {
                                realEndDate = this.datepicker.getDate();
                                endDate = this.value;
                            },
                            value: endDate
                        })
                    ])
                ]),
                session.periodicity !== "daily" ? m(".form-section required", [
                    m(".form-label", i18n.t("days")),
                    m("select[multiple]#edit-event-days-select", [
                        m("option", { value: "sunday" }, i18n.t("sunday")),
                        m("option", { value: "monday" }, i18n.t("monday")),
                        m("option", { value: "tuesday" }, i18n.t("tuesday")),
                        m("option", { value: "wednesday" }, i18n.t("wednesday")),
                        m("option", { value: "thursday" }, i18n.t("thursday")),
                        m("option", { value: "friday" }, i18n.t("friday")),
                        m("option", { value: "saturday" }, i18n.t("saturday"))
                    ])
                ]) : "",
                m(".form-section-shared", [
                    m(".form-section required", [
                        m(".form-label", i18n.t("start_time")),
                        m("input[type=time][lang="+i18n.locale+"]", { oninput: function () { session.startTime = this.value; }, value: session.startTime })
                    ]),
                    m(".form-section required", [
                        m(".form-label", i18n.t("end_time")),
                        m("input[type=time][lang="+i18n.locale+"]", { oninput: function () { session.endTime = this.value; }, value: session.endTime })
                    ])
                ])
            ]),
            m(Component_Agenda_Input, { array: session.agendaItems }),
            m(".form-buttons", [
                !saving ? m("button", { onclick: saveSession }, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Unit_Event_Session_New_Bulk;
