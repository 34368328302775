﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import Modal_Unit_New from "./modal_unit_new";
import i18n from "../../shared/i18n/i18n";
import ModalManager from "../../shared/modal";
import Auth from "../../shared/auth";
import UnitUtilities from "../../shared/utilities/unit_utilities";
import Component_Table_Footer from "../../shared/components/component_table_footer";
import {getUnitTypesi18nText, UnitTypes} from "../values";
import RouterService from "../../shared/services/router.service";

let loading, filter, search, units, selected = [],
    total = 0, limit = 25, offset = 0, orderBy;

function submitPagination(lim, off) {
    limit = lim;
    offset = off;
    loadUnits();
}

function loadUnits() {
    loading = true;
    units = null;
    selected = [];

    let params = [];
    if (filter)
        params.push("filter=" + filter);
    if (search)
        params.push("search=" + search);
    if (orderBy)
        params.push("orderBy=" + orderBy);

    params.push("limit=" + limit);
    params.push("offset=" + offset * limit);

    Requester.get(Gateway.admin + "units" + (params.length > 0 ? "?" + params.join("&") : ""))
        .then(function (result) {
            total = result.properties.totalCount;
            units = result.entities;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function newUnit() {
    ModalManager.open(i18n.t("select_unit_type"), [], Modal_Unit_New);
}

function toggleAllSelected() {
    if (selected.length > 0)
        selected = [];
    else {
        if (units) {
            for (let i = 0; i < units.length; i++) {
                selected.push(units[i].properties.id);
            }
        }
    }
}

function toggleSelected(id) {
    let index = selected.indexOf(id);
    if (index === -1)
        selected.push(id);
    else
        selected.splice(index, 1);
}

function activateUnits() {
    loading = true;
    Requester.post(Gateway.admin + "units/activate", selected)
        .then(function () {
            loadUnits();
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            loading = false;
        });
}

function deactivateUnits() {
    loading = true;
    Requester.post(Gateway.admin + "units/deactivate", selected)
        .then(function () {
            loadUnits();
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            loading = false;
        });
}

function orderByUnitName() {
    if (orderBy === "unitName") {
        orderBy = "unitNameDesc";
    } else if (orderBy === "unitNameDesc") {
        orderBy = null;
    } else {
        orderBy = "unitName";
    }

    loadUnits();
}


function orderByUnitType() {
    if (orderBy === "unitType") {
        orderBy = "unitTypeDesc";
    } else if (orderBy === "unitTypeDesc") {
        orderBy = null;
    } else {
        orderBy = "unitType";
    }

    loadUnits();
}

let currentPage = "";
const Page_Units = {
    oninit: function () {
        loadUnits();
        currentPage = "units";
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        m("h1", i18n.t("units"))
                    ]),
                    m(".toolbar-section", [
                        Auth.validateRights([
                            "unit_action_create",
                            "unit_approval_create",
                            "unit_certificate_create",
                            "unit_document_create",
                            "unit_download_create",
                            "unit_event_create",
                            "unit_form_create",
                            "unit_scorm_create",
                            "unit_upload_create"
                        ], m("button.with-icon btn-cta", {onclick: newUnit}, [m("i.icon-plus"), i18n.t("create_unit")]))
                    ])
                ])
            ]),
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        Auth.validateRights([
                            "unit_action_edit",
                            "unit_approval_edit",
                            "unit_certificate_edit",
                            "unit_document_edit",
                            "unit_download_edit",
                            "unit_event_edit",
                            "unit_form_edit",
                            "unit_scorm_edit",
                            "unit_upload_edit",
                        ], [
                            m(".dropdown", {class: selected.length === 0 ? "disabled" : ""},
                                m("button.with-icon pl-2", [
                                    m("i.icon-visible"),
                                    i18n.t("change_status"),
                                    m("i.icon-back rotate-270 ml-2")
                                ]),
                                m(".dropdown-list", [
                                    m("div", {onclick: activateUnits}, i18n.t("active")),
                                    m("div", {onclick: deactivateUnits}, i18n.t("inactive"))
                                ])
                            ),
                            selected && selected.length > 0 ? m("span.ml-1", [selected.length, " selected"]) : ""
                        ]),
                    ]),
                    m(".toolbar-section", [
                        m(".dropdown",
                            m("button.with-icon pl-2", [
                                filter === null ? i18n.t("all_units") : i18n.t(getUnitTypesi18nText(filter)),
                                m("i.icon-back rotate-270 ml-2")
                            ]),
                            m(".dropdown-list", [
                                m("div", {
                                    class: !filter ? "active" : "", onclick: function () {
                                        filter = null;
                                        loadUnits();
                                    }
                                }, i18n.t("all_units")),
                                m("div", {
                                    class: filter === UnitTypes.scorm ? "active" : "", onclick: function () {
                                        filter = UnitTypes.scorm;
                                        loadUnits();
                                    }
                                }, i18n.t("interactive")),
                                m("div", {
                                    class: filter === UnitTypes.event ? "active" : "", onclick: function () {
                                        filter = UnitTypes.event;
                                        loadUnits();
                                    }
                                }, i18n.t("face_to_face")),
                                m("div", {
                                    class: filter === UnitTypes.form ? "active" : "", onclick: function () {
                                        filter = UnitTypes.form;
                                        loadUnits();
                                    }
                                }, i18n.t("form")),
                                m("div", {
                                    class: filter === UnitTypes.cert ? "active" : "", onclick: function () {
                                        filter = UnitTypes.cert;
                                        loadUnits();
                                    }
                                }, i18n.t("certificate")),
                                m("div", {
                                    class: filter === UnitTypes.doc ? "active" : "", onclick: function () {
                                        filter = UnitTypes.doc;
                                        loadUnits();
                                    }
                                }, i18n.t("document")),
                                m("div", {
                                    class: filter === UnitTypes.action ? "active" : "", onclick: function () {
                                        filter = UnitTypes.action;
                                        loadUnits();
                                    }
                                }, i18n.t("action")),
                                m("div", {
                                    class: filter === UnitTypes.approval ? "active" : "", onclick: function () {
                                        filter = UnitTypes.approval;
                                        loadUnits();
                                    }
                                }, i18n.t("approval")),
                                m("div", {
                                    class: filter === UnitTypes.download ? "active" : "", onclick: function () {
                                        filter = UnitTypes.download;
                                        loadUnits();
                                    }
                                }, i18n.t("download")),
                                m("div", {
                                    class: filter === UnitTypes.upload ? "active" : "", onclick: function () {
                                        filter = UnitTypes.upload;
                                        loadUnits();
                                    }
                                }, i18n.t("upload"))
                            ])
                        ),
                        m(".has-icon-right", [
                            m("input[type=search]", {
                                oninput: function () {
                                    search = this.value;
                                }, onkeyup: function (e) {
                                    if (e.keyCode === 13) {
                                        loadUnits();
                                    }
                                }, value: search, placeholder: i18n.t("search")
                            }),
                            m("i.icon-x c-hand", {
                                onclick: function () {
                                    search = null;
                                    loadUnits();
                                }
                            }),
                            m("i.icon-search c-hand", {
                                onclick: function () {
                                    loadUnits();
                                }
                            })
                        ])
                    ])
                ]),
                !loading ? [
                    units ? [
                        m(".table-wrapper flex-auto", [
                            units.length > 0 ? [
                                m("table.full-width", [
                                    m("thead",
                                        m("tr", [
                                            m("th", m(".toolbar-checkbox", {onclick: toggleAllSelected},
                                                m("i", {class: selected.length > 0 ? "icon-checkbox-checked" : "icon-checkbox-empty"}))),
                                            m("th.c-hand", {onclick: orderByUnitName}, [i18n.t("name"), m("i.icon-forward align-middle", {class: orderBy === "unitName" ? "rotate-90" : orderBy === "unitNameDesc" ? "rotate-270" : "rotate-90 translucent"})]),
                                            m("th.c-hand", {onclick: orderByUnitType}, [i18n.t("type"), m("i.icon-forward align-middle", {class: orderBy === "unitType" ? "rotate-90" : orderBy === "unitTypeDesc" ? "rotate-270" : "rotate-90 translucent"})]),
                                            m("th", i18n.t("active")),
                                            m("th", i18n.t("in_use"))
                                        ])
                                    ),
                                    m("tbody", units.map(function (unit) {
                                        return [
                                            m("tr", [
                                                m("td.list-checkbox c-hand pl-1 pr-1", {
                                                    onclick: function () {
                                                        toggleSelected(unit.properties.id);
                                                    }, style: "width: 18px;"
                                                }, m("i", {class: selected.indexOf(unit.properties.id) !== -1 ? "icon-checkbox-checked" : "icon-checkbox-empty"})),
                                                m("td.text-bolder", m(m.route.Link, {href: RouterService.getUnitUrl(unit.properties.id)}, unit.properties.name)),
                                                m("td.with-icon", m(m.route.Link, {
                                                    href: RouterService.getUnitUrl(unit.properties.id),
                                                    tabindex: "-1"
                                                }, [
                                                    m("i", {class: UnitUtilities.getUnitTypeIcon(unit.properties.type)}),
                                                    UnitUtilities.unitTypes[unit.properties.type.toLowerCase()]
                                                ])),
                                                m("td", m(m.route.Link, {
                                                    href: RouterService.getUnitUrl(unit.properties.id),
                                                    tabindex: "-1"
                                                }, unit.properties.isActive ? m("i.icon-check") : "")),
                                                m("td", m(m.route.Link, {
                                                    href: RouterService.getUnitUrl(unit.properties.id),
                                                    tabindex: "-1"
                                                }, unit.properties.inUse ? m("i.icon-check") : ""))
                                            ])
                                        ];
                                    }))
                                ])
                            ] : m(".text-gray padding", i18n.t("no_units"))
                        ]),
                        m(Component_Table_Footer, {
                            callback: submitPagination,
                            total: total,
                            limit: limit,
                            offset: offset,
                            loading: loading,
                            item: "units"
                        })
                    ] : m(".text-gray padding", i18n.t("unable_load_units"))
                ] : m(".text-gray padding", i18n.t("loading"))
            ])
        ];
    }
};
export default Page_Units;
