﻿import m from "mithril";
import Toasts from "../../shared/toasts";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import ModalManager from "../../shared/modal";
import Component_Table_Footer from "../../shared/components/component_table_footer";
import Auth from "../../shared/auth";
import Modal_User_Change_Role from "./modal_user_change_role";
import Modal_User_Invite from "./modal_user_invite";
import DateUtilities from "../../shared/utilities/date_utilities";
import i18n from "../../shared/i18n/i18n";
import RouterService from "../../shared/services/router.service";

let loading, filter, search, users, selectedUsers = [],
    total = 0, limit = 25, offset = 0, orderBy;

function reloadUsers() {
    setTimeout(function () {
        loadUsers();
    }, 500);
}

function submitPagination(lim, off) {
    limit = lim;
    offset = off;
    loadUsers();
}

function loadUsers(val) {
    if (val)
        search = val.value || val;

    loading = true;
    users = null;
    selectedUsers = [];

    let params = [];
    if (filter)
        params.push("filter=" + filter);
    if (search)
        params.push("search=" + search);
    if (orderBy)
        params.push("orderBy=" + orderBy);

    params.push("limit=" + limit);
    params.push("offset=" + offset * limit);

    Requester.get(Gateway.admin + "users" + (params.length > 0 ? "?" + params.join("&") : ""))
        .then(function (result) {
            total = result.properties.totalCount;
            users = result.entities;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function inviteUsers() {
    ModalManager.open(i18n.t("invite_users"), [], Modal_User_Invite, {callback: reloadUsers});
}

function toggleAllSelected() {
    if (selectedUsers.length > 0) {
        selectedUsers = [];
    } else {
        if (users) {
            for (let i = 0; i < users.length; i++) {
                selectedUsers.push(users[i]);
            }
        }
    }
}

function toggleSelected(user) {
    let index = selectedUsers.indexOf(user);
    if (index === -1) {
        selectedUsers.push(user);
    } else {
        selectedUsers.splice(index, 1);
    }
}

function editRoleOnClick() {
    const userIds = selectedUsers.map(selectedUser => selectedUser.properties.id);
    const selectedRole = selectedUsers.length > 1
        ? null
        : selectedUsers[0].properties.accessLevel;
    ModalManager.open(i18n.t("changeRole"), [], Modal_User_Change_Role, {userIds: userIds, selectedRole: selectedRole, isInternalRole: false, callback: reloadUsers});
}

function getEditRoleClass() {
    const disabled = "disabled";

    if (selectedUsers.length === 0) {
        return disabled;
    }

    if (selectedUsers.some(user => !user.properties.isRoleEditable)) {
        return disabled;
    }

    return "";
}

function orderByUserName() {
    if (orderBy === "userName") {
        orderBy = "userNameDesc";
    } else if (orderBy === "userNameDesc") {
        orderBy = null;
    } else {
        orderBy = "userName";
    }

    loadUsers();

}

function orderByEmail() {
    if (orderBy === "email") {
        orderBy = "emailDesc"
    } else if (orderBy === "emailDesc") {
        orderBy = null;
    } else {
        orderBy = "email"
    }

    loadUsers();
}

function orderByLastActive() {
    if (orderBy === "lastActiveDate") {
        orderBy = "lastActiveDateDesc"
    } else if (orderBy === "lastActiveDateDesc") {
        orderBy = null;
    } else {
        orderBy = "lastActiveDate"
    }

    loadUsers();
}

function onFilter() {
    offset = 0;
    loadUsers();
}

let currentPage;
const Page_AdminUsers = {
    oninit: function () {
        loadUsers();
        currentPage = "users";
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        m("h1", i18n.t("users"))
                    ]),
                    m(".toolbar-section", [
                        Auth.validateRights(["users_create"], m("button.with-icon btn-cta", {onclick: inviteUsers}, [m("i.icon-plus"), i18n.t("invite_users")]))
                    ])
                ])
            ]),
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        Auth.validateRights([Auth.rights.users_edit],
                            m("button.with-icon",
                                {
                                    onclick: editRoleOnClick,
                                    class: getEditRoleClass()
                                },
                                [
                                    m("i.icon-user-id"),
                                    i18n.t("changeRole")
                                ]
                            )
                        )
                    ]),
                    m(".toolbar-section", [
                        m(".dropdown",
                            m("button.with-icon pl-2", [
                                filter === "learners" ? "Learners" : filter === "admins" ? i18n.t("administrators" ): filter === "unverified" ? i18n.t("unverified") : i18n.t("all_users"),
                                m("i.icon-back rotate-270 ml-2")
                            ]),
                            m(".dropdown-list", [
                                m("div", {
                                    class: !filter ? "active" : "", onclick: function () {
                                        filter = null;
                                        onFilter();
                                    }
                                }, i18n.t("all_users")),
                                m("div", {
                                    class: filter === "verified" ? "active" : "", onclick: function () {
                                        filter = "verified";
                                        onFilter();
                                    }
                                }, i18n.t("verified")),
                                m("div", {
                                    class: filter === "unverified" ? "active" : "", onclick: function () {
                                        filter = "unverified";
                                        onFilter();
                                    }
                                }, i18n.t("unverified"))
                            ])
                        ),
                        m(".has-icon-right", [
                            m("input[type=search]", {
                                oninput: function () {
                                    search = this.value;
                                }, onkeyup: function (e) {
                                    if (e.keyCode === 13) {
                                        onFilter();
                                    }
                                }, value: search, placeholder: i18n.t("search")
                            }),
                            m("i.icon-x c-hand", {
                                onclick: function () {
                                    search = null;
                                    onFilter();
                                }
                            }),
                            m("i.icon-search c-hand", {
                                onclick: function () {
                                    onFilter();
                                }
                            })
                        ])
                    ])
                ]),
                !loading ? [
                    users ? [
                        m(".table-wrapper flex-auto", [
                            users.length > 0 ? [
                                m("table.full-width", [
                                    m("thead",
                                        m("tr", [
                                            m("th", m(".toolbar-checkbox", {onclick: toggleAllSelected}, m("i", {class: selectedUsers.length > 0 ? "icon-checkbox-checked" : "icon-checkbox-empty"}))),
                                            m("th.c-hand", {onclick: orderByUserName}, [i18n.t("name"), m("i.icon-forward align-middle", {class: orderBy === "userName" ? "rotate-90" : orderBy === "userNameDesc" ? "rotate-270" : "rotate-90 translucent"})]),
                                            m("th.c-hand", {onclick: orderByEmail}, [i18n.t("email_username"), m("i.icon-forward align-middle", {class: orderBy === "email" ? "rotate-90" : orderBy === "emailDesc" ? "rotate-270" : "rotate-90 translucent"})]),
                                            m("th", i18n.t("role")),
                                            m("th.c-hand", {onclick: orderByLastActive}, [i18n.t("last_login"), m("i.icon-forward align-middle", {class: orderBy === "lastActiveDate" ? "rotate-90" : orderBy === "lastActiveDateDesc" ? "rotate-270" : "rotate-90 translucent"})]),
                                        ])
                                    ),
                                    m("tbody", users.map(function (user) {
                                        return [
                                            m("tr", [
                                                m("td.list-checkbox c-hand pl-1 pr-1", {
                                                    onclick: function () {
                                                        toggleSelected(user);
                                                    }, style: "width: 18px;"
                                                }, m("i", {class: selectedUsers.indexOf(user) !== -1 ? "icon-checkbox-checked" : "icon-checkbox-empty"})),
                                                m("td.text-bolder", m(m.route.Link, {href: RouterService.getAdminUserUrl(user.properties.id)}, user.properties.firstName ? (user.properties.firstName + " " + user.properties.lastName) : m(".text-gray", "Invited"))),
                                                m("td", m(m.route.Link, {
                                                    href: RouterService.getAdminUserUrl(user.properties.id),
                                                    tabindex: "-1"
                                                }, user.properties.email || user.properties.username || "")),
                                                m("td", m(m.route.Link, {
                                                    href: RouterService.getAdminUserUrl(user.properties.id),
                                                    tabindex: "-1"
                                                }, i18n.t(user.properties.accessLevel))),
                                                m("td", m(m.route.Link, {
                                                    href: RouterService.getAdminUserUrl(user.properties.id),
                                                    tabindex: "-1"
                                                }, DateUtilities.getFormattedDateFromUTC(user.properties.lastActive))),
                                            ])
                                        ];
                                    }))
                                ])
                            ] : m(".text-gray padding", "No Users")
                        ]),
                        m(Component_Table_Footer, {
                            callback: submitPagination,
                            total: total,
                            limit: limit,
                            offset: offset,
                            loading: loading,
                            item: "users"
                        })
                    ] : m(".text-gray padding", "Unable to load users")
                ] : m(".text-gray padding", ["Loading", m(".loading-dots")])
            ])
        ];
    }
};
export default Page_AdminUsers;
