import m from 'mithril';
import SessionController from '../../shared/session';
import Sidebar from './sidebar';
import Loading from '../../shared/loading';
import i18n from "../../shared/i18n/i18n";
import Toasts from "../../shared/toasts";
import RouterService from '../../shared/services/router.service';

let loading = false;

function auth() {
    SessionController.checkSession();

    if (!SessionController.session || !SessionController.session.identity || !SessionController.session.identity.rights.length > 0) {
        loading = true;
        SessionController.getSession()
            .then(function () {
                if (!SessionController.session.identity) {
                    RouterService.navigateToAccountLogin();
                }
                else if (!SessionController.session.identity.rights.length > 0) {
                    RouterService.navigateToNoAccount();
                }
            })
            .catch(function () {
                console.log("TODO: Account/layout.js auth Get Session Catch");
                RouterService.navigateToAccountLogin();
            })
            .finally(function () {
                loading = false;
                m.redraw();
            });
    }
}

const AccountLayout = {
    oninit: function (vnode) {
        if (m.route.param("sso-auth"))
            SessionController.verifySso(m.route.param("sso-auth"))
                .catch(function (error) {
                    Toasts.add("error", i18n.t("login_failed"), error);
                    auth();
                });
        else
            auth();

        let theme = localStorage.getItem("theme");
        if (!theme)
            theme = 'light';
        // todo this has no effect
        document.documentElement.setAttribute("theme", theme);
        localStorage.setItem("theme", theme);
    },
    view: function (vnode) {
        return i18n.status === "loading"
            ? m("p", i18n.t("loading") + "...")
            : [m("main", [
                !loading ? [
                    m(Sidebar),
                    m("#content", vnode.children),
                ] : m(Loading)
            ]),
            m("#toaster")
        ];
    }
};

export default AccountLayout;
